import { faAngleLeft, faBars, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faBell, faFilterList, faGrid2, faMap } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useHistory, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ViSVG from '../../assets/images/vietnam.svg';
import KoSVG from '../../assets/images/korea.svg';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar, languageVar, toggleLanguage } from '../../apollo';

import { useQuery } from '@apollo/client';
import { ME_QUERY } from '../../lib/queries/user';

const Button = styled.button`
    width: 70px;
    height: 70px;
    position: absolute;
    left: 0;
    top: 0;
    @media (max-width: 768px) {
        width: 50px;
        height: 60px;
    }
`;
const RightButton = styled.button`
    width: 70px;
    height: 70px;

    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: 768px) {
        width: 50px;
        height: 60px;
    }
`;
const RightLink = styled(Link)`
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: 768px) {
        width: 50px;
        height: 60px;
    }
`;
const RightButton2 = styled.button`
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 50px;
        height: 60px;
    }
`;
const RightLink2 = styled(Link)`
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        width: 50px;
        height: 60px;
    }
`;
const Icon = styled(FontAwesomeIcon)`
    font-size: 26px;
    color: #535353;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`;
const NewPoint = styled.div`
    position: absolute;
    top: 16px;
    right: 16px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border-width: 2px;
    border-color: white;
    background-color: red;
    z-index: 1;
`;
const Iconimg = styled.div`
    width: 26px;
`;
export const HeadOnDrawer = ({ onClick }) => {
    return (
        <Button onClick={onClick} className="items-center justify-center">
            <Icon icon={faBars} />
        </Button>
    );
};

export const HeadBackButton = ({ onClick }) => {
    const navigate = useNavigate();
    const onClickBtn = () => {
        navigate(-1); // 바로 이전 페이지로 이동, '/main' 등 직접 지정도 당연히 가능
    };
    return (
        <Button onClick={onClickBtn} className="items-center justify-center">
            <Icon icon={faAngleLeft} />
        </Button>
    );
};
export const HeadMapButton = ({ onClick }) => {
    return (
        <RightButton onClick={onClick} className="items-center justify-center">
            <Icon icon={faMap} />
        </RightButton>
    );
};
export const HeadGridButton = ({ onClick }) => {
    return (
        <RightButton onClick={onClick} className="items-center justify-center">
            <Icon icon={faGrid2} />
        </RightButton>
    );
};

export const HeadFiltterButton = ({ onClick }) => {
    return (
        <RightButton onClick={onClick} className="items-center justify-center">
            <Icon icon={faFilterList} />
        </RightButton>
    );
};

export const HeadCloseButton = ({ to }) => {
    return (
        <RightLink to={to}>
            <Icon icon={faXmark} />
        </RightLink>
    );
};

export const HeadAlarm = () => {
    const { loading, error, data } = useQuery(ME_QUERY, {
        fetchPolicy: 'network-only',
    });

    if (loading) {
        return <></>;
    }
    if (error) {
        return <>{error.message}</>;
    }

    const {
        me: { notificationDetailCount },
    } = data;

    return (
        <>
            <RightLink2 to={'/account/notification'}>
                <Icon icon={faBell} />
                {notificationDetailCount > 0 && <NewPoint />}
            </RightLink2>
        </>
    );
};

export const HeadLanguage = ({ to }) => {
    const language = useReactiveVar(languageVar);

    const changeLanguage = () => {
        toggleLanguage();
    };
    return (
        <>
            <RightButton2 onClick={changeLanguage}>
                {language === 'vi' && (
                    <Iconimg>
                        <img src={KoSVG} />
                    </Iconimg>
                )}
                {language === 'ko' && (
                    <Iconimg>
                        <img src={ViSVG} />
                    </Iconimg>
                )}
            </RightButton2>
        </>
    );
};
