import React from "react";
import styled from "styled-components";
import LogoSVG from "../../assets/images/test/long.jpg";
const Program = styled.p`
  color: #222;
  font-size: 42px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 28px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
const Company = styled.p`
  color: #1f1f1f;
  font-size: 20px;
  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

export default ({ data }) => {
  const {
    mountainProgram: {
      result: { program },
    },
  } = data;

  return (
    <>
      <div className="flex-1 pr-0  lg:pr-12 ">
        <div>
          <Program>{program.title}</Program>
          <Company>{program.company.companyName}</Company>
          <div
            style={{ width: "100%", minHeight: 500 }}
            className="my-5 lg:my-14"
          >
            {program.commonFiles.map((file, index) => (
              <img key={index} src={file.fileUrl} alt="상세페이지" />
            ))}
            {program.description}
          </div>
        </div>
      </div>
    </>
  );
};
