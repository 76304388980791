import React from 'react';
import styled from 'styled-components';
const Region = styled.p`
    font-weight: 500;
    color: #484848;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
const Length = styled.p`
    margin-left: 4px;
    color: #a0a0a0;
    font-weight: 400;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
export default ({ type, onClick, selected, region, length }) => {
    return (
        <button
            type={type}
            onClick={onClick}
            className={'defaultSelectButton flex ' + `${selected ? 'select' : ''}`}
        >
            <Region
                className={' text-lg tracking-tight selectText ' + `${selected ? 'select' : ''}`}
            >
                {region}
            </Region>
            <Length
                className={' text-lg tracking-tight selectText ' + `${selected ? 'select' : ''}`}
            >
                {length}
            </Length>
        </button>
    );
};
