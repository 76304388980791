import React from "react";
import GraySearch from "../../components/forest/GraySearch";
import { NumericFormat } from "react-number-format";
import MapListForest from "../../components/forest/MapListForest";
import FilterDropDownButton from "../../components/button/FilterDropDownButton";
import NoForest from "../../components/data/NoForest";
import RegionSelect from "../../containers/mountain/RegionSelect";
import Pagination from "../../components/pagination/Pagination";
import {
  regionInfo,
  filterInfo,
  filterOptions,
  sortInfo,
  sortOptions,
} from "../../lib/codes/mountain";
import { useTranslation } from "react-i18next";

export default ({
  keyword,
  region,
  filter,
  sort,
  page,
  baseUrl,
  take,
  data,
  setListType,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <GraySearch
        keyword={keyword}
        region={region}
        filter={filter}
        sort={sort}
        data={data}
      />
      <div className="flex items-start flex-col xl:flex-row xl:items-center justify-between mt-5 mb-10 ">
        <div className="flex relative flex-wrap">
          <RegionSelect
            baseurl="/mountains?"
            info={{
              key: "region",
              value: t(`mountain.list.selects.region.title`),
            }}
            selected={region}
          />
          <FilterDropDownButton
            baseurl="/mountains?"
            info={{
              key: "filter",
              value: t(`mountain.list.selects.filter.title`),
            }}
            options={[
              {
                key: "",
                value: t(`mountain.list.selects.filter.options.all`),
              },
              {
                key: "barrierfree",
                value: t(`mountain.list.selects.filter.options.barrierfree`),
              },
              {
                key: "program",
                value: t(`mountain.list.selects.filter.options.program`),
              },
            ]}
            selected={filter}
            isLeft
          />
          <FilterDropDownButton
            baseurl="/mountains?"
            info={{
              key: "sort",
              value: t(`mountain.list.selects.sort.title`),
            }}
            options={[
              {
                key: "distance",
                value: t(`mountain.list.selects.sort.options.distance`),
              },
              {
                key: "name",
                value: t(`mountain.list.selects.sort.options.name`),
              },
              {
                key: "height",
                value: t(`mountain.list.selects.sort.options.height`),
              },
            ]}
            selected={sort}
            isLeft
          />
        </div>
      </div>
      <div>
        <div className="forestLength">
          {t(`mountain.list.items.title`)}{" "}
          <span>
            (
            <NumericFormat
              value={data.mountainList.totalResults}
              displayType="text"
              thousandSeparator={true}
            />
            )
          </span>
        </div>
        <div className="mt-5">
          {data.mountainList.totalResults > 0 ? (
            <div className="flex-col flex">
              {data.mountainList.results.map((item, index) => {
                return (
                  <MapListForest
                    key={index}
                    to={`/mountains/${item.id}`}
                    isBarrierfree={item.isBarrierfree}
                    isProgram={item.isProgram}
                    img={item.commonFile?.fileUrl}
                    name={item.name}
                    address={
                      item.roadAddress ? item.roadAddress : item.jibunAddress
                    }
                  />
                );
              })}
            </div>
          ) : (
            <NoForest />
          )}

          <div className="w-full flex my-10 justify-center">
            <Pagination
              totalRecord={data.mountainList.totalResults}
              blockSize={5}
              pageSize={take}
              currentPage={page}
              baseUrl={baseUrl}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
