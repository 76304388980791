import React from 'react';
import styled from 'styled-components';
import { faCircleXmark, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import Img from '../../assets/images/user/modalIcon.svg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

const Graysearch = styled.div`
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #f4f4f4;
    padding: 15px 30px;
    border-radius: 15px;
    @media (max-width: 768px) {
        padding: 12px 15px 10px;
    }
`;
const SearchIcon = styled.div`
    margin-right: 10px;
    width: 30px;
`;
const Icon = styled(FontAwesomeIcon)`
    color: #727272;
`;
const Button = styled.button`
    padding: 10px 10px 7px;
    @media (max-width: 768px) {
        padding: 0;
    }
`;
const ButtonText = styled.p`
    font-weight: 500;
    color: #c0c0c0;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
const ClearButton = styled.button`
    font-size: 32px;
    width: 50px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        height: 30px;
    }
`;
const Result = styled.p`
    text-align: right;
    margin-top: 20px;
    font-size: 17px;
`;
const ClearIcon = styled(FontAwesomeIcon)`
    @media (max-width: 768px) {
        font-size: 20px;
    }
`;
export default ({ keyword, region, filter, sort, data }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        setError,
        reset,
    } = useForm({
        defaultValues: {
            keyword,
        },
    });

    const onSubmit = async (data) => {
        if (data.keyword === null) {
            MySwal.fire({
                showCloseButton: true,
                imageUrl: Img,
                imageWidth: 80,
                imageHeight: 80,
                title: <p className="modalTitle">{t(`mountain.list.search.alert.title`)}</p>,
                html: <p className="modalSubText">{t(`mountain.list.search.alert.message`)}</p>,
                confirmButtonText: t(`mountain.list.search.alert.buttons.confirm`),
                confirmButtonColor: `#44a16a`,
            });
            return;
        }

        try {
            const url =
                '/mountains?' +
                (data.keyword ? `keyword=${data.keyword}&` : ``) +
                (region ? `region=${region}&` : ``) +
                (filter ? `filter=${filter}&` : ``) +
                (sort ? `sort=${sort}&` : ``) +
                `page=1`;
            navigate(url);
        } catch (e) {
            console.log(e);
        }
    };

    const onClear = () => {
        const url =
            '/mountains?' +
            (region ? `region=${region}&` : ``) +
            (filter ? `filter=${filter}&` : ``) +
            (sort ? `sort=${sort}&` : ``) +
            `page=1`;
        navigate(url);
        setValue('keyword', '');
    };

    return (
        <div className="flex-1">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Graysearch className="flex items-center">
                    <Controller
                        control={control}
                        name="keyword"
                        render={({ field: { onChange, onBlur, value } }) => (
                            <div className="flex items-center flex-1">
                                <SearchIcon>
                                    <Icon icon={faMagnifyingGlass} />
                                </SearchIcon>

                                <input
                                    type="text"
                                    className="greysearchInput"
                                    title={t(`mountain.list.search.input.title`)}
                                    placeholder={t(`mountain.list.search.input.placeholder`)}
                                    value={value}
                                    onBlur={onBlur}
                                    onChange={onChange}
                                />

                                {value !== null && value !== '' && (
                                    <ClearButton type="button" className="flex" onClick={onClear}>
                                        <ClearIcon
                                            icon={faCircleXmark}
                                            style={{ color: '#A7A7A7' }}
                                        />
                                    </ClearButton>
                                )}
                            </div>
                        )}
                    />
                    <Button type="submit">
                        <ButtonText>{t(`mountain.list.search.button.text`)}</ButtonText>
                    </Button>
                </Graysearch>
            </form>
            {keyword ? (
                <Result className="searchResult ">
                    "<span className="">{keyword}</span>"
                    <span className="">({data.mountainList.totalResults})</span>
                    {t(`mountain.list.search.result.text`)}{' '}
                </Result>
            ) : (
                ``
            )}
        </div>
    );
};
