import React from "react";
import { Link } from "react-router-dom";

export const ScrollToTop = () => {
  window.scrollTo(0, 0);
};

export const DefaultLink = ({ text, to }) => {
  return (
    <>
      <Link to={to} className="defaultTap" onClick={ScrollToTop}>
        <p className="defaultTapText">{text}</p>
      </Link>
    </>
  );
};
export const DefaultButton = ({ text, onClick, type }) => {
  return (
    <>
      <button type={type} onClick={onClick} className="defaultTap">
        <p className="defaultTapText">{text}</p>
      </button>
    </>
  );
};

export const DefaultGreyLink = ({ text, to }) => {
  return (
    <>
      <Link to={to} className="defaultTap grey" onClick={ScrollToTop}>
        <p className="defaultTapText grey">{text}</p>
      </Link>
    </>
  );
};
export const DefaultGreyButton = ({ text, onClick, type }) => {
  return (
    <>
      <button onClick={onClick} type={type} className="defaultTap grey">
        <p className="defaultTapText grey">{text}</p>
      </button>
    </>
  );
};

export const DefaultLineLink = ({ text, to }) => {
  return (
    <>
      <Link to={to} className="defaultTap line" onClick={ScrollToTop}>
        <p className="defaultTapText line">{text}</p>
      </Link>
    </>
  );
};
export const DefaultLineButton = ({ text, onClick, type }) => {
  return (
    <>
      <button onClick={onClick} type={type} className="defaultTap line">
        <p className="defaultTapText line">{text}</p>
      </button>
    </>
  );
};
