import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

function InputErrorText({ text }) {
    return (
        <>
            <div className="flex items-center mt-4">
                <FontAwesomeIcon
                    icon={faCircleExclamation}
                    style={{ width: 30, color: '#ff4848' }}
                />
                <p className="inputError">{text}</p>
            </div>
        </>
    );
}
export default InputErrorText;
