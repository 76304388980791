import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import '../../assets/css/banner.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import ImageGallery from 'react-image-gallery';

const slides = [
    {
        original: '/assets/images/user/mobile-banner1.png',
        thumbnail: '/assets/images/user/mobile-banner1.png',
    },
    {
        original: '/assets/images/user/mobile-banner2.png',
        thumbnail: '/assets/images/user/mobile-banner2.png',
    },
];

const Index = styled.div`
    position: absolute;
    bottom: 40px;
    left: 60px;
    font-size: 24px;
`;
const IndexLine = styled.div`
    width: 25px;
    height: 1.5px;
    background-color: #fff;
    margin: 0 11px;
    opacity: 0.5;
    margin-bottom: 3px;
`;
const Now = styled.span`
    color: #fff;
    font-weight: 700;
`;
const Total = styled.span`
    color: #95b5d3;
`;

export default () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleSlide = (currentIndex) => {
        setCurrentIndex(currentIndex);
    };
    return (
        <div className="block md:hidden">
            <div className="">
                <div className="relative">
                    <ImageGallery
                        items={slides}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        startIndex={0}
                        // showPlayButton={false}
                        onSlide={handleSlide}
                        autoPlay={false}
                        renderItem={({ original }) => (
                            <div
                                className="image-gallery-slide mobile-slide"
                                style={{ backgroundImage: `url(${original})` }}
                            />
                        )}
                    />
                    <Index className="hidden md:flex  items-center">
                        <Now>{currentIndex + 1}</Now>
                        <IndexLine /> <Total>{slides.length}</Total>
                    </Index>
                </div>
            </div>
        </div>
    );
};
