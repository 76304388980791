import { faFaceDotted } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

export default () => {
    return (
        <>
            <div className="flex justify-center items-center flex-col text-center">
                <FontAwesomeIcon icon={faFaceDotted} className="noIcon" />
                <p className="noTitle">해당 데이터가 없습니다</p>
                <p className="noText">알림내역이 없습니다</p>
            </div>
        </>
    );
};
