import { gql } from "@apollo/client";

/* 지역 목록  */
export const COMMON_REGION_LIST_QUERY = gql`
  query commonRegionList($take: Int, $skip: Int!) {
    commonRegionList(input: { take: $take, skip: $skip }) {
      ok
      results {
        id
        createdAt
        updatedAt

        name

        count
      }
      error
      totalResults
      totalPages
    }
  }
`;

// where : region, isBarrierfree, isProgram
// order : 경도/위도, 이름, 높이, 피톤치드

/** 목록 **/
export const MOUNTAIN_LIST_QUERY = gql`
  query mountainList(
    $language: String
    $take: Int
    $skip: Int!
    $keyword: String
    $region: String
    $filter: String
    $sort: String
  ) {
    mountainList(
      input: {
        language: $language
        take: $take
        skip: $skip
        keyword: $keyword
        region: $region
        filter: $filter
        sort: $sort
      }
    ) {
      ok
      results {
        id
        createdAt
        updatedAt

        commonState
        no
        name
        reason
        locationInfo
        height
        description
        roadAddress
        jibunAddress
        longitude
        latitude

        isBarrierfree
        isProgram
        commonFile {
          id
          fileUrl
        }
      }
      error
      totalResults
      totalPages
    }
  }
`;

/** 정보 **/
export const MOUNTAIN_READ_QUERY = gql`
  query mountainRead($language: String, $id: Float!) {
    mountainRead(input: { language: $language, id: $id }) {
      ok
      error
      result {
        id
        createdAt
        updatedAt

        commonState
        no
        name
        reason
        locationInfo
        height
        description
        roadAddress
        jibunAddress
        longitude
        latitude

        commonFiles {
          id
          fileUrl
        }
        regionHasMountains {
          id
          region {
            id
            name
          }
        }
        phytoncides {
          id
          createdAt
          updatedAt

          commonState
          no
          totalNvocs
          anion
          averageWind
          averageTemperature
          averageHumidity
          name
          longitude
          latitude
          checkedAt
        }
        barrierfrees {
          id
          createdAt
          updatedAt

          commonState
          no
          year
          organ
          name
          roadAddress
          jibunAddress
          longitude
          latitude
          amount
          area
        }
        programs {
          id
          createdAt
          updatedAt

          commonState
          title
          description
          tel
          company {
            id
            createdAt
            updatedAt

            companyName
            companyTel
            companyNumber
          }
        }
        user {
          id
          createdAt
          updatedAt

          role
          userId
          name
          phone
          deviceToken
          isWithdrawal
          visitedAt
        }
        phytoncide {
          id
          createdAt
          updatedAt

          commonState
          no
          totalNvocs
          anion
          averageWind
          averageTemperature
          averageHumidity
          name
          longitude
          latitude
          checkedAt
        }
        isBarrierfree
        isProgram
      }
    }
  }
`;

/** 상담 **/
export const MOUNTAIN_PROGRAM_QUERY = gql`
  query mountainProgram($language: String, $id: Float!, $program_id: Float!) {
    mountainProgram(
      input: { language: $language, id: $id, program_id: $program_id }
    ) {
      ok
      error
      result {
        id
        createdAt
        updatedAt

        commonState
        no
        name
        reason
        locationInfo
        height
        description
        roadAddress
        jibunAddress
        longitude
        latitude

        commonFiles {
          id
          fileUrl
        }
        regionHasMountains {
          id
          region {
            id
            name
          }
        }
        phytoncides {
          id
          createdAt
          updatedAt

          commonState
          no
          totalNvocs
          anion
          averageWind
          averageTemperature
          averageHumidity
          name
          longitude
          latitude
          checkedAt
        }
        barrierfrees {
          id
          createdAt
          updatedAt

          commonState
          no
          year
          organ
          name
          roadAddress
          jibunAddress
          longitude
          latitude
          amount
          area
        }
        programs {
          id
          createdAt
          updatedAt

          commonState
          title
          description
          tel
          company {
            id
            createdAt
            updatedAt

            companyName
            companyTel
            companyNumber
          }
        }
        user {
          id
          createdAt
          updatedAt

          role
          userId
          name
          phone
          deviceToken
          isWithdrawal
          visitedAt
        }
        phytoncide {
          id
          createdAt
          updatedAt

          commonState
          no
          totalNvocs
          anion
          averageWind
          averageTemperature
          averageHumidity
          name
          longitude
          latitude
          checkedAt
        }
        program {
          id
          createdAt
          updatedAt

          commonState
          title
          description
          tel
          commonFiles {
            id
            fileUrl
          }
          company {
            id
            createdAt
            updatedAt

            companyName
            companyTel
            companyNumber
          }
        }
        isBarrierfree
      }
    }
  }
`;
