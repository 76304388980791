import React from 'react';

export default ({ img }) => {
    return (
        <>
            <div
                className=""
                style={{
                    backgroundImage: img,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    width: '100%',
                    height: '100%',
                }}
            />
        </>
    );
};
