import React from "react";
import Header from "../layout/header/HeaderNav";
import Banner from "../containers/main/Banner";
import Search from "../containers/main/Search";
import Region from "../containers/main/Region";
import List from "../containers/main/List";
import Footer from "../layout/footer/Footer";
import MobileFooter from "../layout/footer/MobileFooter";
import MobileBanner from "../containers/main/MobileBanner";
import MobileHead from "../layout/header/MobileHead";

export default () => {
  return (
    <div>
      <Header />
      <MobileHead title={"메타숲"} />
      <Banner />
      <MobileBanner />
      <div className="py-10 md:py-16">
        <div className="width-1600">
          <Search />
          <Region />
          <List />
        </div>
      </div>
      <Footer />
      <MobileFooter />
    </div>
  );
};
