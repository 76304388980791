import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";

const Region = styled.p`
  font-weight: 500;
  color: #484848;
`;
const Length = styled.p`
  margin-left: 4px;
  color: #a0a0a0;
  font-weight: 400;
`;
export default ({ to, active, region, length }) => {
  return (
    <Link
      to={to}
      className={"defaultSelectButton flex " + `${active ? "select" : ""}`}
    >
      <Region
        className={
          " text-lg tracking-tight selectText " + `${active ? "select" : ""}`
        }
      >
        {region}
      </Region>
      <Length
        className={
          " text-lg tracking-tight selectText " + `${active ? "select" : ""}`
        }
      >
        <NumericFormat
          value={length}
          displayType="text"
          thousandSeparator={true}
        />
      </Length>
    </Link>
  );
};
