import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderNav from '../../layout/header/HeaderNav';
import Footer from '../../layout/footer/Footer';
import TreeTitle from '../../components/logout/TreeTitle';
import InputErrorText from '../../components/logout/InputErrorText';
import CloseHead from '../../layout/header/CloseHead';
import { InputSubButton } from '../../components/linkButton/InputSub';
import ClearButton from '../../components/button/ClearButton';
import { useTranslation } from 'react-i18next';

import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import {
    PW_FINDER_PHONE_VERIFY_SEND_MUTATION,
    PW_FINDER_PHONE_VERIFY_MUTATION,
    PW_FINDER_PASSWORD_CHANGE_MUTATION,
} from '../../lib/queries/user';

import Img from '../../assets/images/user/modalIcon.svg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { DefaultButton } from '../../components/linkButton/Default';
const MySwal = withReactContent(Swal);

export default () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [pwFinderPhoneVerifySendMutation, { pwFinderPhoneVerifySendLoading }] = useMutation(
        PW_FINDER_PHONE_VERIFY_SEND_MUTATION,
    );
    const [pwFinderPhoneVerifyMutation, { pwFinderPhoneVerifyLoading }] = useMutation(
        PW_FINDER_PHONE_VERIFY_MUTATION,
    );
    const [pwFinderPasswordChangeMutation, { pwFinderPasswordChangeLoading }] = useMutation(
        PW_FINDER_PASSWORD_CHANGE_MUTATION,
    );

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        setError,
        reset,
    } = useForm({
        defalutValues: {
            userId: '',
            password: '',
        },
    });

    const onSubmit = async (data) => {
        try {
            if (pwFinderPhoneVerifyLoading) {
                return;
            }

            const {
                data: { pwFinderPhoneVerify },
            } = await pwFinderPhoneVerifyMutation({
                variables: {
                    user_userId: data.user_userId,
                    user_phone: data.user_phone,
                    code: data.code,
                },
            });

            if (!pwFinderPhoneVerify.ok) {
                MySwal.fire({
                    showCloseButton: true,
                    icon: 'error',
                    title: (
                        <p className="modalTitle">
                            {t(`pwfind.alerts.pwFinderPhoneVerify.error.title`)}
                        </p>
                    ),
                    html: (
                        <p className="modalSubText">
                            {t(`pwfind.alerts.pwFinderPhoneVerify.error.message`)}
                        </p>
                    ),
                    confirmButtonText: t(`pwfind.alerts.pwFinderPhoneVerify.error.buttons.confirm`),
                    confirmButtonColor: `#44a16a`,
                });
                return;
            }

            MySwal.fire({
                showCloseButton: true,
                imageUrl: Img,
                imageWidth: 80,
                imageHeight: 80,
                title: (
                    <p className="modalTitle">
                        {t(`pwfind.alerts.pwFinderPhoneVerify.confirm.title`)}
                    </p>
                ),
                html: (
                    <p className="modalSubText">
                        {t(`pwfind.alerts.pwFinderPhoneVerify.confirm.message`)}
                    </p>
                ),
                input: 'password',
                imputPlaceholder: t(`pwfind.alerts.pwFinderPhoneVerify.confirm.imputPlaceholder`),
                inputAttributes: {
                    autocapitalize: 'off',
                },
                confirmButtonText: t(`pwfind.alerts.pwFinderPhoneVerify.confirm.buttons.confirm`),
                confirmButtonColor: `#44a16a`,
                showLoaderOnConfirm: true,
                preConfirm: async (password) => {
                    if (password.length < 8) {
                        MySwal.showValidationMessage(
                            t(
                                `pwfind.alerts.pwFinderPhoneVerify.confirm.preConfirm.showValidationMessage`,
                            ),
                        );
                        return;
                    }
                    if (pwFinderPasswordChangeLoading) {
                        return;
                    }
                    const {
                        data: { pwFinderPasswordChange },
                    } = await pwFinderPasswordChangeMutation({
                        variables: {
                            user_userId: data.user_userId,
                            user_phone: data.user_phone,
                            code: data.code,
                            password: password,
                        },
                    });

                    if (!pwFinderPasswordChange.ok) {
                        MySwal.showValidationMessage(
                            t(`pwfind.alerts.pwFinderPasswordChange.error.showValidationMessage`),
                        );
                        return;
                    }
                    MySwal.fire({
                        showCloseButton: true,
                        imageUrl: Img,
                        imageWidth: 80,
                        imageHeight: 80,
                        title: (
                            <p className="modalTitle">
                                {t(`pwfind.alerts.pwFinderPasswordChange.error.title`)}
                            </p>
                        ),
                        html: (
                            <p className="modalSubText">
                                {t(`pwfind.alerts.pwFinderPasswordChange.error.message`)}
                            </p>
                        ),
                        confirmButtonText: t(
                            `pwfind.alerts.pwFinderPasswordChange.error.buttons.confirm`,
                        ),
                        confirmButtonColor: `#44a16a`,
                    }).then((result) => {
                        if (result.isDismissed || result.isConfirmed) {
                            navigate('/login');
                        }
                    });
                },
                allowOutsideClick: false,
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onPhoneVerifySend = async () => {
        try {
            if (
                watch('user_userId') === undefined ||
                watch('user_userId').length < 5 ||
                watch('user_userId').length > 10
            ) {
                setError('user_userId', {
                    type: 'error',
                    message: t(`pwfind.id.rules.required`),
                });
                return;
            }
            if (watch('user_phone') === undefined || watch('user_phone').length < 10) {
                setError('user_phone', {
                    type: 'error',
                    message: t(`pwfind.phone.rules.required`),
                });
                return;
            }

            if (pwFinderPhoneVerifySendLoading) {
                return;
            }
            const {
                data: { pwFinderPhoneVerifySend },
            } = await pwFinderPhoneVerifySendMutation({
                variables: {
                    user_userId: watch('user_userId'),
                    user_phone: watch('user_phone'),
                },
            });

            if (!pwFinderPhoneVerifySend.ok) {
                setError('user_phone', {
                    type: 'error',
                    message: t(`pwfind.phone.errors.exception`),
                });
                return;
            }
            startCountdown();
        } catch (e) {
            console.log({ e });
        }
    };
    const [isCounting, setIsCounting] = useState(false);
    const [remainingTime, setRemainingTime] = useState(180);
    const startCountdown = () => {
        setIsCounting(true);
    };

    useEffect(() => {
        let intervalId;
        if (isCounting) {
            intervalId = setInterval(() => {
                setRemainingTime((prevTime) => {
                    if (prevTime <= 0) {
                        setIsCounting(false);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [isCounting]);
    return (
        <>
            <div
                className=" h-full flex flex-col min-h-screen lg:block"
                style={{ backgroundColor: '#fafafa' }}
            >
                <HeaderNav isLogin={false} />
                <CloseHead title={t(`pwFind.title`)} to="/login" />
                <div className="w-11/12  pt-0 pb-0 flex flex-col justify-center m-auto minHeight900  md:w-5/6  lg:w-3/5 max-w-screen-md md:pt-10 md:pb-20">
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                        <div className="whiteShadowBox">
                            <TreeTitle title={t(`pwFind.title`)} />
                            <div>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: t(`pwfind.id.rules.required`),
                                    }}
                                    name="user_userId"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <div className="my-8">
                                            <p className="mb-0 inputLabel sm:mb-3">
                                                {t(`pwfind.id.title`)}
                                            </p>
                                            <div className="inputGreyBox flex items-center justify-between">
                                                <input
                                                    type="text"
                                                    className="defaultInput"
                                                    placeholder={t(`pwfind.id.input.placeholder`)}
                                                    maxLength={12}
                                                    value={value}
                                                    onBlur={onBlur}
                                                    onChange={onChange}
                                                />
                                                {value !== undefined && value !== '' && (
                                                    <ClearButton
                                                        onClick={() => setValue('user_userId', '')}
                                                    />
                                                )}
                                            </div>
                                            {errors.user_userId && (
                                                <InputErrorText text={errors.user_userId.message} />
                                            )}
                                        </div>
                                    )}
                                />

                                <Controller
                                    control={control}
                                    rules={{
                                        required: t(`pwfind.phone.rules.required`),
                                    }}
                                    name="user_phone"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <div className="mb-5">
                                            <p className="mb-0 inputLabel sm:mb-3">
                                                {t(`pwfind.phone.title`)}
                                            </p>
                                            <div className="block md:flex justify-between">
                                                <div className="inputGreyBox flex items-center justify-between">
                                                    <input
                                                        type="tel"
                                                        className="defaultInput"
                                                        placeholder={t(
                                                            `pwfind.phone.input.placeholder`,
                                                        )}
                                                        maxLength={12}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                    />
                                                    {value !== undefined && value !== '' && (
                                                        <ClearButton
                                                            onClick={() =>
                                                                setValue('user_phone', '')
                                                            }
                                                        />
                                                    )}
                                                </div>
                                                <InputSubButton
                                                    type="button"
                                                    onClick={() => {
                                                        onPhoneVerifySend();
                                                    }}
                                                    text={t(`pwfind.phone.button.text`)}
                                                />
                                            </div>

                                            {errors.user_phone && (
                                                <InputErrorText text={errors.user_phone.message} />
                                            )}
                                        </div>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    rules={{
                                        required: t(`pwfind.code.rules.required`),
                                    }}
                                    name="code"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <div className="mb-10">
                                            <div className="block md:flex justify-between">
                                                <div className="inputGreyBox flex items-center justify-between">
                                                    <input
                                                        className="defaultInput"
                                                        id="code"
                                                        type="text"
                                                        placeholder={t(
                                                            `pwfind.code.input.placeholder`,
                                                        )}
                                                        maxLength={12}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                    />
                                                    {value !== undefined && value !== '' && (
                                                        <ClearButton
                                                            onClick={() => setValue('code', '')}
                                                        />
                                                    )}
                                                    {isCounting ? (
                                                        <p className="timers">{`${Math.floor(
                                                            remainingTime / 60,
                                                        )}:${String(remainingTime % 60).padStart(
                                                            2,
                                                            '0',
                                                        )}`}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {errors.code && (
                                                <InputErrorText text={errors.code.message} />
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="mt-14">
                                <DefaultButton
                                    type="submit"
                                    text={t(`pwfind.buttons.confirm.text`)}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <Footer />
            </div>
        </>
    );
};
