import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import ReactMoment from 'react-moment';
import styled from 'styled-components';

const List = styled.div``;
const label = styled.span``;
const Value = styled.p``;
const Icon = styled(FontAwesomeIcon)`
    font-size: 14px;
    color: #a2a2a2;
`;
export const MobileBetweenTable = ({ label, value, icon }) => {
    return (
        <List className="tableText flex  w-full  justify-between sm:justify-normal py-1">
            <div className="flex w-24 justify-start items-center">
                <Icon icon={icon} className="mb-1 mr-3 w-5" />
                <span className="w-16 flex tableSpan">{label}</span>
            </div>

            <Value>{value}</Value>
        </List>
    );
};
export const MobileBetweenDateTable = ({ label, date, icon }) => {
    return (
        <List className="tableText flex  w-full justify-between sm:justify-normal  py-1">
            <div className="flex w-24 justify-start items-center">
                <Icon icon={icon} className="mb-1 mr-3 w-5" />
                <span className="w-16 flex tableSpan">{label}</span>
            </div>
            <Value>
                <ReactMoment format="YYYY-MM-DD">{date}</ReactMoment>
            </Value>
        </List>
    );
};
