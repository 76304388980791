import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderNav from "../../layout/header/HeaderNav";
import Footer from "../../layout/footer/Footer";
import TreeTitle from "../../components/logout/TreeTitle";
import General from "../../layout/join/General";
import Company from "../../layout/join/Company";
import InputErrorText from "../../components/logout/InputErrorText";
import { useTranslation } from "react-i18next";

import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/client";
import {
  CREATE_USERID_CHECKER_MUTATION,
  CREATE_PHONE_VERIFY_SEND_MUTATION,
  CREATE_USER_ACCOUNT_MUTATION,
  CREATE_COMPANY_ACCOUNT_MUTATION,
  LOGIN_MUTATION,
} from "../../lib/queries/user";
import { logUserIn } from "../../apollo";

import Img from "../../assets/images/user/modalIcon.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CloseHead from "../../layout/header/CloseHead";
import ShowModalButton from "../../components/linkButton/ShowModalButton";
import PrivacyModal from "../../components/modals/logout/PrivacyModal";
import TemrsModal from "../../components/modals/logout/TemrsModal";
import { DefaultButton } from "../../components/linkButton/Default";
const MySwal = withReactContent(Swal);

export default () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [privacy, setPrivacy] = useState(false);
  const togglePrivacy = () => {
    setPrivacy(!privacy);
  };
  const [isTerms, setTerms] = useState(false);
  const toggleTerms = () => {
    setTerms(!isTerms);
  };
  const [activeTab, setActiveTab] = useState("user");
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const [allChecked, setAllChecked] = useState(false);
  const handleAllChecked = () => {
    setAllChecked(!allChecked);
    setValue("privacypolicy", !allChecked);
    setValue("termsAgreed", !allChecked);
  };

  const [createUserIdCheckerMutation, { loading: createUserIdCheckerLoading }] =
    useMutation(CREATE_USERID_CHECKER_MUTATION);
  const [
    createPhoneVerifySendMutation,
    { loading: createPhoneVerifySendLoading },
  ] = useMutation(CREATE_PHONE_VERIFY_SEND_MUTATION);
  const [createUserAccountMutation, { createUserAccountLoading }] = useMutation(
    CREATE_USER_ACCOUNT_MUTATION
  );
  const [createCompanyAccountMutation, { createCompanyAccountLoading }] =
    useMutation(CREATE_COMPANY_ACCOUNT_MUTATION);
  const [logInMutation, { logInLoading }] = useMutation(LOGIN_MUTATION);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    reset,
    clearErrors,
  } = useForm({
    defalutValues: {
      userId: "",
      password: "",
      passwordConfirm: "",
      name: "",
      phone: "",
      companyName: "",
      companyNumber: "",
      companyTel: "",
      code: "",
      privacypolicy: false,
      atermsAgreed: false,
    },
  });

  const onSubmit = async (data) => {
    try {
      if (createUserIdCheckerLoading) {
        return;
      }
      const {
        data: { createUserIdChecker },
      } = await createUserIdCheckerMutation({
        variables: {
          userId: data.userId.toLowerCase(),
        },
      });

      if (!createUserIdChecker.ok) {
        setError("userId", {
          type: "error",
          message: createUserIdChecker.error,
        });
        MySwal.fire({
          showCloseButton: true,
          icon: "error",
          title: (
            <p className="modalTitle">
              {t(`join.alerts.createUserIdChecker.error.title`)}
            </p>
          ),
          html: (
            <p className="modalSubText">
              {t(`join.alerts.createUserIdChecker.error.message`)}
            </p>
          ),
          confirmButtonText: t(
            `join.alerts.createUserIdChecker.error.buttons.confirm`
          ),
          confirmButtonColor: `#44a16a`,
        });
      }

      if (activeTab === "user") {
        if (createUserAccountLoading) {
          return;
        }

        const {
          data: { createUserAccount },
        } = await createUserAccountMutation({
          variables: {
            userId: data.userId,
            password: data.password,
            name: data.name,
            phone: data.phone,
            companyName: data.companyName,
            companyNumber: data.companyNumber,
            companyTel: data.companyTel,
            code: data.code,
          },
        });

        if (!createUserAccount.ok) {
          MySwal.fire({
            showCloseButton: true,
            icon: "error",
            title: (
              <p className="modalTitle">
                {t(`join.alerts.createUserAccount.error.title`)}
              </p>
            ),
            html: (
              <p className="modalSubText">
                {t(`join.alerts.createUserAccount.error.message`)}
              </p>
            ),
            confirmButtonText: t(
              `join.alerts.createUserAccount.error.buttons.confirm`
            ),
            confirmButtonColor: `#44a16a`,
          });
        }
      }
      if (activeTab === "company") {
        if (createCompanyAccountLoading) {
          return;
        }

        const {
          data: { createCompanyAccount },
        } = await createCompanyAccountMutation({
          variables: {
            userId: data.userId,
            password: data.password,
            name: data.name,
            phone: data.phone,
            companyName: data.companyName,
            companyNumber: data.companyNumber,
            companyTel: data.companyTel,
            code: data.code,
          },
        });

        if (!createCompanyAccount.ok) {
          MySwal.fire({
            showCloseButton: true,
            icon: "error",
            title: (
              <p className="modalTitle">
                {t(`join.alerts.createCompanyAccount.error.title`)}
              </p>
            ),
            html: (
              <p className="modalSubText">
                {t(`join.alerts.createCompanyAccount.error.message`)}
              </p>
            ),
            confirmButtonText: t(
              `join.alerts.createCompanyAccount.error.buttons.confirm`
            ),
            confirmButtonColor: `#44a16a`,
          });
          // return toast.error(login.error);
        }
      }

      MySwal.fire({
        showCloseButton: true,
        imageUrl: Img,
        imageWidth: 80,
        imageHeight: 80,
        title: <p className="modalTitle">{t(`join.alerts.confirm.title`)}</p>,
        html: (
          <p className="modalSubText">{t(`join.alerts.confirm.message`)}</p>
        ),
        confirmButtonText: t(`join.alerts.confirm.buttons.confirm`),
        confirmButtonColor: `#44a16a`,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isDismissed || result.isConfirmed) {
          if (logInLoading) {
            return;
          }

          const {
            data: { login },
          } = await logInMutation({
            variables: {
              userId: data.userId,
              password: data.password,
            },
          });

          if (login.ok) {
            logUserIn(login.token, login.result);
          } else {
            MySwal.fire({
              showCloseButton: true,
              icon: "error",
              title: (
                <p className="modalTitle">{t(`login.alerts.error.title`)}</p>
              ),
              html: (
                <p className="modalSubText">
                  {t(`login.alerts.error.message`)}
                </p>
              ),
              confirmButtonText: t(`join.alerts.error.buttons.confirm`),
              confirmButtonColor: `#44a16a`,
            });
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onPhoneVerifySend = async () => {
    console.log("onPhoneVerifySend", watch("userId"));
    try {
      if (watch("userId") === undefined) {
        setError("userId", {
          type: "error",
          message: t(`join.id.rules.required`),
        });
        return;
      } else {
        clearErrors("userId");
      }
      if (watch("phone") === undefined || watch("phone")?.length < 10) {
        setError("phone", {
          type: "error",
          message: t(`join.phone.input.placeholder`),
        });
        return;
      } else {
        clearErrors("phone");
      }

      if (createPhoneVerifySendLoading) {
        return;
      }
      const {
        data: { createPhoneVerifySend },
      } = await createPhoneVerifySendMutation({
        variables: {
          user_userId: watch("userId"),
          user_phone: watch("phone"),
        },
      });

      if (!createPhoneVerifySend.ok) {
        setError("phone", {
          type: "error",
          message: createPhoneVerifySend.error,
        });
        return;
      }

      startCountdown();
    } catch (e) {
      console.log({ e });
    }
  };

  const [isCounting, setIsCounting] = useState(false);
  const [remainingTime, setRemainingTime] = useState(180);
  const startCountdown = () => {
    setIsCounting(true);
  };

  useEffect(() => {
    let intervalId;
    if (isCounting) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 0) {
            setIsCounting(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isCounting]);

  return (
    <>
      <div
        className=" h-full flex flex-col min-h-screen lg:block"
        style={{ backgroundColor: "#fafafa" }}
      >
        <HeaderNav isLogin={false} />
        <CloseHead title={t(`join.title`)} to="/login" />
        <div className="pt-5  pb-5 w-11/12 flex flex-col mr-auto ml-auto  justify-center md:m-auto minHeight900  md:w-5/6  lg:w-3/5 max-w-screen-md lg:pt-10 lg:pb-20">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="whiteShadowBox">
              <TreeTitle title={t(`join.title`)} />
              <div>
                <div className="tab-menu my-5 lg:my-10">
                  {[
                    { id: "user", label: "일반" },
                    { id: "company", label: "기업" },
                  ].map((tab) => (
                    <button
                      key={tab.id}
                      type="button"
                      className={activeTab === tab.id ? "active" : ""}
                      onClick={() => handleTabClick(tab.id)}
                    >
                      {t(`join.tabs.${tab.id}`)}
                    </button>
                  ))}
                </div>

                {activeTab === "user" && (
                  <General
                    Controller={Controller}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                    onPhoneVerifySend={onPhoneVerifySend}
                    isCounting={isCounting}
                    remainingTime={remainingTime}
                  />
                )}
                {activeTab === "company" && (
                  <Company
                    Controller={Controller}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    errors={errors}
                    onPhoneVerifySend={onPhoneVerifySend}
                    isCounting={isCounting}
                    remainingTime={remainingTime}
                  />
                )}

                <div className="mb-10">
                  <p className="inputLabel">{t(`join.policy.title`)}</p>
                  <div className="relative my-2 flex justify-between items-center py-2 md:my-4">
                    <div className="flex items-center">
                      <input
                        className="checkInput"
                        type="checkbox"
                        id="agree_check_all"
                        name="agree_check_all"
                        checked={watch("privacypolicy") && watch("termsAgreed")}
                        onChange={handleAllChecked}
                      />
                      <label htmlFor="agree_check_all" className="checkLabel">
                        <p>{t(`join.policy.items.all.text`)}</p>
                      </label>
                    </div>
                  </div>
                  <Controller
                    control={control}
                    rules={{
                      required: t(
                        `join.policy.items.service.input.placeholder`
                      ),
                    }}
                    name="termsAgreed"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <div className="relative my-2 flex justify-between items-center py-2 md:my-4">
                          <div className="flex items-center">
                            <input
                              className="checkInput"
                              type="checkbox"
                              id="agree_check_used"
                              name="termsAgreed"
                              checked={value}
                              onChange={onChange}
                            />
                            <label
                              htmlFor="agree_check_used"
                              className="checkLabel sublabel"
                            >
                              <p>
                                <span>
                                  {t(`join.policy.items.service.required`)}{" "}
                                </span>
                                {t(`join.policy.items.service.text`)}
                              </p>
                            </label>
                          </div>
                          <ShowModalButton
                            text={t(`join.policy.items.service.show`)}
                            onClick={toggleTerms}
                          />
                        </div>
                        {errors.termsAgreed && (
                          <div
                            className="relative mb-2 flex justify-between items-center"
                            style={{ marginTop: -20 }}
                          >
                            <InputErrorText text={errors.termsAgreed.message} />
                          </div>
                        )}
                      </>
                    )}
                  />
                  <Controller
                    control={control}
                    rules={{
                      required: t(
                        `join.policy.items.privacy.input.placeholder`
                      ),
                    }}
                    name="privacypolicy"
                    render={({ field: { onChange, value } }) => (
                      <>
                        <div className="relative my-2 flex justify-between items-center py-2 md:my-4">
                          <div className="flex items-center">
                            <input
                              className="checkInput"
                              type="checkbox"
                              id="agree_check_info"
                              name="privacypolicy"
                              checked={value}
                              onChange={onChange}
                            />

                            <label
                              htmlFor="agree_check_info"
                              className="checkLabel sublabel"
                            >
                              <p>
                                <span>
                                  {t(`join.policy.items.privacy.required`)}{" "}
                                </span>
                                {t(`join.policy.items.privacy.text`)}
                              </p>
                            </label>
                          </div>

                          <ShowModalButton
                            text={t(`join.policy.items.privacy.show`)}
                            onClick={togglePrivacy}
                          />
                        </div>
                        {errors.privacypolicy && (
                          <div
                            className="relative mb-2 flex justify-between items-center"
                            style={{ marginTop: -20 }}
                          >
                            <InputErrorText
                              text={errors.privacypolicy.message}
                            />
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>

                <div className="mt-20">
                  <DefaultButton
                    type={"submit"}
                    text={t(`join.buttons.confirm.text`)}
                    onClick={onSubmit}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <Footer />
      </div>
      <PrivacyModal isOpen={privacy} closeModal={togglePrivacy} />
      <TemrsModal isOpen={isTerms} closeModal={toggleTerms} />
    </>
  );
};
