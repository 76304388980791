import React from "react";
import MypageInfo from "../../layout/mypage/info/Info";
import {
  faBadgeCheck,
  faBars,
  faBell,
  faListCheck,
  faMessageCheck,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import SideName from "../../components/mypage/SideName";
import MypageLayout from "../../layout/mypage/MypageLayout";
import { useTranslation } from "react-i18next";

function AccountEdit() {
  const { t } = useTranslation();
  const meJson = localStorage.getItem("ME");
  const me = meJson && JSON.parse(meJson);

  return (
    <MypageLayout title={t(`header.account.edit`)}>
      <div className="hidden lg:block sideFixed">
        <div>
          <SideName />
          <ul>
            <li>
              <Link className="menuActive sideMenu" to="/account/edit">
                <FontAwesomeIcon
                  icon={faUser}
                  className="sideIcon whiteActive"
                />
                <p className="sideText whiteActive">
                  {t(`header.account.edit`)}
                </p>
              </Link>
            </li>
            <li>
              <Link className="sideMenu" to="/account/user/reservations">
                <FontAwesomeIcon icon={faBars} className="sideIcon" />
                <p className="sideText">
                  {t(`header.account.user.reservations`)}
                </p>
              </Link>
            </li>
            {me.role === "Company" && (
              <li>
                <Link className="sideMenu" to="/account/company/reservations">
                  <FontAwesomeIcon icon={faMessageCheck} className="sideIcon" />
                  <p className="sideText">
                    {t(`header.account.company.reservations`)}
                  </p>
                </Link>
              </li>
            )}
            <li>
              <Link className="sideMenu" to="/account/notification">
                <FontAwesomeIcon icon={faBell} className="sideIcon" />
                <p className="sideText">{t(`header.account.notification`)}</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex-1">
        <MypageInfo />
      </div>
    </MypageLayout>
  );
}

export default AccountEdit;
