import React from 'react';
import styled from 'styled-components';
import LogoSVG from '../../assets/images/user/twotree.svg';

const Wrap = styled.div`
    align-items: flex-end;
`;

const Title = styled.p`
    color: #333;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -0.8px;
    @media (max-width: 768px) {
        font-size: 18px;
    }
`;
const Tree = styled.div`
    width: 68px;
    margin-left: 12px;
    margin-bottom: 8px;
    @media (max-width: 768px) {
        width: 32px;
        margin-bottom: 4px;
        margin-left: 8px;
    }
`;

export default ({ title }) => {
    return (
        <>
            <Wrap className="hidden lg:flex">
                <Title>{title}</Title>
                <Tree>
                    <img src={LogoSVG} alt="메타숲 로고" />
                </Tree>
            </Wrap>
        </>
    );
};
