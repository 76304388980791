import React from 'react';
import ReactMoment from 'react-moment';
import styled from 'styled-components';
import NoNotification from '../../../components/data/NoNotification';
import { TableHeadTitle } from '../../../components/forest/table/TableWidget';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { IconButton } from '../../../components/linkButton/IconButton';
import Pagination from '../../../components/pagination/Pagination';
import Loading from '../../../components/page/Loading';
import { useReactiveVar } from '@apollo/client';
import { languageVar } from '../../../apollo';
import { useTranslation } from 'react-i18next';

import { useSearchParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import {
    NOTIFICATIONDETAIL_LIST_QUERY,
    NOTIFICATIONDETAIL_DELETE_MUTATION,
    NOTIFICATIONDETAIL_ALLDELETE_MUTATION,
} from '../../../lib/queries/notification';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

const Title = styled.p`
    font-size: 18px;
    font-weight: 500;
    color: #1a1a1a;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;
const DeleteButton = styled.button`
    background: #f2f2f2;
    padding: 13px 18px 11px;
    border-radius: 10px;
`;
const TrashIcon = styled(FontAwesomeIcon)`
    width: 30px;
    color: #ff6d6d;
    font-size: 18px;
    margin-bottom: 3px;
`;
const DeleteText = styled.p`
    color: #949494;
    font-size: 15px;
`;
export default () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const [notificationDetailAllDeleteMutation, { loading: notificationDetailAllDeleteLoading }] =
        useMutation(NOTIFICATIONDETAIL_ALLDELETE_MUTATION, {
            refetchQueries: () => [
                {
                    query: NOTIFICATIONDETAIL_LIST_QUERY,
                    variables: {
                        language,
                        take,
                        skip,
                    },
                },
            ],
        });
    const onAllDelete = async () => {
        try {
            if (notificationDetailAllDeleteLoading) {
                return;
            }
            MySwal.fire({
                showCloseButton: true,
                icon: 'warning',
                title: (
                    <p className="modalTitle">
                        {t(`account.notification.alerts.delete.confirm.title`)}
                    </p>
                ),
                html: (
                    <p
                        className="modalSubText"
                        dangerouslySetInnerHTML={{
                            __html: t(`account.notification.alerts.delete.confirm.message`),
                        }}
                    />
                ),

                confirmButtonText: t(`account.notification.alerts.delete.confirm.buttons.confirm`),
                confirmButtonColor: `#44a16a`,

                showCancelButton: true,
                cancelButtonText: t(`account.notification.alerts.delete.confirm.buttons.cancel`),
                cancelButtonColor: `rgb(206, 206, 206)`,

                allowOutsideClick: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const {
                        data: { notificationDetailAllDelete },
                    } = await notificationDetailAllDeleteMutation();

                    if (notificationDetailAllDelete.ok) {
                    }
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    const [notificationDetailDeleteMutation, { loading: notificationDetailDeleteLoading }] =
        useMutation(NOTIFICATIONDETAIL_DELETE_MUTATION, {
            refetchQueries: () => [
                {
                    query: NOTIFICATIONDETAIL_LIST_QUERY,
                    variables: { language, take, skip },
                },
            ],
        });
    const onDelete = (item) => {
        try {
            if (notificationDetailDeleteLoading) {
                return;
            }
            MySwal.fire({
                showCloseButton: true,
                icon: 'warning',
                title: (
                    <p className="modalTitle">
                        {t(`account.notification.alerts.delete.confirm.title`)}
                    </p>
                ),
                html: (
                    <p
                        className="modalSubText"
                        dangerouslySetInnerHTML={{
                            __html: t(`account.notification.alerts.delete.confirm.message`),
                        }}
                    />
                ),

                confirmButtonText: t(`account.notification.alerts.delete.confirm.buttons.confirm`),
                confirmButtonColor: `#44a16a`,

                showCancelButton: true,
                cancelButtonText: t(`account.notification.alerts.delete.confirm.buttons.cancel`),
                cancelButtonColor: `rgb(206, 206, 206)`,

                allowOutsideClick: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const {
                        data: { notificationDetailDelete },
                    } = await notificationDetailDeleteMutation({
                        variables: {
                            id: item.id,
                        },
                    });

                    if (notificationDetailDelete.ok) {
                    }
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    const language = useReactiveVar(languageVar);
    const page = searchParams.get('page') ? +searchParams.get('page') : 1;
    const baseUrl = `/account/notification?`;

    const take = 12;
    const skip = (page - 1) * take;
    const { data, error, loading } = useQuery(NOTIFICATIONDETAIL_LIST_QUERY, {
        fetchPolicy: 'network-only',
        variables: { language, take, skip },
    });

    if (loading) {
        return <Loading text={t(`account.notification.loading.message`)} />;
    }
    if (error) {
        return <>{error.message}</>;
    }

    return (
        <div>
            <p className="hidden lg:block pageTitle">{t(`account.notification.title`)}</p>

            {data.notificationDetailList.totalResults > 0 ? (
                <div>
                    <DeleteButton
                        className="flex items-center  ml-auto"
                        onClick={onAllDelete}
                        type={'button'}
                    >
                        <TrashIcon icon={faTrashCan} />
                        <DeleteText>{t(`account.notification.buttons.allDelete`)}</DeleteText>
                    </DeleteButton>
                    <div>
                        <div className="mb-3 mt-3 lg:mb-20 lg:mt-7">
                            <div className="hidden lg:flex  justify-between text-center items-center py-5 px-5 tableHead">
                                <div className="text-center w-2/3">
                                    <TableHeadTitle
                                        title={t(`account.notification.headers.message`)}
                                    />
                                </div>
                                <div className="text-center w-1/3">
                                    <TableHeadTitle
                                        title={t(`account.notification.headers.createdAt`)}
                                    />
                                </div>
                            </div>
                            {data.notificationDetailList.results.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="px-0 py-2 flex flex-col md:flex-row justify-between tableList items-center md:py-5 md:px-5"
                                    >
                                        <div className="text-left w-full md:w-2/3">
                                            <Title>{item.notification.title}</Title>
                                            <span
                                                className=" tableText"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.notification.body,
                                                }}
                                            ></span>
                                        </div>
                                        <div className="w-full md:w-1/3 flex flex-row items-center   justify-between md:justify-around">
                                            <span className="textWrap tableText">
                                                <ReactMoment format="YYYY-MM-DD">
                                                    {item.createdAt}
                                                </ReactMoment>
                                            </span>
                                            <div className="ml-5">
                                                <IconButton
                                                    icon={faTrashCan}
                                                    type={'button'}
                                                    onClick={() => onDelete(item)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}

                            <div className="w-full flex my-10 justify-center">
                                <Pagination
                                    totalRecord={data.notificationDetailList.totalResults}
                                    blockSize={5}
                                    pageSize={take}
                                    currentPage={page}
                                    baseUrl={baseUrl}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="py-14">
                    <NoNotification />
                </div>
            )}
        </div>
    );
};
