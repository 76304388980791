import React, { useState } from "react";
import { faFilterList, faGrid2, faMap } from "@fortawesome/pro-solid-svg-icons";
import { NumericFormat } from "react-number-format";
import FilterDropDownButton from "../../components/button/FilterDropDownButton";
import GridForest from "../../components/forest/GridForest";
import Pagination from "../../components/pagination/Pagination";
import {
  IconButton,
  WhiteIconButton,
} from "../../components/linkButton/IconButton";
import FilterModal from "../../components/modals/login/FilterModal";
import NoForest from "../../components/data/NoForest";
import { useTranslation } from "react-i18next";

export default ({ filter, sort, page, baseUrl, take, data, setListType }) => {
  const { t } = useTranslation();

  const [isShow, setIsShow] = useState(false);
  const toggleModal = () => {
    setIsShow(!isShow);
  };

  return (
    <>
      <div className="py-5 lg:py-10">
        <div className="flex justify-between items-center my-5 lg:my-12">
          <div className="forestLength">
            {t(`mountain.list.items.title`)}{" "}
            <span>
              (
              <NumericFormat
                value={data.mountainList.totalResults}
                displayType="text"
                thousandSeparator={true}
              />
              )
            </span>
          </div>

          <div className="flex items-center">
            <div className="hidden lg:flex items-center relative">
              <FilterDropDownButton
                baseurl="/mountains?"
                info={{
                  key: "filter",
                  value: t(`mountain.list.selects.filter.title`),
                }}
                options={[
                  {
                    key: "",
                    value: t(`mountain.list.selects.filter.options.all`),
                  },
                  {
                    key: "barrierfree",
                    value: t(
                      `mountain.list.selects.filter.options.barrierfree`
                    ),
                  },
                  {
                    key: "program",
                    value: t(`mountain.list.selects.filter.options.program`),
                  },
                ]}
                selected={filter}
              />
              <FilterDropDownButton
                baseurl="/mountains?"
                info={{
                  key: "sort",
                  value: t(`mountain.list.selects.sort.title`),
                }}
                options={[
                  {
                    key: "distance",
                    value: t(`mountain.list.selects.sort.options.distance`),
                  },
                  {
                    key: "name",
                    value: t(`mountain.list.selects.sort.options.name`),
                  },
                  {
                    key: "height",
                    value: t(`mountain.list.selects.sort.options.height`),
                  },
                ]}
                selected={sort}
              />
            </div>
            <div className="flex items-center viewStyleWrap ml-5">
              <IconButton
                type={"button"}
                icon={faGrid2}
                onClick={() => {
                  localStorage.setItem("LISTTYPE", "MAP");
                  setListType("MAP");
                }}
              />
              <WhiteIconButton
                type={"button"}
                icon={faMap}
                onClick={() => {
                  localStorage.setItem("LISTTYPE", "MAP");
                  setListType("MAP");
                }}
              />
            </div>
            {/* <div className="flex lg:hidden">
                            <IconButton icon={faFilterList} onClick={toggleModal} />
                        </div> */}
          </div>
        </div>
        {data.mountainList.totalResults > 0 ? (
          <div className="flex-wrap flex">
            {data.mountainList.results.map((item, index) => {
              return (
                <GridForest
                  key={index}
                  to={`/mountains/${item.id}`}
                  isBarrierfree={item.isBarrierfree}
                  isProgram={item.isProgram}
                  img={item.commonFile?.fileUrl}
                  name={item.name}
                  address={
                    item.roadAddress ? item.roadAddress : item.jibunAddress
                  }
                />
              );
            })}
          </div>
        ) : (
          <NoForest />
        )}

        <div className="w-full flex my-10 justify-center">
          <Pagination
            totalRecord={data.mountainList.totalResults}
            blockSize={5}
            pageSize={take}
            currentPage={page}
            baseUrl={baseUrl}
          />
        </div>
      </div>
      <FilterModal isOpen={isShow} closeModal={toggleModal} />
    </>
  );
};
