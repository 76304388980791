import React from "react";
import styled from "styled-components";
import RegionLink from "../../components/button/RegionLink";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { COMMON_REGION_LIST_QUERY } from "../../lib/queries/mountain";

const SelectWrap = styled.div`
  margin: 0 -10px;
`;

export default ({ keyword, region, filter, sort }) => {
  const { t } = useTranslation();

  const { data, error, loading } = useQuery(COMMON_REGION_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      take: 100,
      skip: 0,
    },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <></>;
  }

  return (
    <SelectWrap className="hidden md:inline-flex flex-wrap">
      {data.commonRegionList.results.map((item, index) => {
        const url =
          "/mountains?" +
          (keyword ? `keyword=${keyword}&` : ``) +
          (item.id !== 0 ? `region=${item.id}&` : ``) +
          (filter ? `filter=${filter}&` : ``) +
          (sort ? `sort=${sort}&` : ``) +
          `page=1`;

        return (
          <RegionLink
            key={index}
            to={url}
            active={item.id === +region}
            region={t(`main.region.options.${item.id}`)}
            length={item.count}
          />
        );
      })}
    </SelectWrap>
  );
};
