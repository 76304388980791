import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GraySearch from '../../components/forest/GraySearch';
import FilterModal from '../../components/modals/login/FilterModal';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import NoForest from '../../components/data/NoForest';

import MobileMapList from '../../components/forest/MobileMapList';
import Map from './Map';
import MapStyleViewButton from '../../components/maps/MapStyleViewButton';
import { FilterIconButton, IconButton } from '../../components/linkButton/IconButton';
import NoFrestRow from '../../components/data/NoFrestRow';

export const MIN_Y = 60;
export const MAX_Y = window.innerHeight - 160;
export const BOTTOM_SHEET_HEIGHT = window.innerHeight - MIN_Y;
const Search = styled.div`
    background-color: white;
    padding: 20px 15px;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: start;
    @media (max-width: 768px) {
        padding: 10px 5px;
    }
`;
const MapWrap = styled.div`
    height: 100%;
    position: relative;
`;
const BaseDiv = styled.div`
    background-color: transparent;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 20px;
    z-index: 10;
    width: 100%;
    height: auto;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    @media (max-width: 768px) {
        height: auto;
    }
`;

export default ({ keyword, region, filter, sort, data, setListType, baseUrl }) => {
    const [isShow, setIsShow] = useState(false);
    const toggleModal = () => {
        setIsShow(!isShow);
    };
    const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

    useEffect(() => {
        const keyboardOpenHandler = () => {
            setIsKeyboardOpen(true);
        };

        const keyboardCloseHandler = () => {
            setIsKeyboardOpen(false);
        };

        window.addEventListener('keyboardWillShow', keyboardOpenHandler);
        window.addEventListener('keyboardWillHide', keyboardCloseHandler);

        return () => {
            window.removeEventListener('keyboardWillShow', keyboardOpenHandler);
            window.removeEventListener('keyboardWillHide', keyboardCloseHandler);
        };
    }, []);
    return (
        <>
            <div className="flex-1 relative w-full h-full">
                <Search>
                    <GraySearch
                        keyword={keyword}
                        region={region}
                        filter={filter}
                        sort={sort}
                        data={data}
                    />
                    <div className="2xl:hidden flex  py-0 md:py-3 pl-2 md:pl-5 md:pr-2 h-full">
                        <FilterIconButton onClick={toggleModal} />
                    </div>
                </Search>

                <MapWrap>
                    {data.mountainList.totalResults > 0 ? (
                        <Map data={data} baseUrl={baseUrl} />
                    ) : (
                        <NoForest />
                    )}

                    <MapStyleViewButton setListType={setListType} />
                </MapWrap>
                <BaseDiv className="{${isKeyboardOpen ? 'keyboard-open' : ''}}">
                    <Swiper
                        slidesPerView={1.1} // 한 번에 보여질 슬라이드 개수 (첫 번째 슬라이드만 보이도록 1로 설정)
                        centeredSlides={true} // 첫 번째 슬라이드를 가운데에 배치
                        spaceBetween={10}
                        grabCursor={true}
                        className="mySwiper"
                    >
                        {data.mountainList.totalResults > 0 ? (
                            <div className="flex-col flex">
                                {data.mountainList.results.map((item, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <MobileMapList
                                                key={index}
                                                to={`/mountains/${item.id}`}
                                                isBarrierfree={item.isBarrierfree}
                                                isProgram={item.isProgram}
                                                img={item.commonFile?.fileUrl}
                                                name={item.name}
                                                address={
                                                    item.roadAddress
                                                        ? item.roadAddress
                                                        : item.jibunAddress
                                                }
                                            />
                                        </SwiperSlide>
                                    );
                                })}
                            </div>
                        ) : null}
                    </Swiper>
                </BaseDiv>
            </div>
            <FilterModal isOpen={isShow} closeModal={toggleModal} />
        </>
    );
};
