import { useReactiveVar } from "@apollo/client";
import { Navigate, useOutlet } from "react-router-dom";
import { isLoggedInVar } from "../apollo";

export const PublicAuth = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const outlet = useOutlet();

  if (isLoggedIn) {
    return <Navigate to={"/main"} />;
  }

  return <div>{outlet}</div>;
};

export const ProtectedAuth = () => {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const outlet = useOutlet();

  if (!isLoggedIn) {
    return <Navigate to={"/main"} />;
  }

  return <div>{outlet}</div>;
};
