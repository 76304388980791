import { gql } from "@apollo/client";

/** 목록 **/
export const BANNER_LIST_QUERY = gql`
  query bannerList($take: Int, $skip: Int!) {
    bannerList(input: { take: $take, skip: $skip }) {
      ok
      results {
        id
        createdAt
        updatedAt

        commonState
        title
        isLink
        url
        pcImage {
          id
          fileUrl
        }
        mobileImage {
          id
          fileUrl
        }
      }
      error
      totalResults
      totalPages
    }
  }
`;
