import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import LogoSVG from '../../assets/images/tree.svg';
import { useTranslation } from 'react-i18next';
import colLogo from '../../assets/images/colLogo.svg';
import { Link } from 'react-router-dom';
const CloseButton = styled.button`
    height: 50px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
`;
export const Close = ({ onClick }) => {
    return (
        <>
            <CloseButton onClick={onClick}>
                <FontAwesomeIcon icon={faClose} style={{ fontSize: 22, color: '#868686' }} />
            </CloseButton>
        </>
    );
};

const Img = styled.div`
    width: 35px;
`;
const Name = styled.p`
    font-size: 18px;
    font-weight: 600;
    margin-bottom: -2px;
`;
const Id = styled.span`
    font-size: 14px;
    color: #a3a3a3;
`;
export const IsLoginTop = () => {
    const meJson = localStorage.getItem('ME');
    const me = meJson && JSON.parse(meJson);
    return (
        <div className="flex flex-col mb-8">
            <Img>
                <img src={colLogo} alt="Logo" />
            </Img>
            <div className="mt-3">
                <Name>{me.role === 'Company' ? me.company.companyName : me?.name}</Name>
                <Id>@{me?.userId}</Id>
            </div>
        </div>
    );
};
const InfoText = styled.p`
    font-size: 15px;
    color: #8c8c8c;
`;
const LoginButton = styled(Link)`
    background-color: #44a16a;
    color: #fff;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0 8px;
    border-radius: 7px;
    font-size: 15px;
`;
const JoinButton = styled(Link)`
    background-color: #e9e9e9;
    color: #8a8a8a;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px 0 8px;
    border-radius: 7px;
    font-size: 15px;
`;

export const IsLogoutTop = () => {
    const { t } = useTranslation();
    return (
        <div>
            <div className="flex justify-start items-center mb-4">
                <InfoText className="">{t(`drawer.login.text`)}</InfoText>
            </div>
            <div className="flex justify-between w-full mb-8">
                <div className=" flex-1">
                    <LoginButton to={`/login`}>{t(`drawer.login.buttons.login`)}</LoginButton>
                </div>
                <div className="mx-1" />
                <div className=" flex-1">
                    <JoinButton to={`/join`}>{t(`drawer.login.buttons.join`)}</JoinButton>
                </div>
            </div>
        </div>
    );
};
