import React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import ReactDOM from "react-dom/client";
import Routes from "./lib/routes";
import { ApolloProvider } from "@apollo/client";
import { NavermapsProvider } from "react-naver-maps";
import { client } from "./apollo";
import "./locales/i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
function App() {
  let element = useRoutes(Routes);

  return element;
}
root.render(
  <BrowserRouter>
    <React.StrictMode>
      <ApolloProvider client={client}>
        <NavermapsProvider ncpClientId="3k1rx78mav">
          <App />
        </NavermapsProvider>
      </ApolloProvider>
    </React.StrictMode>
  </BrowserRouter>
);
