import React from "react";
import Header from "../../layout/header/HeaderNav";
import Footer from "../../layout/footer/Footer";
import ForestDetail from "../../layout/forest/ForestDetail";
import MobileFooter from "../../layout/footer/MobileFooter";
import { useReactiveVar } from "@apollo/client";
import { languageVar } from "../../apollo";

import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { MOUNTAIN_READ_QUERY } from "../../lib/queries/mountain";
import MobileHead from "../../layout/header/MobileHead";

export default () => {
  const location = useLocation();

  const language = useReactiveVar(languageVar);
  const params = useParams();
  const id = params.id;

  const { data, error, loading } = useQuery(MOUNTAIN_READ_QUERY, {
    fetchPolicy: "network-only",
    variables: { language, id: +id },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  const {
    mountainRead: {
      result: { name },
    },
  } = data;

  return (
    <div style={{ backgroundColor: "#FBFBFB" }}>
      <Header isGrey />
      <MobileHead title={name} isBack />
      <ForestDetail data={data} />
      <Footer />
      <MobileFooter />
    </div>
  );
};
