import React from "react";
import CoverImage from "../CoverImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarWeek,
  faHouse,
  faPhone,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import ReactMoment from "react-moment";
import { MobileBetweenDateTable, MobileBetweenTable } from "./MobileTable";
import {
  ProgramTitle,
  TableDateText,
  TableIconDate,
  TableIconLabel,
  TableImg,
  TableProgramInfo,
  TableText,
  TableTwoLineText,
} from "./TableWidget";
import { useTranslation } from "react-i18next";

export default ({ img, title, tel, date, company, persons, requestDate }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="tableList">
        {/* pc */}
        <div className="hidden xl:flex justify-between items-center py-6">
          <div className="flex items-center w-2/6">
            <TableProgramInfo img={img} title={title} date={date} />
          </div>
          <div className=" flex flex-col justify-center items-center w-2/6">
            <TableTwoLineText first={company} second={tel} />
          </div>
          <div className="w-1/6 text-center ">
            <TableText text={persons} />
          </div>
          <div className="w-1/6 text-center ">
            <TableDateText date={requestDate} />
          </div>
        </div>
        {/* 모바일 */}
        <div className="flex justify-between flex-col sm:flex-row py-5 xl:hidden">
          <TableImg img={img} />
          <div className="ml-0 mt-4 flex-1 flex flex-col sm:ml-5 sm:mt-0">
            <ProgramTitle text={title} />
            <div className="flex items-center">
              <TableDateText date={date} />
            </div>
            <div className="flex  w-full items-start sm:items-center mt-0 sm:mt-1 flex-col sm:flex-row">
              <TableIconLabel
                icon={faUsers}
                label={t(`account.user.reservation.items.persons`)}
                value={persons}
              />
              <div className="pl-0 sm:pl-3" />
              <TableIconDate
                label={t(`account.user.reservation.items.createdAt`)}
                date={requestDate}
              />
            </div>
            <div className="flex  items-start sm:items-center mt-0 sm:mt-1 flex-col sm:flex-row">
              <TableIconLabel
                icon={faHouse}
                label={t(`account.user.reservation.items.companyName`)}
                value={company}
              />
              <div className="pl-0 sm:pl-3" />
              <TableIconLabel
                icon={faPhone}
                label={t(`account.user.reservation.items.companyTel`)}
                value={tel}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
