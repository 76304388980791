import { faGrid2, faMap } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { IconButton, WhiteIconButton } from '../linkButton/IconButton';

export default ({ setListType }) => {
    return (
        <>
            <div className="flex items-center absolute  z-10 top-3 left-3 bg-white  rounded-xl viewStyleWrap">
                <WhiteIconButton
                    type={'button'}
                    icon={faGrid2}
                    onClick={() => {
                        localStorage.setItem('LISTTYPE', 'LIST');
                        setListType('LIST');
                    }}
                />
                <IconButton
                    type={'button'}
                    icon={faMap}
                    onClick={() => {
                        localStorage.setItem('LISTTYPE', 'LIST');
                        setListType('LIST');
                    }}
                />
            </div>
        </>
    );
};
