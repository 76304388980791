import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderNav from '../../layout/header/HeaderNav';
import Footer from '../../layout/footer/Footer';
import TreeTitle from '../../components/logout/TreeTitle';
import InputErrorText from '../../components/logout/InputErrorText';
import ClearButton from '../../components/button/ClearButton';
import { useTranslation } from 'react-i18next';

import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import {
    ID_FINDER_PHONE_VERIFY_SEND_MUTATION,
    ID_FINDER_PHONE_VERIFY_MUTATION,
} from '../../lib/queries/user';

import Img from '../../assets/images/user/modalIcon.svg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { InputSubButton } from '../../components/linkButton/InputSub';
import CloseHead from '../../layout/header/CloseHead';
import { DefaultButton } from '../../components/linkButton/Default';
const MySwal = withReactContent(Swal);

export default () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [idFinderPhoneVerifySendMutation, { idFinderPhoneVerifySendLoading }] = useMutation(
        ID_FINDER_PHONE_VERIFY_SEND_MUTATION,
    );
    const [idFinderPhoneVerifyMutation, { idFinderPhoneVerifyLoading }] = useMutation(
        ID_FINDER_PHONE_VERIFY_MUTATION,
    );

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        setError,
        reset,
    } = useForm({
        defaultValues: {
            userId: '',
            password: '',
        },
    });

    const onSubmit = async (data) => {
        console.log({ data });
        try {
            if (idFinderPhoneVerifyLoading) {
                return;
            }

            const {
                data: { idFinderPhoneVerify },
            } = await idFinderPhoneVerifyMutation({
                variables: {
                    user_phone: data.user_phone,
                    code: data.code,
                },
            });

            if (!idFinderPhoneVerify.ok) {
                MySwal.fire({
                    showCloseButton: true,
                    icon: 'error',
                    title: (
                        <p className="modalTitle">
                            {t(`idfind.alerts.idFinderPhoneVerify.error.title`)}
                        </p>
                    ),
                    html: (
                        <p className="modalSubText">
                            {t(`idfind.alerts.idFinderPhoneVerify.error.message`)}
                        </p>
                    ),
                    confirmButtonText: t(`idfind.alerts.idFinderPhoneVerify.error.buttons.confirm`),
                    confirmButtonColor: `#44a16a`,
                });
                return;
            }
            MySwal.fire({
                showCloseButton: true,
                imageUrl: Img,
                imageWidth: 80,
                imageHeight: 80,
                title: (
                    <p className="modalTitle">
                        {t(`idfind.alerts.idFinderPhoneVerify.confirm.title`)}
                    </p>
                ),
                html: (
                    <p className="modalSubText">
                        {t(`idfind.alerts.idFinderPhoneVerify.confirm.message`)}
                        <span className="ModalGreenText">ID : {idFinderPhoneVerify.userId}</span>
                    </p>
                ),
                confirmButtonText: t(`idfind.alerts.idFinderPhoneVerify.error.confirm.confirm`),

                confirmButtonColor: `#44a16a`,
                allowOutsideClick: false,
            }).then((result) => {
                if (result.isDismissed || result.isConfirmed) {
                    navigate('/login');
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onPhoneVerifySend = async () => {
        try {
            if (watch('user_phone') === undefined || watch('user_phone')?.length < 10) {
                setError('user_phone', {
                    type: 'error',
                    message: t(`idfind.phone.rules.required`),
                });
                return;
            }

            if (idFinderPhoneVerifySendLoading) {
                return;
            }
            const {
                data: { idFinderPhoneVerifySend },
            } = await idFinderPhoneVerifySendMutation({
                variables: {
                    user_phone: watch('user_phone'),
                },
            });

            if (!idFinderPhoneVerifySend.ok) {
                setError('user_phone', {
                    type: 'error',
                    message: t(`idfind.phone.errors.exception`),
                });
                return;
            }
            startCountdown();
        } catch (e) {
            console.log({ e });
        }
    };
    const [isCounting, setIsCounting] = useState(false);
    const [remainingTime, setRemainingTime] = useState(180);
    const startCountdown = () => {
        setIsCounting(true);
    };

    useEffect(() => {
        let intervalId;
        if (isCounting) {
            intervalId = setInterval(() => {
                setRemainingTime((prevTime) => {
                    if (prevTime <= 0) {
                        setIsCounting(false);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
        }

        return () => clearInterval(intervalId);
    }, [isCounting]);
    return (
        <>
            <div
                className=" h-full flex flex-col min-h-screen lg:block"
                style={{ backgroundColor: '#fafafa' }}
            >
                <HeaderNav isLogin={false} />
                <CloseHead title={t(`idfind.title`)} to="/login" />
                <div className="w-11/12 flex flex-col justify-center m-auto minHeight900  md:w-5/6  lg:w-3/5 max-w-screen-md md:pt-10 md:pb-20 pt-0 pb-0">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="whiteShadowBox">
                            <TreeTitle title={t(`idfind.title`)} />
                            <div>
                                <Controller
                                    control={control}
                                    rules={{
                                        required: t(`idfind.phone.rules.required`),
                                    }}
                                    name="user_phone"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <div className="py-5 ">
                                            <p className="mb-0 inputLabel sm:mb-3">
                                                {t(`idfind.phone.title`)}
                                            </p>
                                            <div className="mt-3 flex-col flex justify-between  items-stretch sm:mt-5 md:flex-row">
                                                <div className="inputGreyBox flex items-center justify-between">
                                                    <input
                                                        type="text"
                                                        className="defaultInput"
                                                        placeholder={t(
                                                            `idfind.phone.input.placeholder`,
                                                        )}
                                                        maxLength={12}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                    />
                                                    {value !== undefined && value !== '' && (
                                                        <ClearButton
                                                            onClick={() =>
                                                                setValue('user_phone', '')
                                                            }
                                                        />
                                                    )}
                                                </div>
                                                <InputSubButton
                                                    onClick={() => {
                                                        onPhoneVerifySend();
                                                    }}
                                                    type="button"
                                                    text={t(`idfind.phone.button.text`)}
                                                />
                                            </div>
                                            {errors.user_phone && (
                                                <InputErrorText text={errors.user_phone.message} />
                                            )}
                                        </div>
                                    )}
                                />
                                <Controller
                                    control={control}
                                    rules={{
                                        required: t(`idfind.code.rules.required`),
                                    }}
                                    name="code"
                                    render={({ field: { onChange, onBlur, value } }) => (
                                        <div className="mb-10">
                                            <div className="block md:flex justify-between">
                                                <div className="inputGreyBox flex items-center justify-between">
                                                    <input
                                                        className="defaultInput"
                                                        id="code"
                                                        type="text"
                                                        placeholder={t(
                                                            `idfind.code.input.placeholder`,
                                                        )}
                                                        maxLength={12}
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        value={value}
                                                    />
                                                    {value !== undefined && value !== '' && (
                                                        <ClearButton
                                                            onClick={() => setValue('code', '')}
                                                        />
                                                    )}
                                                    {isCounting ? (
                                                        <p className="timers">{`${Math.floor(
                                                            remainingTime / 60,
                                                        )}:${String(remainingTime % 60).padStart(
                                                            2,
                                                            '0',
                                                        )}`}</p>
                                                    ) : null}
                                                </div>
                                            </div>
                                            {errors.code && (
                                                <InputErrorText text={errors.code.message} />
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className="mt-5 md:mt-14">
                                <DefaultButton
                                    type="submit"
                                    text={t(`idfind.buttons.confirm.text`)}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <Footer />
            </div>
        </>
    );
};
