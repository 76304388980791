import React, { useEffect, useState } from "react";
import ClearButton from "../../../components/button/ClearButton";
import InputErrorText from "../../../components/logout/InputErrorText";
import { InputSubButton } from "../../../components/linkButton/InputSub";
import Switch from "react-switch";
import { DefaultButton } from "../../../components/linkButton/Default";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/client";
import {
  UPDATE_PHONE_VERIFY_SEND_MUTATION,
  UPDATE_COMPANY_ACCOUNT_MUTATION,
  WITHDRAWAL_MUTATION,
} from "../../../lib/queries/user";
import { logUserUpdate, logUserOut } from "../../../apollo";

import Img from "../../../assets/images/user/modalIcon.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TextUnderLineButton } from "../../../components/linkButton/TextUnderLine";
import Lines from "../../../components/forest/Lines";
import {
  CheckedIcon,
  UncheckedIcon,
} from "../../../components/button/SwitchIcon";
const MySwal = withReactContent(Swal);

export default ({ data }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const [
    updatePhoneVerifySendMutation,
    { loading: updatePhoneVerifySendLoading },
  ] = useMutation(UPDATE_PHONE_VERIFY_SEND_MUTATION);
  const [updateCompanyAccountMutation, { updateCompanyAccountLoading }] =
    useMutation(UPDATE_COMPANY_ACCOUNT_MUTATION);
  const [withdrawalMutation, { withdrawalLoading }] =
    useMutation(WITHDRAWAL_MUTATION);

  const {
    me: {
      id,

      role,
      userId,
      name,
      phone,
      isNotification,
      company,
    },
  } = data;

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      password: "",
      passwordConfirm: "",
      name,
      phone,
      companyName: company.companyName,
      companyNumber: company.companyNumber,
      companyTel: company.companyTel,
      code: "",
      isNotification,
    },
  });

  const onSubmit = async (data) => {
    try {
      if (updateCompanyAccountLoading) {
        return;
      }

      const {
        data: { updateCompanyAccount },
      } = await updateCompanyAccountMutation({
        variables: {
          userId: data.userId,
          password: data.password,
          name: data.name,
          phone: data.phone,
          companyName: data.companyName,
          companyNumber: data.companyNumber,
          companyTel: data.companyTel,
          code: data.code,
          isNotification: data.isNotification,
        },
      });

      logUserUpdate(updateCompanyAccount.result);
      if (!updateCompanyAccount.ok) {
        MySwal.fire({
          showCloseButton: true,
          icon: "error",
          title: (
            <p className="modalTitle">
              {t(`account.edit.alerts.updateAccount.error.title`)}
            </p>
          ),
          html: (
            <p className="modalSubText">
              {t(`account.edit.alerts.updateAccount.error.message`)}
            </p>
          ),
          confirmButtonText: t(
            `account.edit.alerts.updateAccount.error.buttons.confirm`
          ),
          confirmButtonColor: `#44a16a`,
        });
      }

      MySwal.fire({
        showCloseButton: true,
        imageUrl: Img,
        imageWidth: 80,
        imageHeight: 80,
        title: (
          <p className="modalTitle">
            {t(`account.edit.alerts.updateAccount.confirm.title`)}
          </p>
        ),
        html: (
          <p className="modalSubText">
            {t(`account.edit.alerts.updateAccount.confirm.message`)}
          </p>
        ),
        confirmButtonText: t(
          `account.edit.alerts.updateAccount.confirm.buttons.confirm`
        ),
        confirmButtonColor: `#44a16a`,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isDismissed || result.isConfirmed) {
          navigate("/main");
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  const onPhoneVerifySend = async () => {
    try {
      if (watch("phone") === undefined || watch("phone")?.length < 10) {
        setError("phone", {
          type: "error",
          message: t(`account.edit.phone.rules.required`),
        });
        return;
      }

      if (updatePhoneVerifySendLoading) {
        return;
      }

      const {
        data: { updatePhoneVerifySend },
      } = await updatePhoneVerifySendMutation({
        variables: {
          user_phone: watch("phone"),
        },
      });

      if (!updatePhoneVerifySend.ok) {
        setError("phone", {
          type: "error",
          message: t(`account.edit.phone.errors.exception`),
        });
      }
      startCountdown();
    } catch (e) {
      console.log({ e });
    }
  };

  const onWithdraw = async () => {
    MySwal.fire({
      showCloseButton: true,
      icon: "warning",
      title: (
        <p className="modalTitle">
          {t(`account.edit.alerts.withdrawal.info.title`)}
        </p>
      ),
      html: (
        <p className="modalSubText">
          {t(`account.edit.alerts.withdrawal.info.message`)}
        </p>
      ),
      confirmButtonText: t(
        `account.edit.alerts.withdrawal.info.buttons.confirm`
      ),
      confirmButtonColor: `rgb(206, 206, 206)`,

      showCancelButton: true,
      cancelButtonText: t(`account.edit.alerts.withdrawal.info.buttons.cancel`),
      cancelButtonColor: `#44a16a`,

      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (withdrawalLoading) {
          return;
        }

        const {
          data: { withdrawal },
        } = await withdrawalMutation();

        if (!withdrawal.ok) {
          MySwal.fire({
            showCloseButton: true,
            icon: "error",
            title: (
              <p className="modalTitle">
                {t(`account.edit.alerts.withdrawal.error.title`)}
              </p>
            ),
            html: (
              <p className="modalSubText">
                {t(`account.edit.alerts.withdrawal.error.message`)}
              </p>
            ),
            confirmButtonText: t(
              `account.edit.alerts.withdrawal.error.buttons.confirm`
            ),
            confirmButtonColor: `#44a16a`,
          });
          return;
        }

        MySwal.fire({
          showCloseButton: true,
          icon: "success",
          title: (
            <p className="modalTitle">
              {t(`account.edit.alerts.withdrawal.confirm.title`)}
            </p>
          ),
          html: (
            <p className="modalSubText">
              {t(`account.edit.alerts.withdrawal.confirm.message`)}
            </p>
          ),
          confirmButtonText: t(
            `account.edit.alerts.withdrawal.confirm.buttons.confirm`
          ),
          confirmButtonColor: `#44a16a`,

          allowOutsideClick: false,
        });
        logUserOut();
      }
    });
  };
  const [isCounting, setIsCounting] = useState(false);
  const [remainingTime, setRemainingTime] = useState(180);
  const startCountdown = () => {
    setIsCounting(true);
  };

  useEffect(() => {
    let intervalId;
    if (isCounting) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 0) {
            setIsCounting(false);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isCounting]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="pageTitle hidden lg:block">{t(`account.edit.title`)}</p>
      <div className="w-full py-3 pr-3 block">
        <p className="mb-0 inputLabel sm:mb-3">{t(`account.edit.id.title`)}</p>

        <div className="mt-3 flex justify-between  items-stretch sm:mt-5">
          <div className="inputGreyBox flex items-center justify-between">
            <div className="defaultInput">
              <span>{userId}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap inputWrapper">
        <Controller
          control={control}
          rules={{
            validate: (value) => {
              if (value !== "" && value.length < 8) {
                return t(`account.edit.id.rules.validate`);
              }

              return undefined;
            },
          }}
          name="password"
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="w-full  py-3 px-3">
              <p className="mb-0 inputLabel sm:mb-3">
                {t(`account.edit.password.title`)}
              </p>
              <div className="mt-3 flex-col flex justify-between  items-stretch sm:mt-5 sm:flex-row">
                <div className="inputGreyBox flex items-center justify-between">
                  <input
                    type="password"
                    className="defaultInput"
                    placeholder={t(`account.edit.password.input.placeholder`)}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    autocomplete="new-password"
                  />
                  {value !== undefined && value !== "" && (
                    <ClearButton onClick={() => setValue("password", "")} />
                  )}
                </div>
              </div>
              {errors.password && (
                <InputErrorText text={errors.password.message} />
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          rules={{
            validate: (value) => {
              if (value !== watch("password")) {
                return t(`account.edit.passwordConfirm.rules.validate`);
              }

              return undefined;
            },
          }}
          name="passwordConfirm"
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="w-full  py-3 px-3">
              <p className="mb-0 inputLabel sm:mb-3">
                {t(`account.edit.passwordConfirm.title`)}
              </p>
              <div className="mt-3 flex-col flex justify-between  items-stretch sm:mt-5 sm:flex-row">
                <div className="inputGreyBox flex items-center justify-between">
                  <input
                    type="password"
                    className="defaultInput"
                    placeholder={t(
                      `account.edit.passwordConfirm.input.placeholder`
                    )}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    autocomplete="new-password"
                  />
                  {value !== undefined && value !== "" && (
                    <ClearButton
                      onClick={() => setValue("passwordConfirm", "")}
                    />
                  )}
                </div>
              </div>
              {errors.passwordConfirm && (
                <InputErrorText text={errors.passwordConfirm.message} />
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          rules={{
            required: t(`account.edit.companyName.rules.required`),
          }}
          name="companyName"
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="w-full xl:w-1/2 py-3 px-3">
              <p className="mb-0 inputLabel sm:mb-3">
                {t(`account.edit.companyName.title`)}
              </p>

              <div className="mt-3 flex-col flex justify-between  items-stretch sm:mt-5 sm:flex-row">
                <div className="inputGreyBox flex items-center justify-between">
                  <input
                    type="text"
                    className="defaultInput"
                    placeholder={t(
                      `account.edit.companyName.input.placeholder`
                    )}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                  {value !== undefined && value !== "" && (
                    <ClearButton onClick={() => setValue("companyName", "")} />
                  )}
                </div>
              </div>
              {errors.companyName && (
                <InputErrorText text={errors.companyName.message} />
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          rules={{
            required: t(`account.edit.companyNumber.rules.required`),
          }}
          name="companyNumber"
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="w-full xl:w-1/2 py-3 px-3">
              <p className="mb-0 inputLabel sm:mb-3">
                {t(`account.edit.companyNumber.title`)}
              </p>

              <div className="mt-3 flex-col flex justify-between  items-stretch sm:mt-5 sm:flex-row">
                <div className="inputGreyBox flex items-center justify-between">
                  <input
                    type="text"
                    className="defaultInput"
                    placeholder={t(
                      `account.edit.companyNumber.input.placeholder`
                    )}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                  {value !== undefined && value !== "" && (
                    <ClearButton
                      onClick={() => setValue("companyNumber", "")}
                    />
                  )}
                </div>
              </div>
              {errors.companyNumber && (
                <InputErrorText text={errors.companyNumber.message} />
              )}
            </div>
          )}
        />
        <Controller
          control={control}
          rules={{
            required: t(`account.edit.companyTel.rules.required`),
          }}
          name="companyTel"
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="w-full xl:w-1/2 py-3 px-3">
              <p className="mb-0 inputLabel sm:mb-3">
                {t(`account.edit.companyTel.title`)}
              </p>

              <div className="mt-3 flex-col flex justify-between  items-stretch sm:mt-5 sm:flex-row">
                <div className="inputGreyBox flex items-center justify-between">
                  <input
                    type="text"
                    className="defaultInput"
                    placeholder={t(`account.edit.companyTel.input.placeholder`)}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                  {value !== undefined && value !== "" && (
                    <ClearButton onClick={() => setValue("companyTel", "")} />
                  )}
                </div>
              </div>
              {errors.companyTel && (
                <InputErrorText text={errors.companyTel.message} />
              )}
            </div>
          )}
        />

        <Controller
          control={control}
          rules={{
            required: t(`account.edit.name.rules.required`),
          }}
          name="name"
          render={({ field: { onChange, onBlur, value } }) => {
            return (
              <div className="w-full  py-3 px-3">
                <p className="mb-0 inputLabel sm:mb-3">
                  {t(`account.edit.name.title`)}
                </p>
                <div className="mt-3 flex-col flex justify-between  items-stretch sm:mt-5 sm:flex-row">
                  <div className="inputGreyBox flex items-center justify-between">
                    <input
                      type="text"
                      className="defaultInput"
                      placeholder={t(`account.edit.name.input.placeholder`)}
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                    />
                    {value !== undefined && value !== "" && (
                      <ClearButton onClick={() => setValue("name", "")} />
                    )}
                  </div>
                </div>
                {errors.name && <InputErrorText text={errors.name.message} />}
              </div>
            );
          }}
        />

        <Controller
          control={control}
          rules={{
            required: t(`account.edit.phone.rules.required`),
          }}
          name="phone"
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="w-full  pt-3 px-3">
              <p className="mb-0 inputLabel sm:mb-3">
                {t(`account.edit.phone.title`)}
              </p>
              <div className="mt-3 flex-col flex justify-between  items-stretch sm:mt-5 md:flex-row">
                <div className="inputGreyBox flex items-center justify-between">
                  <input
                    type="text"
                    className="defaultInput"
                    placeholder={t(`account.edit.phone.input.placeholder`)}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                  {value !== undefined && value !== "" && (
                    <ClearButton onClick={() => setValue("phone", "")} />
                  )}
                </div>
                <InputSubButton
                  type="button"
                  text={t(`account.edit.phone.button.text`)}
                  onClick={() => {
                    onPhoneVerifySend();
                    startCountdown();
                  }}
                />
              </div>
              {errors.phone && <InputErrorText text={errors.phone.message} />}
            </div>
          )}
        />
        <Controller
          control={control}
          rules={{
            required: t(`account.edit.code.rules.required`),
          }}
          name="code"
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="w-full  px-3">
              <div className="mt-3 flex-col flex justify-between  items-stretch sm:mt-5 sm:flex-row">
                <div className="inputGreyBox flex items-center justify-between">
                  <input
                    type="text"
                    className="defaultInput"
                    placeholder={t(`account.edit.code.input.placeholder`)}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                  {value !== undefined && value !== "" && (
                    <ClearButton onClick={() => setValue("code", "")} />
                  )}
                  {isCounting ? (
                    <p className="timers">{`${Math.floor(
                      remainingTime / 60
                    )}:${String(remainingTime % 60).padStart(2, "0")}`}</p>
                  ) : null}
                </div>
              </div>
              {errors.code && <InputErrorText text={errors.code.message} />}
            </div>
          )}
        />
      </div>
      <div className="py-10">
        <Lines />
      </div>

      <Controller
        control={control}
        name="isNotification"
        render={({ field: { onChange, value } }) => (
          <div>
            <div className="w-full  pt-3 px-3">
              <p className="mb-0 inputLabel sm:mb-3">
                {t(`account.edit.notification.title`)}
              </p>
              <div className="flex justify-between items-center">
                <p className="notifiLabelText">
                  {t(`account.edit.notification.label`)}
                </p>
                <div>
                  <Switch
                    className="react-switch"
                    onChange={onChange}
                    checked={value}
                    aria-labelledby="neat-label"
                    onColor="#44a16a"
                    offColor="#414141"
                    height={42}
                    width={80}
                    uncheckedIcon={<UncheckedIcon />}
                    checkedIcon={<CheckedIcon />}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      />

      <div className="py-10">
        <Lines />
      </div>
      <div className="flex  justify-between mt-32 mb-24  flex-col-reverse sm:flex-row">
        <TextUnderLineButton
          text={t(`account.edit.buttons.withdrawal.text`)}
          type="button"
          onClick={onWithdraw}
        />

        <div className="w-full mb-5 sm:w-52 sm:mb-0">
          <DefaultButton
            text={t(`account.edit.buttons.confirm.text`)}
            type={null}
            onClick={null}
          />
        </div>
      </div>
    </form>
  );
};
