import React from "react";
import CompanyConsulting from "./CompanyConsulting";

export default ({ text }) => {
  return (
    <>
      <div>
        <p className="hidden lg:block pageTitle">{text}</p>
        <div>
          <CompanyConsulting />
        </div>
      </div>
    </>
  );
};
