import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { ko } from 'date-fns/esm/locale';

export default () => {
    const today = new Date();
    const ExampleCustomInput = ({ value, onClick }) => (
        <button
            className="example-custom-input datePicker w-full inputWrap"
            onClick={onClick}
            type="button"
        >
            {value}
        </button>
    );
    const handleDatePickerFocus = () => {
        // DatePicker 포커스 해제
        document.activeElement.blur();
    };

    const [selectedDate, setSelectedDate] = useState(new Date());

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <>
            <DatePicker
                dateFormat="yyyy.MM.dd"
                shouldCloseOnSelect // 날짜를 선택하면 datepicker가 자동으로 닫힘
                minDate={today} // minDate 이전 날짜 선택 불가
                selected={selectedDate}
                locale={ko}
                onChange={handleDateChange}
                onFocus={handleDatePickerFocus}
                customInput={<ExampleCustomInput />}
            />
        </>
    );
};
