import React from "react";
import Header from "../../layout/header/HeaderNav";
import Footer from "../../layout/footer/Footer";
import CounselPage from "../../layout/forest/Counsel";
import DrawerHead from "../../layout/header/DrawerHead";
import MobileFooter from "../../layout/footer/MobileFooter";
import { useReactiveVar } from "@apollo/client";
import { languageVar } from "../../apollo";
import { useTranslation } from "react-i18next";

import { useParams, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { MOUNTAIN_PROGRAM_QUERY } from "../../lib/queries/mountain";
import MobileHead from "../../layout/header/MobileHead";

export default () => {
  const { t } = useTranslation();
  const location = useLocation();
  const language = useReactiveVar(languageVar);
  const params = useParams();
  const id = params.id;
  const programId = params.programId;

  const { data, error, loading, refetch } = useQuery(MOUNTAIN_PROGRAM_QUERY, {
    fetchPolicy: "network-only",
    variables: { language, id: +id, program_id: +programId },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }
  if (!data.mountainProgram.ok) {
    return <>{data.mountainProgram.error}</>;
  }

  return (
    <div style={{ backgroundColor: "#FBFBFB" }}>
      <Header isLogin={true} isGrey />
      <MobileHead isBack title={t(`mountain.program.title`)} />
      <CounselPage data={data} />
      <Footer />
      <MobileFooter />
    </div>
  );
};
