import React from 'react';
import { TableHeadTitle } from '../../../components/forest/table/TableWidget';
import TableCompanyList from '../../../components/forest/table/TableCompanyList';
import NodataCounsel from '../../../components/data/NodataCounsel';
import Pagination from '../../../components/pagination/Pagination';
import Loading from '../../../components/page/Loading';
import { useReactiveVar } from '@apollo/client';
import { languageVar } from '../../../apollo';
import { useTranslation } from 'react-i18next';

import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { COMPANY_RESERVATION_LIST_QUERY } from '../../../lib/queries/reservation';

export default () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();

    const language = useReactiveVar(languageVar);
    const page = searchParams.get('page') ? +searchParams.get('page') : 1;
    const baseUrl = `/account/company/reservations?`;

    const take = 12;
    const skip = (page - 1) * take;
    const { data, error, loading } = useQuery(COMPANY_RESERVATION_LIST_QUERY, {
        fetchPolicy: 'network-only',
        variables: { language, take, skip },
    });

    if (loading) {
        return <Loading text={t(`account.company.reservation.loading.message`)} />;
    }
    if (error) {
        return <>{error.message}</>;
    }

    return (
        <>
            {data.companyReservationList.totalResults > 0 ? (
                <div className="my-3 lg:my-20">
                    <div className="hidden xl:flex  justify-between text-center items-center py-5 tableHead">
                        <div className="text-center w-1/12">
                            <TableHeadTitle title={t(`account.company.reservation.headers.no`)} />
                        </div>
                        <div className="text-center w-5/12">
                            <TableHeadTitle
                                title={t(`account.company.reservation.headers.program`)}
                            />
                        </div>
                        <div className="text-center w-2/12">
                            <TableHeadTitle title={t(`account.company.reservation.headers.user`)} />
                        </div>
                        <div className="text-center w-2/12">
                            <TableHeadTitle
                                title={t(`account.company.reservation.headers.persons`)}
                            />
                        </div>
                        <div className="text-center w-2/12">
                            <TableHeadTitle
                                title={t(`account.company.reservation.headers.createdAt`)}
                            />
                        </div>
                    </div>
                    <div>
                        {data.companyReservationList.results.map((item, index) => {
                            const fileUrl =
                                item.program.commonFiles.length > 0
                                    ? item.program.commonFiles[item.program.commonFiles.length - 1]
                                          ?.fileUrl
                                    : '/static/media/defaultImg.b9c73ad879bafcc7a378b338bdb3c963.svg';

                            return (
                                <TableCompanyList
                                    key={index}
                                    number={item.id}
                                    img={fileUrl}
                                    title={item.program.title}
                                    date={item.date}
                                    name={item.user.name}
                                    phone={item.user.phone}
                                    persons={item.persons}
                                    requestDate={item.createdAt}
                                />
                            );
                        })}
                    </div>

                    <div className="w-full flex my-10 justify-center">
                        <Pagination
                            totalRecord={data.companyReservationList.totalResults}
                            blockSize={5}
                            pageSize={take}
                            currentPage={page}
                            baseUrl={baseUrl}
                        />
                    </div>
                </div>
            ) : (
                <div className="py-14">
                    <NodataCounsel />
                </div>
            )}
        </>
    );
};
