import React from "react";
import { Link } from "react-router-dom";
import DefaultImage from "../../assets/images/user/defaultImg.svg";
import { ScrollToTop } from "../linkButton/Default";
import { GridBarrierLabel, GridProgramLabel } from "../label/ListLabel";
import { useTranslation } from "react-i18next";

export default ({ to, isBarrierfree, isProgram, name, address, img }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full sm:w-1/2 lg:w-4/12 borderBox">
      <Link to={to} className="containerStyle flex" onClick={ScrollToTop}>
        {img != null ? (
          <div
            className="containerImg"
            style={{
              backgroundImage: `url(${img})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div
              className="gradient"
              style={{
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(360deg, #0F0F0F 0%, rgba(0, 0, 0, 0) 56%)",
              }}
            />
            <div className="containerInfo">
              <div className="flex">
                {isBarrierfree && (
                  <>
                    <GridBarrierLabel
                      text={t(`main.mountain.items.labels.barrierfree`)}
                    />
                    <div className="pr-2" />
                  </>
                )}
                {isProgram && (
                  <GridProgramLabel
                    text={t(`main.mountain.items.labels.program`)}
                  />
                )}
              </div>
              <p className="forestName">{name}</p>
              <p className="forestAddress">{address}</p>
            </div>
          </div>
        ) : (
          <div
            className="containerImg"
            style={{
              backgroundImage: `url(${DefaultImage})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundSize: "cover",
            }}
          >
            <div
              className="gradient"
              style={{
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(360deg, #9b9b9b 0%, rgba(0, 0, 0, 0) 46%)",
              }}
            />
            <div className="containerInfo">
              <div className="flex">
                {isBarrierfree && (
                  <>
                    <GridBarrierLabel
                      text={t(`main.mountain.items.labels.barrierfree`)}
                    />
                    <div className="pr-2" />
                  </>
                )}
                {isProgram && (
                  <GridProgramLabel
                    text={t(`main.mountain.items.labels.program`)}
                  />
                )}
              </div>
              <p className="forestName">{name}</p>
              <p className="forestAddress">{address}</p>
            </div>
          </div>
        )}
      </Link>
    </div>
  );
};
