import React, { useState } from "react";
import CounselContents from "./CounselContents";
import CounselFormScroll from "./CounselFormScroll";
import CounselSuccess from "../../components/modals/login/CounselSuccess";

export default ({ data }) => {
  return (
    <>
      <div className="py-10 lg:py-16">
        <div className="width-1600">
          <div className="flex-col flex lg:flex-row">
            <CounselContents data={data} />
            <CounselFormScroll data={data} />
          </div>
        </div>
      </div>
    </>
  );
};
