import React, { useState } from 'react';
import styled from 'styled-components';

import { HeadCloseButton } from '../../components/linkButton/HeaderButton';

const HeadName = styled.div`
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 768px) {
        font-size: 16px;
        height: 60px;
    }
`;
export default ({ title, to }) => {
    return (
        <>
            <div className="headSticky sticky-header justify-center items-center flex lg:hidden">
                <HeadName>{title}</HeadName>
                <HeadCloseButton to={to} />
            </div>
        </>
    );
};
