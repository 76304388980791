import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faCloud,
  faCloudDrizzle,
  faCloudRain,
  faCloudShowersHeavy,
  faSmog,
  faSnowflake,
  faSun,
} from "@fortawesome/pro-solid-svg-icons";
import Loading from "../page/Loading";

const Label = styled.p`
  color: #bfbfbf;
  margin-bottom: 10px;
`;

const NowTems = styled.p`
  font-size: 32px;
  font-weight: bold;
`;
const TodayIcon = styled(FontAwesomeIcon)`
  font-size: 42px;
  width: 50px;
`;

const MDay = styled.p`
  font-size: 17px;
  color: #636363;
`;
const MDayMin = styled.p`
  font-size: 17px;
  color: #9a9a9a;
  margin-left: 10px;
`;
const Max = styled.p`
  font-size: 16px;
  color: #737373;
  width: 30px;
  text-align: center;
`;
const Min = styled.p`
  font-size: 16px;
  color: #c4c4c4;
  width: 30px;
  text-align: center;
`;
const ListIcon = styled(FontAwesomeIcon)`
  width: 30px;
  font-size: 20px;
`;
export default ({ longitude, latitude }) => {
  const [weather, setWeather] = useState(null);
  const API_KEY = "2104544839a09dd25a899660b5baf344"; // OpenWeatherMap API 키

  const dayOfWeek = new Date().toLocaleDateString("ko-KR", { weekday: "long" });
  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const weatherResponse = await axios.get(
          `https://api.openweathermap.org/data/2.5/onecall?lat=${latitude}&lon=${longitude}&appid=${API_KEY}&units=metric`
        );

        setWeather(weatherResponse.data);
      } catch (error) {
        console.error("Error fetching weather data:", error);
      }
    };

    fetchWeather();
  }, []);

  if (!weather) {
    return <Loading text={"로딩중..."} />;
  }

  const TEMP = weather;

  const NowTemp = Math.round(TEMP.daily[0].temp.day) + "°"; //현재날씨
  const MaxTemp = Math.round(TEMP.daily[0].temp.max) + "°"; //오늘 최고 기온
  const MinTemp = Math.round(TEMP.daily[0].temp.min) + "°"; // 오늘 최저 기온
  const weatherState = TEMP.daily[0].weather[0].main; // 날씨 상태 아이콘 확인
  const forecastData = TEMP.daily.slice(1, 8); // 내일~ 일주일간 날씨 데이터

  const weatherIconMap = {
    Clear: faSun,
    Clouds: faCloud,
    Rain: faCloudRain,
    Snow: faSnowflake,
    Thunderstorm: faBolt,
    Drizzle: faCloudDrizzle,
    Mist: faSmog,
  };
  const weatherIcon = weatherIconMap[weatherState]; // 아이콘
  const now = new Date();
  var month = now.getMonth() + 1;
  var date = now.getDate();
  const hour = now.getHours();
  let iconColor;

  if (hour >= 18 || (hour >= 0 && hour < 7)) {
    iconColor = "#2e2e2e";
  } else if (hour >= 7 && hour < 15) {
    iconColor = "#7bcef1";
  } else {
    iconColor = "#ff9751";
  }

  return (
    <>
      <div className="mt-8">
        <Label>날씨</Label>
        <div className="py-5 border-b border-gray-200 ">
          <div className="flex mb-2">
            <MDay>
              {month}월 {date}일 {dayOfWeek}
            </MDay>
          </div>
          <div className="flex justify-between items-center">
            <div className="flex">
              <TodayIcon icon={weatherIcon} color={iconColor} />
              {/* 현재기온  */}
              <NowTems className="pl-2">{NowTemp}</NowTems>
            </div>

            <div className="flex items-center">
              <Max>{MaxTemp}</Max>
              <span className="mx-2  text-gray-400">/</span>
              <Min>{MinTemp}</Min>
            </div>
          </div>
        </div>
        <div className="pt-7">
          <MDay>일주일 날씨</MDay>
          <ul className="pt-3">
            {forecastData.map((dayForecast, index) => (
              <li key={index} className="flex justify-between py-3">
                <div className="flex">
                  <ListIcon
                    icon={weatherIconMap[dayForecast.weather[0].main]}
                    color={iconColor}
                  />
                  <div className="flex items-center pl-3">
                    <MDay>
                      {new Date(dayForecast.dt * 1000).toLocaleDateString(
                        "ko-KR",
                        { weekday: "long" }
                      )}
                    </MDay>
                    <MDayMin className="pl-1">
                      {new Date(dayForecast.dt * 1000)
                        .toLocaleDateString("ko-KR", {
                          month: "numeric",
                          day: "numeric",
                        })
                        .replace("/", ".")}
                    </MDayMin>
                  </div>
                </div>
                <div className="flex items-center">
                  <Max>{Math.round(dayForecast.temp.max) + "°"}</Max>
                  <span className="mx-2  text-gray-400">/</span>
                  <Min>{Math.round(dayForecast.temp.min) + "°"}</Min>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};
