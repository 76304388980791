import { gql } from "@apollo/client";

/** 사용자 로그인 **/
export const LOGIN_MUTATION = gql`
  mutation login($userId: String!, $password: String!, $deviceToken: String) {
    login(
      input: { userId: $userId, password: $password, deviceToken: $deviceToken }
    ) {
      ok
      token
      result {
        id

        role
        userId
        name
        phone
        isWithdrawal
        isNotification
        company {
          id
          createdAt
          updatedAt

          companyName
          companyTel
          companyNumber
        }
      }
      error
    }
  }
`;

/** 아이디 찾기 시작 **/
export const ID_FINDER_PHONE_VERIFY_SEND_MUTATION = gql`
  mutation idFinderPhoneVerifySend($user_phone: String!) {
    idFinderPhoneVerifySend(input: { user_phone: $user_phone }) {
      ok
      error
    }
  }
`;

export const ID_FINDER_PHONE_VERIFY_MUTATION = gql`
  mutation idFinderPhoneVerify($user_phone: String!, $code: String!) {
    idFinderPhoneVerify(input: { user_phone: $user_phone, code: $code }) {
      ok
      userId
      error
    }
  }
`;
/** 아이디 찾기 끝 **/

/** 비밀번호 찾기 시작 **/
export const PW_FINDER_PHONE_VERIFY_SEND_MUTATION = gql`
  mutation pwFinderPhoneVerifySend(
    $user_userId: String!
    $user_phone: String!
  ) {
    pwFinderPhoneVerifySend(
      input: { user_userId: $user_userId, user_phone: $user_phone }
    ) {
      ok
      error
    }
  }
`;

export const PW_FINDER_PHONE_VERIFY_MUTATION = gql`
  mutation pwFinderPhoneVerify(
    $user_userId: String!
    $user_phone: String!
    $code: String!
  ) {
    pwFinderPhoneVerify(
      input: { user_userId: $user_userId, user_phone: $user_phone, code: $code }
    ) {
      ok
      error
    }
  }
`;

export const PW_FINDER_PASSWORD_CHANGE_MUTATION = gql`
  mutation pwFinderPasswordChange(
    $user_userId: String!
    $user_phone: String!
    $code: String!
    $password: String!
  ) {
    pwFinderPasswordChange(
      input: {
        user_userId: $user_userId
        user_phone: $user_phone
        code: $code
        password: $password
      }
    ) {
      ok
      error
    }
  }
`;
/** 비밀번호 찾기 끝 **/

/** 회원가입 시작 **/
export const CREATE_USERID_CHECKER_MUTATION = gql`
  mutation createUserIdChecker($userId: String!) {
    createUserIdChecker(input: { userId: $userId }) {
      ok
      error
    }
  }
`;

export const CREATE_PHONE_VERIFY_SEND_MUTATION = gql`
  mutation createPhoneVerifySend($user_userId: String!, $user_phone: String!) {
    createPhoneVerifySend(
      input: { user_userId: $user_userId, user_phone: $user_phone }
    ) {
      ok
      error
    }
  }
`;

export const CREATE_USER_ACCOUNT_MUTATION = gql`
  mutation createUserAccount(
    $userId: String!
    $password: String!
    $name: String!
    $phone: String!
    $code: String!
  ) {
    createUserAccount(
      input: {
        userId: $userId
        password: $password
        name: $name
        phone: $phone
        code: $code
      }
    ) {
      ok
      error
    }
  }
`;

export const CREATE_COMPANY_ACCOUNT_MUTATION = gql`
  mutation createCompanyAccount(
    $userId: String!
    $password: String!
    $name: String!
    $phone: String!
    $companyName: String!
    $companyTel: String!
    $companyNumber: String!
    $code: String!
  ) {
    createCompanyAccount(
      input: {
        userId: $userId
        password: $password
        name: $name
        phone: $phone
        companyName: $companyName
        companyTel: $companyTel
        companyNumber: $companyNumber
        code: $code
      }
    ) {
      ok
      error
    }
  }
`;

/** 회원가입 끝 **/

/** 사용자 정보(me) **/
export const ME_QUERY = gql`
  query me {
    me {
      id
      createdAt
      updatedAt

      role
      userId
      name
      phone
      isWithdrawal
      isNotification
      company {
        id
        createdAt
        updatedAt

        companyName
        companyTel
        companyNumber
      }
      notificationDetailCount
    }
  }
`;

/** 정보수정 시작 **/
export const UPDATE_PHONE_VERIFY_SEND_MUTATION = gql`
  mutation updatePhoneVerifySend($user_phone: String!) {
    updatePhoneVerifySend(input: { user_phone: $user_phone }) {
      ok
      error
    }
  }
`;

export const UPDATE_USER_ACCOUNT_MUTATION = gql`
  mutation updateUserAccount(
    $password: String!
    $name: String!
    $phone: String!
    $code: String!
    $isNotification: Boolean!
  ) {
    updateUserAccount(
      input: {
        password: $password
        name: $name
        phone: $phone
        code: $code
        isNotification: $isNotification
      }
    ) {
      ok
      result {
        id
        createdAt
        updatedAt

        role
        userId
        name
        phone
        isWithdrawal
        isNotification
      }
      error
    }
  }
`;

export const UPDATE_COMPANY_ACCOUNT_MUTATION = gql`
  mutation updateCompanyAccount(
    $password: String!
    $name: String!
    $phone: String!
    $companyName: String
    $companyTel: String
    $companyNumber: String
    $code: String!
    $isNotification: Boolean!
  ) {
    updateCompanyAccount(
      input: {
        password: $password
        name: $name
        phone: $phone
        companyName: $companyName
        companyTel: $companyTel
        companyNumber: $companyNumber
        code: $code
      }
    ) {
      ok
      result {
        id
        createdAt
        updatedAt

        role
        userId
        name
        phone
        isWithdrawal
        isNotification
        company {
          id
          createdAt
          updatedAt

          companyName
          companyTel
          companyNumber
        }
      }
      error
    }
  }
`;

/** 정보수정 끝 **/

/** 탈퇴 **/
export const WITHDRAWAL_MUTATION = gql`
  mutation withdrawal {
    withdrawal {
      ok
      error
    }
  }
`;
