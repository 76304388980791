import { Fragment, useState } from 'react';
import PageHead from '../../components/forest/content/PageHead';
import MainSearch from '../../components/forest/MainSearch';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import Img from '../../assets/images/user/modalIcon.svg';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FilterIconButton, IconButton } from '../../components/linkButton/IconButton';
import { faFilterList } from '@fortawesome/pro-solid-svg-icons';
import FilterModal from '../../components/modals/login/FilterModal';
const MySwal = withReactContent(Swal);

export default ({ keyword, region, filter, sort, data }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isShow, setIsShow] = useState(false);
    const toggleModal = () => {
        setIsShow(!isShow);
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        setError,
        reset,
    } = useForm({
        defaultValues: {
            keyword,
        },
    });

    const onSubmit = async (data) => {
        if (data.keyword === null) {
            MySwal.fire({
                showCloseButton: true,
                imageUrl: Img,
                imageWidth: 80,
                imageHeight: 80,
                title: <p className="modalTitle">{t(`mountain.list.search.alert.title`)}</p>,
                html: <p className="modalSubText">{t(`mountain.list.search.alert.message`)}</p>,
                confirmButtonText: t(`mountain.list.search.alert.buttons.confirm`),
                confirmButtonColor: `#44a16a`,
            });
            return;
        }

        try {
            const url =
                '/mountains?' +
                (data.keyword ? `keyword=${data.keyword}&` : ``) +
                (region ? `region=${region}&` : ``) +
                (filter ? `filter=${filter}&` : ``) +
                (sort ? `sort=${sort}&` : ``) +
                `page=1`;
            navigate(url);
        } catch (e) {
            console.log(e);
        }
    };

    const onClear = () => {
        const url =
            '/mountains?' +
            (region ? `region=${region}&` : ``) +
            (filter ? `filter=${filter}&` : ``) +
            (sort ? `sort=${sort}&` : ``) +
            `page=1`;
        navigate(url);
        setValue('keyword', '');
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex w-full justify-start items-baseline flex-col xl:flex-row lx:place-content-between xl:items-center">
                    <PageHead
                        top={t(`mountain.list.title.top`)}
                        main={t(`mountain.list.title.main`)}
                    />
                    <div className="flex items-center justify-between md:justify-end w-full flex-1">
                        <MainSearch
                            control={control}
                            name="keyword"
                            onClear={onClear}
                            keyword={keyword}
                            data={data}
                            title={t(`mountain.list.search.input.title`)}
                            placeholder={t(`mountain.list.search.input.placeholder`)}
                        />
                        {/* <div className="flex lg:hidden">
                            <IconButton icon={faFilterList} onClick={toggleModal} />
                        </div> */}
                        <div className="md:hidden">
                            <FilterIconButton onClick={toggleModal} />
                        </div>
                    </div>
                </div>
                <div className="hidden md:block py-10">
                    <p className="myeongjo text-2xl" style={{ fontWeight: 700 }}>
                        {t(`mountain.list.title.sub`)}
                    </p>
                </div>
                <FilterModal isOpen={isShow} closeModal={toggleModal} />
            </form>
        </Fragment>
    );
};
