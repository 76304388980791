import React from 'react';
import styled from 'styled-components';
import tree from '../../../assets/images/tree.svg';
const TitleTop = styled.p`
    color: #484848;
    font-size: 24px;
    font-weight: 500;
    line-height: 42px;
    margin-bottom: 40px;
    @media (max-width: 768px) {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 15px;
    }
`;
const Title = styled.p`
    font-weight: 900;
    color: #484848;
    font-size: 82px;
    line-height: 94px;
    letter-spacing: -0.6px;
    color: #222;
    @media (max-width: 1280px) {
        font-size: 62px;
    }
    @media (max-width: 768px) {
        font-size: 28px;
        line-height: 32px;
    }
`;
const TreeImg = styled.div`
    width: 65px;
    line-height: 78px;
    margin-bottom: 18px;
    margin-left: 20px;
    @media (max-width: 768px) {
        width: 35px;
        margin-bottom: 4px;
        margin-left: 10px;
    }
`;
export default ({ top, main }) => {
    return (
        <div className="mr-5">
            <TitleTop className="myeongjo" dangerouslySetInnerHTML={{ __html: top }}></TitleTop>
            <div className="flex items-end">
                <Title>{main}</Title>
                <TreeImg>
                    <img src={tree} />
                </TreeImg>
            </div>
        </div>
    );
};
