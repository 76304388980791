import React from 'react';
import styled from 'styled-components';
import Img from '../../assets/images/user/modalIcon.svg';

const Icon = styled.div`
    width: 80px;
    margin-bottom: 35px;
    @media (max-width: 768px) {
        width: 50px;
    }
`;
export default () => {
    return (
        <>
            <Icon>
                <img src={Img} alt="아이콘" />
            </Icon>
        </>
    );
};
