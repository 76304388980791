import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCamera,
    faDropletPercent,
    faLeaf,
    faMountains,
    faTemperatureQuarter,
    faTree,
    faWheelchair,
} from '@fortawesome/pro-solid-svg-icons';
import RoadList from '../../components/forest/RoadList';
import { NumericFormat } from 'react-number-format';
import CoverImage from '../../components/forest/CoverImage';
import Lines from '../../components/forest/Lines';
import { BarrierLabel, ProgramLabel } from '../../components/label/ListLabel';
import { useTranslation } from 'react-i18next';
import { Container as MapDiv, NaverMap, Marker, useNavermaps } from 'react-naver-maps';

const ForestName = styled.p`
    font-size: 42px;
    color: #222;
    font-weight: 800;
    line-height: 1.3;
    @media (max-width: 768px) {
        font-size: 28px;
    }
    @media (max-width: 640px) {
        font-size: 22px;
    }
`;
const ForestAddress = styled.p`
    font-size: 18px;
    color: #1f1f1f;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;
const Info = styled.div`
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-color: #e2e2e2;
`;

const InfoIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    color: #ccc;
`;
const InfoText = styled.span`
    font-size: 18px;
    color: #a4a4a4;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;
const InfoValue = styled.p`
    color: #333;
    font-size: 18px;
    @media (max-width: 768px) {
        font-size: 16px;
    }
`;
const IconWrap = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    @media (max-width: 768px) {
        margin-right: 10px;
        width: 30px;
    }
`;

const TEXT = styled.p`
    color: #969696;
    line-height: 2;
    font-size: 18px;
    @media (max-width: 768px) {
        font-size: 15px;
    }
`;

const GridItem = styled.div`
    background-color: #f0f0f0;
    width: 25%;
    padding-top: 25%;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    margin: 0 5px;
    @media (max-width: 768px) {
        padding-top: 20%;
    }
`;
const Items = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;
const Abs = styled.button`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(63, 63, 63, 0.8);
    color: #fff;
    font-size: 16px;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const Wrap = styled.div`
    margin: 0 -5px;
`;

export default ({ data, onClick }) => {
    const { t } = useTranslation();
    const navermaps = useNavermaps();

    const {
        mountainRead: {
            result: {
                createdAt,
                updatedAt,

                commonState,
                no,
                name,
                reason,
                locationInfo,
                locationSido,
                height,
                description,
                roadAddress,
                jibunAddress,
                longitude,
                latitude,

                commonFiles,
                regionHasMountains,
                phytoncides,
                barrierfrees,
                programs,
                user,
                phytoncide,
                isBarrierfree,
                isProgram,
            },
        },
    } = data;

    var imageLength = [0, 1, 2, 3, 4];

    const [map, setMap] = useState(null);

    const moveCenter = (item) => {
        if (map) {
            map.setCenter(new navermaps.LatLng(item.latitude, item.longitude));
        }
    };

    return (
        <>
            <div className="pr-0 lg:pr-12 flex-1">
                <div className="mt-5 mb-5 md:mt-20 md:mb-16">
                    <div className="flex">
                        {isBarrierfree && (
                            <BarrierLabel text={t(`mountain.detail.labels.barrierfree`)} />
                        )}
                        {isProgram && <ProgramLabel text={t(`mountain.detail.labels.program`)} />}
                    </div>
                    <div className="flex items-start justify-between">
                        <div>
                            <ForestName>{name}</ForestName>
                            <ForestAddress>
                                {roadAddress ? roadAddress : jibunAddress}
                            </ForestAddress>
                        </div>
                    </div>
                </div>

                <Lines />

                <div className="py-14 ">
                    <p className="DetailLabelTitle">{t(`mountain.detail.infomation.title`)}</p>
                    <ul className="flex flex-wrap">
                        <li className="w-full py-10 flex items-center  justify-between infoList md:w-1/2 md:py-5 ">
                            <div className="flex">
                                <IconWrap>
                                    <InfoIcon icon={faMountains} />
                                </IconWrap>
                                <InfoText>
                                    {t(`mountain.detail.infomation.items.height.title`)}
                                </InfoText>
                            </div>
                            <InfoValue>
                                <NumericFormat
                                    value={height}
                                    displayType="text"
                                    thousandSeparator={true}
                                    suffix="m"
                                />
                            </InfoValue>
                        </li>
                        <li className="w-full py-10 flex items-center  justify-between infoList md:w-1/2 md:py-5 ">
                            <div className="flex">
                                <IconWrap>
                                    <InfoIcon icon={faWheelchair} />
                                </IconWrap>
                                <InfoText>
                                    {t(`mountain.detail.infomation.items.isBarrierfree.title`)}
                                </InfoText>
                            </div>
                            <InfoValue>
                                {isBarrierfree
                                    ? t(`mountain.detail.infomation.items.isBarrierfree.items.true`)
                                    : t(
                                          `mountain.detail.infomation.items.isBarrierfree.items.false`,
                                      )}
                            </InfoValue>
                        </li>

                        {phytoncide && (
                            <>
                                <li className="w-full py-10 flex items-center  justify-between infoList md:w-1/2 md:py-5 ">
                                    <div className="flex">
                                        <IconWrap>
                                            <InfoIcon icon={faTree} />
                                        </IconWrap>
                                        <InfoText>
                                            {t(`mountain.detail.infomation.items.phytoncide.title`)}
                                        </InfoText>
                                    </div>
                                    <InfoValue>
                                        <NumericFormat
                                            value={(+phytoncide.totalNvocs).toFixed(2)}
                                            displayType="text"
                                            thousandSeparator={true}
                                            suffix="NVOCs"
                                        />
                                    </InfoValue>
                                </li>
                                <li className="w-full py-10 flex items-center  justify-between infoList md:w-1/2 md:py-5 ">
                                    <div className="flex">
                                        <IconWrap>
                                            <InfoIcon icon={faTemperatureQuarter} />
                                        </IconWrap>
                                        <InfoText>
                                            {t(
                                                `mountain.detail.infomation.items.temperature.title`,
                                            )}
                                        </InfoText>
                                    </div>
                                    <InfoValue>
                                        <NumericFormat
                                            value={(+phytoncide.averageTemperature).toFixed(2)}
                                            displayType="text"
                                            thousandSeparator={true}
                                            suffix="°C"
                                        />
                                    </InfoValue>
                                </li>
                                <li className="w-full py-10 flex items-center  justify-between infoList md:w-1/2 md:py-5 ">
                                    <div className="flex">
                                        <IconWrap>
                                            <InfoIcon icon={faDropletPercent} />
                                        </IconWrap>
                                        <InfoText>
                                            {t(`mountain.detail.infomation.items.humidity.title`)}
                                        </InfoText>
                                    </div>
                                    <InfoValue>
                                        <NumericFormat
                                            value={(+phytoncide.averageHumidity).toFixed(2)}
                                            displayType="text"
                                            thousandSeparator={true}
                                            suffix="%"
                                        />
                                    </InfoValue>
                                </li>
                                <li className="w-full py-10 flex items-center  justify-between infoList md:w-1/2 md:py-5 ">
                                    <div className="flex">
                                        <IconWrap>
                                            <InfoIcon icon={faLeaf} />
                                        </IconWrap>
                                        <InfoText>
                                            {t(`mountain.detail.infomation.items.anion.title`)}
                                        </InfoText>
                                    </div>
                                    <InfoValue>
                                        <NumericFormat
                                            value={(+phytoncide.anion).toFixed(2)}
                                            displayType="text"
                                            thousandSeparator={true}
                                            suffix="Aion"
                                        />
                                    </InfoValue>
                                </li>
                            </>
                        )}
                    </ul>
                </div>

                <Lines />

                <div className="py-14 block lg:hidden">
                    <p className="DetailLabelTitle">{t(`mountain.detail.photos.title`)}</p>
                    <Wrap className="flex">
                        {imageLength
                            .filter((item, index) => index < 4)
                            .map((item, index) => {
                                return (
                                    <GridItem key={index}>
                                        <Items>
                                            <CoverImage
                                                img={`url(${
                                                    commonFiles[item]
                                                        ? commonFiles[item].fileUrl
                                                        : '/static/media/defaultImg.b9c73ad879bafcc7a378b338bdb3c963.svg'
                                                })`}
                                            />

                                            {item === 3 && commonFiles.length > 0 && (
                                                <Abs onClick={onClick}>
                                                    <FontAwesomeIcon icon={faCamera} />
                                                    {commonFiles.length}장
                                                </Abs>
                                            )}
                                        </Items>
                                    </GridItem>
                                );
                            })}
                    </Wrap>
                    <div></div>
                </div>

                <Lines />

                <div className="py-14">
                    <p className="DetailLabelTitle">{t(`mountain.detail.map.title`)}</p>
                    <MapDiv
                        style={{
                            height: 300,
                        }}
                    >
                        <NaverMap
                            defaultCenter={new navermaps.LatLng(latitude, longitude)}
                            defaultZoom={15}
                            ref={setMap}
                        >
                            {/* 산 마커 */}
                            <Marker
                                defaultPosition={{ lat: latitude, lng: longitude }}
                                icon={{
                                    url:
                                        process.env.PUBLIC_URL +
                                        '/assets/images/user/forestMarker.svg',
                                    // size: new naver.maps.Size(60, 60),
                                    // origin: new naver.maps.Point(0, 0),
                                    // anchor: new naver.maps.Point(12, 37),
                                }}
                            />

                            {/* 무장애 길 마커 */}
                            {barrierfrees.map((item, index) => (
                                <Marker
                                    key={index}
                                    defaultPosition={{ lat: item.latitude, lng: item.longitude }}
                                    icon={{
                                        url:
                                            process.env.PUBLIC_URL +
                                            '/assets/images/user/varrierfreeMarker.svg',
                                        // size: new naver.maps.Size(60, 60),
                                        // origin: new naver.maps.Point(0, 0),
                                        // anchor: new naver.maps.Point(12, 37),
                                    }}
                                />
                            ))}
                        </NaverMap>
                    </MapDiv>
                </div>

                <Lines />

                {barrierfrees.length > 0 && (
                    <>
                        <div className="py-5 md:py-14">
                            <p className="DetailLabelTitle">
                                {t(`mountain.detail.barrierfree.title`)}
                            </p>
                            <ul>
                                {barrierfrees.map((item, index) => {
                                    return (
                                        <RoadList
                                            key={index}
                                            name={item.name}
                                            address={
                                                item.roadAddress
                                                    ? item.roadAddress
                                                    : item.jibunAddress
                                            }
                                            onClick={() => moveCenter(item)}
                                        />
                                    );
                                })}
                            </ul>
                        </div>
                        <Lines />
                    </>
                )}

                <div className="py-14">
                    <p className="DetailLabelTitle">{t(`mountain.detail.description.title`)}</p>
                    <TEXT className="pt-14">{description}</TEXT>
                </div>
            </div>
        </>
    );
};
