import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
    faChevronLeft,
    faChevronRight,
    faChevronsLeft,
    faChevronsRight,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Item = ({ currentPage, baseUrl, startPage, endPage, totalPage }) => {
    let pages = [];
    let page = parseInt(currentPage);

    for (var i = startPage; i <= endPage && i <= totalPage; i++) {
        if (i === page) {
            pages.push(
                <li className={'page-item ' + (i === page ? 'active' : '')} key={'pagination' + i}>
                    <div className="page-link" title="현재 페이지" to="#">
                        {currentPage}
                    </div>
                </li>,
            );
        } else {
            pages.push(
                <li className="page-item" key={'pagination' + i}>
                    <Link
                        className="page-link"
                        title={i + ' 페이지 이동'}
                        to={baseUrl + 'page=' + i}
                    >
                        {i}
                    </Link>
                </li>,
            );
        }
    }
    return pages;
};

export default ({ totalRecord, blockSize, pageSize, currentPage, baseUrl }) => {
    const currentBlock = Math.ceil(currentPage / blockSize);
    const totalPage = Math.ceil(totalRecord / pageSize);
    const totalBlock = Math.ceil(totalPage / blockSize);

    const startPage = (currentBlock - 1) * blockSize + 1;
    const endPage = currentBlock * blockSize;

    const prevBlockPage = startPage - 1 > 0 ? startPage - 1 : 1;
    const nextBlockPage = endPage + 1 < totalPage ? endPage + 1 : totalPage;

    if (totalRecord === 0) {
        return <></>;
    }

    const url = baseUrl[baseUrl.length - 1] !== '?' ? `${baseUrl}&` : `${baseUrl}`;

    return (
        <ul className="pagination mt-10 flex items-center w-auto  justify-around ">
            {/* <li className={`page-item ${currentBlock === 1 && `disabled`}`}>
        <Link
          className="page-link"
          title={"첫 1 페이지 보기"}
          to={url + "page=1"}
        >
          <FontAwesomeIcon icon={faChevronsLeft} />
        </Link>
      </li> */}
            <li className={`page-item ${currentBlock === 1 && `disabled`}`}>
                <Link
                    className="page-link"
                    title={'이전 ' + blockSize + '페이지 보기'}
                    to={url + 'page=' + prevBlockPage}
                >
                    <FontAwesomeIcon icon={faChevronLeft} />
                </Link>
            </li>
            <Item
                currentPage={currentPage}
                baseUrl={url}
                startPage={startPage}
                endPage={endPage}
                totalPage={totalPage}
            />
            <li className={`page-item  ${currentBlock === totalBlock && `disabled`}`}>
                <Link
                    className="page-link"
                    title={'다음 ' + nextBlockPage + '페이지 보기'}
                    to={url + 'page=' + nextBlockPage}
                >
                    <FontAwesomeIcon icon={faChevronRight} />
                </Link>
            </li>
            {/* <li className={`page-item  ${currentBlock === totalBlock && `disabled`}`}>
        <Link
          className="page-link"
          title={"마지막 " + totalPage + "페이지 보기"}
          to={url + "page=" + totalPage}
        >
          <FontAwesomeIcon icon={faChevronsRight} />
        </Link>
      </li> */}
        </ul>
    );
};
