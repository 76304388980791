import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import i18n from "./locales/i18n";

const TOKEN = "TOKEN";
const ME = "ME";

export const isLoggedInVar = makeVar(Boolean(localStorage.getItem(TOKEN)));

export const logUserIn = (token, me) => {
  localStorage.setItem(TOKEN, token);
  localStorage.setItem(ME, JSON.stringify(me));
  isLoggedInVar(true);
};
export const logUserOut = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(ME);
  isLoggedInVar(false);
};
export const logUserUpdate = (me) => {
  localStorage.setItem(ME, JSON.stringify(me));
};

const LANGUAGE = "LANGUAGE";

export const languageVar = makeVar(
  localStorage.getItem("LANGUAGE") === "vi" ? "vi" : "ko"
);

export const toggleLanguage = () => {
  const language = localStorage.getItem("LANGUAGE");
  const newLanguage = language ? (language === "ko" ? "vi" : "ko") : "vi";
  i18n.changeLanguage(newLanguage);
  localStorage.setItem(LANGUAGE, newLanguage);
  languageVar(newLanguage);
};

// export const uri = "3.36.247.35:3000";
// export const uri = "localhost:3000";
export const uri = "www.metafore.kr";

const httpLink = createHttpLink({
  uri: `https://${uri}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "x-jwt": localStorage.getItem(TOKEN),
    },
  };
});

export const cache = new InMemoryCache({
  // typePolicies: {
  //   Query: {
  //     fields: {
  //       localSearchKeyword: {
  //         keyArgs: ["page", "query"],
  //         merge(existing, incoming, { args }) {
  //           const page = args.input.page * 15;
  //           const merged =
  //             existing && incoming.totalResults > 0
  //               ? existing.results.slice(0)
  //               : [];
  //           for (let i = 0; i < incoming.results.length; ++i) {
  //             merged[page + i] = incoming.results[i];
  //           }
  //           return {
  //             ok: incoming.ok,
  //             results: merged,
  //             totalPages: incoming.totalPages,
  //             totalResults: incoming.totalResults,
  //             error: incoming.error,
  //           };
  //         },
  //       },
  //     },
  //   },
  // },
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});
