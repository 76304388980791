import { gql } from "@apollo/client";

/** 상담 신청 **/
export const RESERVATION_CREATE_MUTATION = gql`
  mutation reservationCreate(
    $program_id: Int!
    $date: String!
    $persons: String!
    $name: String!
    $phone: String!
  ) {
    reservationCreate(
      input: {
        program_id: $program_id
        date: $date
        persons: $persons
        name: $name
        phone: $phone
      }
    ) {
      ok
      error
    }
  }
`;

/** 사용자 상담 목록 **/
export const USER_RESERVATION_LIST_QUERY = gql`
  query userReservationList($language: String, $take: Int, $skip: Int!) {
    userReservationList(
      input: { language: $language, take: $take, skip: $skip }
    ) {
      ok
      results {
        id
        createdAt
        updatedAt

        commonState
        date
        persons
        name
        phone
        company {
          id

          companyName
          companyTel
          companyNumber
        }
        program {
          id
          createdAt
          updatedAt

          commonState
          title
          description
          tel

          commonFiles {
            id
            fileUrl
          }
        }
        user {
          id
          createdAt
          updatedAt

          userId
          name
          phone
        }
      }
      error
      totalResults
      totalPages
    }
  }
`;
/** 기업 상담 목록 **/
export const COMPANY_RESERVATION_LIST_QUERY = gql`
  query companyReservationList($language: String, $take: Int, $skip: Int!) {
    companyReservationList(
      input: { language: $language, take: $take, skip: $skip }
    ) {
      ok
      results {
        id
        createdAt
        updatedAt

        commonState
        date
        persons
        name
        phone
        company {
          id

          companyName
          companyTel
          companyNumber
        }
        program {
          id
          createdAt
          updatedAt

          commonState
          title
          description
          tel

          commonFiles {
            id
            fileUrl
          }
        }
        user {
          id
          createdAt
          updatedAt

          userId
          name
          phone
        }
      }
      error
      totalResults
      totalPages
    }
  }
`;
