import React, { useState } from 'react';
import styled from 'styled-components';
import BottomModal from '../bottomModal';

export default ({ isOpen, closeModal }) => {
    return (
        <>
            <BottomModal isOpen={isOpen} closeModal={closeModal}>
                <div>
                    <p className="termsTitle">개인정보취급방침</p>
                    <div className="termsWrap">
                        <p className="termsText">
                            1. 수집하는 개인정보의 항목 메타숲은 회원가입, 원활한 고객상담, 각종
                            서비스 등 기본적인 서비스 제공을 위한 필수정보와 고객 맞춤 서비스 제공을
                            위한 선택정보로 구분하여 아래와 같은 개인정보를 수집하고 있습니다.{' '}
                            <br />
                            <br />
                            1) 개인 회원 필수항목 : 아이디, 비밀번호, 이름, 닉네임, 이메일 <br />
                            2) 법인 회원 필수항목 : 아이디, 비밀번호, 법인명, 사업자등록번호,
                            대표자명, 업종, 이메일, 담당자 이름, 담당자 생년월일, 담당자 성별,
                            담당자 본인확인값(CI, DI) 선택사항 : 설립일, 담당자 휴대폰 번호 <br />
                            3) 서비스 이용 과정에서 아래와 같은 정보들이 자동으로 생성되어 수집될 수
                            있습니다. IP Address, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록,
                            기기정보 선택정보를 입력하지 않은 경우에도 서비스 이용 제한은 없으며
                            이용자의 기본적 인권 침해의 우려가 있는 민감한 개인 정보(인종, 사상 및
                            신조, 정치적 성향 이나 범죄기록, 의료정보 등)는 기본적으로 수집하지
                            않습니다. 다만 불가피하게 수집이 필요한 경우 반드시 사전에 동의 절차를
                            거치도록 하겠습니다. 개인정보의 수집 • 이용목적 메타숲은 이용자의 소중한
                            개인정보를 다음과 같은 목적으로만 이용하며, 목적이 변경될 경우에는
                            사전에 이용자의 동의를 구하도록 하겠습니다. 회원으로 가입한 이용자를
                            식별하고 가입의사 및 나이 확인, 불량회원의 부정한 이용을 방지하기 위하여
                            사용합니다. 다만 만14세 미만 아동의 개인정보는 법령에 따라 법정대리인의
                            동의 여부도 확인하기 위해도 사용됩니다. <br />
                            <br />
                            2. 이용자에게 메타숲의 다양한 서비스를 제공하고 서비스 이용 과정에서
                            이용자의 문의사항이나 불만을 처리하고 공지사항 등을 전달하기 위해
                            사용합니다. <br />
                            <br />
                            3. 이용자와 약속한 서비스를 제공하고 유료 서비스 구매 및 이용이
                            이루어지는 경우 이에 따른 요금 정산을 위해 사용됩니다. <br />
                            <br />
                            4. 신규 서비스가 개발되거나 이벤트 행사 시 참여기회를 알리기 위한 정보
                            전달 및 마케팅 및 광고 등에도 사용됩니다. 5. 이용자의 서비스 이용 기록과
                            접속 빈도 분석 및 서비스 이용에 대한 통계, 이를 통한 맞춤형 서비스
                            제공과 서비스 개선에도 사용됩니다. 개인정보의 보유 • 이용기간 메타숲은
                            이용자의 개인정보를 회원가입을 하는 시점부터 서비스를 제공하는 기간
                            동안에만 제한적으로 이용하고 있습니다. 이용자가 회원탈퇴를 요청하거나
                            제공한 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 또는 수집 및
                            이용목적이 달성되거나 보유 및 이용기간이 종료한 경우 해당 이용자의
                            개인정보는 지체 없이 파기 됩니다. 그리고 관계법령의 규정에 따라 아래와
                            같이 관계 법령에서 정한 일정한 기간 동안 회원정보를 보관합니다. <br />
                            <br />
                            1. 서비스 이용 관련 개인정보 (로그인기록)보존 근거 : 통신비밀보호법 보존
                            기간 : 3개월 <br />
                            2. 표시/광고에 관한 기록 보존 근거 : 전자상거래 등에서의 소비자보호에
                            관한 법률 보존 기간 : 6개월 <br />
                            3. 계약 또는 청약철회 등에 관한 기록 보존 근거 : 전자상거래 등에서의
                            소비자보호에 관한 법률 보존 기간 : 5년 <br />
                            4. 대금결제 및 재화 등의 공급에 관한 기록 보존 근거 : 전자상거래
                            등에서의 소비자보호에 관한 법률 보존 기간 : 5년 <br />
                            5. 소비 자의 불만 또는 분쟁처리에 관한 기록 보존 근거 : 전자상거래
                            등에서의 소비자보호에 관한 법률 보존 기간 : 3년 <br />
                            6. 개인위치정보에 관한 기록 보존 근거 : 위치정보의 보호 및 이용 등에
                            관한 법률 보존 기간 : 6개월 7. 전자금융 거래에 관한 기록 보존 근거 :
                            전자금융거래법 보존 기간 : 5년
                        </p>
                    </div>
                </div>
            </BottomModal>
        </>
    );
};
