import React from "react";
import UserConsulting from "./UserConsulting";

export default ({ text }) => {
  return (
    <div>
      <p className="hidden lg:block pageTitle">{text}</p>
      <div>
        <UserConsulting />
      </div>
    </div>
  );
};
