import React, { useState } from 'react';
import styled from 'styled-components';
import 'react-modern-drawer/dist/index.css';
import Drawer from 'react-modern-drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faClose } from '@fortawesome/pro-regular-svg-icons';
import { faHome, faMountains, faPowerOff } from '@fortawesome/pro-solid-svg-icons';
import { Link } from 'react-router-dom';
import LogoSVG from '../../assets/images/tree.svg';
import colLogo from '../../assets/images/colLogo.svg';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from '../../apollo';
import { useTranslation } from 'react-i18next';

import { logUserOut } from '../../apollo';
import {
    HeadBackButton,
    HeadCloseButton,
    HeadFiltterButton,
    HeadGridButton,
    HeadMapButton,
    HeadOnDrawer,
} from '../../components/linkButton/HeaderButton';
import { LanguageButton } from '../../components/button/LanguageButton';

const Label = styled.p`
    font-size: 13px;
    color: #969696;
    margin-bottom: 15px;
`;

const menu = [
    {
        key: 'main',
        icon: faHome,
        to: '/main',
    },
    {
        key: 'mountains',
        icon: faMountains,
        to: '/mountains',
    },
];

const LinkButton = styled(Link)``;
const LinkIcon = styled(FontAwesomeIcon)`
    color: #505157;
    font-size: 16px;
    width: 50px;
`;
const LinkText = styled.p`
    font-size: 17px;
    color: #505157;
    font-weight: 600;
`;

const mypage = [
    {
        key: 'edit',
        icon: faAngleRight,
        to: '/account/edit',
    },
    {
        key: 'user',
        icon: faAngleRight,
        to: '/account/user/reservations',
    },
    {
        key: 'company',
        icon: faAngleRight,
        to: '/account/company/reservations',
    },
    {
        key: 'notifications',
        icon: faAngleRight,
        to: '/account/notification',
    },
];
const ArrowIcon = styled(FontAwesomeIcon)`
    color: #868686;
    font-size: 15px;
    width: 50px;
`;
const Logout = styled.button`
    background-color: #ececec;
    width: 100%;
    padding: 15px 0 13px;
    border-radius: 10px;
`;
const PowerOffIcon = styled(FontAwesomeIcon)`
    width: 30px;
    color: #bbbbbb;
`;
const LogoutText = styled.p`
    color: #9a9a9a;
    font-size: 15px;
`;
const CloseButton = styled.button`
    height: 50px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
`;
const LogoWrap = styled.div`
    width: 46px;
    height: 46px;
    border-radius: 23px;
    background-color: #ececec;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`;
const Line = styled.div`
    background-color: #e9e9e9;
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
`;
const LoginButton = styled(Link)`
    background-color: #44a16a;
    color: #fff;
    display: flex;
    flex: 1;

    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 0 8px;
    border-radius: 10px;
`;
const JoinButton = styled(Link)`
    background-color: #e9e9e9;
    color: #8a8a8a;
    display: flex;
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px 0 8px;
    border-radius: 10px;
`;
const HeadName = styled.div`
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 768px) {
        font-size: 16px;
        height: 60px;
    }
`;
export default ({ Title, title, setListType, Map, List, back, Close, to, Filter, showFilter }) => {
    const { t } = useTranslation();
    const isLoggedIn = useReactiveVar(isLoggedInVar);

    const meJson = localStorage.getItem('ME');
    const me = meJson && JSON.parse(meJson);

    const [isOpen, setIsOpen] = useState(false);
    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <div className="headSticky sticky-header justify-center items-center flex lg:hidden">
                {back ? <HeadBackButton /> : <HeadOnDrawer onClick={toggleDrawer} />}

                {Title && <HeadName>{title}</HeadName>}
                {Map && (
                    <HeadMapButton
                        onClick={() => {
                            localStorage.setItem('LISTTYPE', 'MAP');
                            setListType('MAP');
                        }}
                    />
                )}
                {List && (
                    <HeadGridButton
                        onClick={() => {
                            localStorage.setItem('LISTTYPE', 'LIST');
                            setListType('LIST');
                        }}
                    />
                )}
                {Filter && <HeadFiltterButton onClick={showFilter} />}
                {Close && <HeadCloseButton to={null} />}

                <Drawer
                    open={isOpen}
                    onClose={toggleDrawer}
                    direction="left"
                    className="bla bla bla"
                >
                    <div className="px-4 flex flex-col justify-between h-full">
                        <div>
                            <CloseButton onClick={toggleDrawer}>
                                <FontAwesomeIcon
                                    icon={faClose}
                                    style={{ fontSize: 24, color: '#868686' }}
                                />
                            </CloseButton>
                            {isLoggedIn ? (
                                <div className="mt-5 mb-14">
                                    <LogoWrap>
                                        <div className="flex justify-center items-center w-3/4">
                                            <img src={LogoSVG} alt="Logo" />
                                        </div>
                                    </LogoWrap>
                                    <p style={{ fontSize: 20, fontWeight: 600 }}>
                                        {me.role === 'Company' ? me.company.companyName : me?.name}
                                    </p>
                                    <span>@{me?.userId}</span>
                                </div>
                            ) : (
                                <div>
                                    <div className="flex justify-center items-center flex-col">
                                        <div style={{ width: 35 }}>
                                            <img src={colLogo} alt="Logo" />
                                        </div>
                                        <p className="mt-5 mb-5">{t(`drawer.login.text`)}</p>
                                        <div className="flex justify-between w-full mb-12">
                                            <div className="px-1 flex-1">
                                                <LoginButton to={`/login`}>
                                                    {t(`drawer.login.buttons.login`)}
                                                </LoginButton>
                                            </div>
                                            <div className="px-1 flex-1">
                                                <JoinButton to={`/join`}>
                                                    {t(`drawer.login.buttons.join`)}
                                                </JoinButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <Label>{t(`drawer.nav.title`)}</Label>
                            <div className="mt-3">
                                {menu.map((item, index) => {
                                    return (
                                        <LinkButton
                                            key={index}
                                            to={item.to}
                                            className="flex justify-start items-center py-3"
                                        >
                                            <LinkIcon icon={item.icon} />
                                            <LinkText>{t(`drawer.nav.items.${item.key}`)}</LinkText>
                                        </LinkButton>
                                    );
                                })}
                            </div>
                        </div>
                        {isLoggedIn && (
                            <div>
                                <Line />
                                <Label>{t(`drawer.config.title`)}</Label>
                                <div className="mt-3">
                                    {mypage
                                        .filter((item) =>
                                            me.role !== 'Company' ? item.key !== 'company' : true,
                                        )
                                        .map((item, index) => {
                                            return (
                                                <Link
                                                    key={index}
                                                    className="flex justify-between items-center py-3"
                                                    to={item.to}
                                                >
                                                    <p>{t(`drawer.config.items.${item.key}`)}</p>
                                                    <ArrowIcon icon={item.icon} />
                                                </Link>
                                            );
                                        })}
                                </div>
                                <div className="mt-5 mb-5">
                                    <Logout
                                        className="flex justify-center items-center"
                                        onClick={logUserOut}
                                    >
                                        <PowerOffIcon icon={faPowerOff} />
                                        <LogoutText>{t(`drawer.config.logout`)}</LogoutText>
                                    </Logout>
                                    <LanguageButton />
                                </div>
                            </div>
                        )}
                    </div>
                </Drawer>
            </div>
        </>
    );
};
