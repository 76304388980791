import React, { useState } from "react";
import styled from "styled-components";
import RegionLink from "../../components/button/RegionLink";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { COMMON_REGION_LIST_QUERY } from "../../lib/queries/mountain";

const SelectWrap = styled.div`
  margin: 0 -10px;
`;

export default () => {
  const { t } = useTranslation();

  const { data, error, loading } = useQuery(COMMON_REGION_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      take: 100,
      skip: 0,
    },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <></>;
  }

  return (
    <SelectWrap className="hidden md:inline-flex flex-wrap">
      {data.commonRegionList.results.map((item, index) => {
        return (
          <RegionLink
            key={index}
            to={"/mountains?region=" + item.id}
            active={item.id === 0}
            region={t(`main.region.options.${item.id}`)}
            length={item.count}
          />
        );
      })}
    </SelectWrap>
  );
};
