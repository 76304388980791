import React from 'react';
import styled from 'styled-components';
import NoneProgram from '../../components/forest/program/NoneProgram';
import { Link, useParams } from 'react-router-dom';
import { MoreLink } from '../../components/linkButton/More';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from '../../apollo';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

const Fiexd = styled.div`
    position: sticky;
    /* width: 35%; */
    background-color: #fff;
    top: 200px;
    border-radius: 30px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.02);
    height: min-content;
    width: 600px;
    @media (max-width: 1536px) {
        width: 40%;
    }
    @media (max-width: 1024px) {
        display: none;
    }
`;
const List = styled.div`
    border-bottom-width: 1px;

    border-color: ${(props) => (props.last ? 'transparent' : '#eee')};
`;
const ProgramCount = styled.p`
    color: #a0a0a0;
    font-size: 17px;
    font-weight: 500;
`;
const ProgramName = styled.p`
    font-size: 19px;
    font-weight: 500;
    color: #1f1f1f;
`;
const ProgramCompany = styled.span`
    color: #969696;
    font-size: 15px;
`;

const LoginLink = styled.div`
    margin-top: 20px;
    background-color: #f8f8f8;
    width: 100%;
    padding: 20px 0 18px;
    border-radius: 10px;
`;
const LoginIcon = styled(FontAwesomeIcon)`
    width: 20px;
    margin-right: 5px;
    color: #b6b6b6;
`;
const LoginText = styled.p`
    color: #9a9a9a;
    font-size: 15px;
`;

export default ({ data }) => {
    const { t } = useTranslation();
    const isLoggedIn = useReactiveVar(isLoggedInVar);
    const params = useParams();
    const id = params.id;

    const {
        mountainRead: {
            result: {
                createdAt,
                updatedAt,

                commonState,
                no,
                name,
                reason,
                locationInfo,
                locationSido,
                height,
                description,
                roadAddress,
                jibunAddress,
                longitude,
                latitude,

                commonFiles,
                regionHasMountains,
                phytoncides,
                barrierfrees,
                programs,
                user,
                isBarrierfree,
            },
        },
    } = data;

    return (
        <>
            <Fiexd className=" sticky bottom-0 lg:flex flex-col mt-20 py-10 px-8">
                <p className="DetailLabelTitle">{t(`mountain.detail.program.title`)}</p>
                <div className="mt-10">
                    {programs.length > 0 ? (
                        <>
                            <ProgramCount>
                                {t(`mountain.detail.program.total`)} {programs.length} 건
                            </ProgramCount>
                            {isLoggedIn == false && (
                                <LoginLink className="flex justify-center items-center">
                                    <LoginIcon icon={faCircleInfo} />
                                    <LoginText>
                                        {t(`mountain.detail.program.logout.message`)}
                                    </LoginText>
                                </LoginLink>
                            )}

                            {programs.map((item, index) => {
                                return (
                                    <List
                                        key={index}
                                        last={item.last}
                                        className="flex justify-between items-center py-3 my-3 lastBorderNone"
                                    >
                                        <div>
                                            <ProgramName>{item.title}</ProgramName>
                                            <ProgramCompany>
                                                {item.company.companyName}
                                            </ProgramCompany>
                                        </div>
                                        {isLoggedIn && (
                                            <MoreLink
                                                text={t(`mountain.detail.program.button.text`)}
                                                to={`/mountains/${id}/programs/${item.id}`}
                                            />
                                        )}
                                    </List>
                                );
                            })}
                        </>
                    ) : (
                        <NoneProgram text={t(`mountain.detail.program.empty.message`)} />
                    )}
                </div>
            </Fiexd>
        </>
    );
};
