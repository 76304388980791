import React from "react";
import FilterDropDownButton from "../../components/button/FilterDropDownButton";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { COMMON_REGION_LIST_QUERY } from "../../lib/queries/mountain";

export default ({ baseurl, info, selected }) => {
  const { t } = useTranslation();

  const { data, error, loading } = useQuery(COMMON_REGION_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      take: 100,
      skip: 0,
    },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <></>;
  }

  const regionOptions = data.commonRegionList.results.map((item) => {
    return {
      key: item.id,
      value: `${t(
        `main.region.options.${item.id}`
      )}(${item.count.toLocaleString()})`,
    };
  });

  return (
    <FilterDropDownButton
      baseurl={baseurl}
      info={info}
      options={regionOptions}
      selected={selected}
      isLeft
    />
  );
};
