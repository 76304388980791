import React from 'react';
import styled from 'styled-components';
import ViSVG from '../../assets/images/vietnam.svg';
import KoSVG from '../../assets/images/korea.svg';
import { useReactiveVar } from '@apollo/client';
import { languageVar, toggleLanguage } from '../../apollo';

const StyleButton = styled.button`
    margin: ${(props) => (props.isblack ? '0 25px' : '0')};
    background-color: ${(props) => (props.isblack ? '#353535' : '#F8F8F8')};
    padding: 18px 30px 14px;
    border-radius: 30px;
    @media (max-width: 1024px) {
        width: 100%;
        justify-content: center;
        background-color: #ececec;
        padding: 15px 0 13px;
        border-radius: 10px;
        margin-top: 10px;
    }
`;
const Icon = styled.div`
    width: 23px;
    margin-bottom: 2px;
    margin-right: 10px;
    @media (max-width: 1024px) {
        width: 18px;
    }
`;
const ButtonText = styled.p`
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.6px;
    color: #8d8d8d;
    color: ${(props) => (props.isblack ? '#fff' : '#9E9E9E')};
    @media (max-width: 1024px) {
        color: #9a9a9a;
        font-size: 15px;
    }
`;

export const LanguageButton = () => {
    const language = useReactiveVar(languageVar);

    const changeLanguage = () => {
        toggleLanguage();
    };

    return (
        <StyleButton className="flex items-center" isblack={false} onClick={changeLanguage}>
            {language === 'vi' && (
                <>
                    <Icon>
                        <img src={KoSVG} />
                    </Icon>
                    <ButtonText isblack={false}>KO</ButtonText>
                </>
            )}
            {language === 'ko' && (
                <>
                    <Icon>
                        <img src={ViSVG} />
                    </Icon>
                    <ButtonText isblack={false}>VI</ButtonText>
                </>
            )}
        </StyleButton>
    );
};
