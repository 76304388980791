import { Fragment } from "react";
import PageHead from "../../components/forest/content/PageHead";
import MainSearch from "../../components/forest/MainSearch";
import { useTranslation } from "react-i18next";

import { useSearchParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import Img from "../../assets/images/user/modalIcon.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

export default () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      keyword: searchParams.get("keyword") ? searchParams.get("keyword") : "",
    },
  });

  const onSubmit = async (data) => {
    console.log({ data });
    try {
      if (data.keyword === "") {
        MySwal.fire({
          showCloseButton: true,
          imageUrl: Img,
          imageWidth: 80,
          imageHeight: 80,
          title: <p className="modalTitle">{t(`main.search.alert.title`)}</p>,
          html: (
            <p className="modalSubText">{t(`main.search.alert.message`)}</p>
          ),
          confirmButtonText: t(`main.search.alert.buttons.confirm`),
          confirmButtonColor: `#44a16a`,
        });
        return;
      }

      const url =
        "/mountains?" +
        (data.keyword ? `keyword=${data.keyword}&` : ``) +
        `page=1`;
      navigate(url);
    } catch (e) {
      console.log(e);
    }
  };

  const onClear = () => {
    setValue("keyword", "");
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-start items-baseline flex-col lg:flex-row lg:place-content-between lg:items-center">
          <PageHead top={t(`main.title.top`)} main={t(`main.title.main`)} />
          <MainSearch
            control={control}
            name="keyword"
            onClear={onClear}
            title={t(`main.search.input.title`)}
            placeholder={t(`main.search.input.placeholder`)}
          />
        </div>
        <div className="hidden md:block py-10">
          <p className="myeongjo text-2xl" style={{ fontWeight: 700 }}>
            {t(`main.title.sub`)}
          </p>
        </div>
      </form>
    </Fragment>
  );
};
