import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faXmark } from '@fortawesome/pro-regular-svg-icons';

import { Link, useNavigate } from 'react-router-dom';
import { HeadBackButton } from '../../components/linkButton/HeaderButton';

const Head = styled.div`
    border-bottom-width: 1px;
    border-color: rgba(240, 240, 240, 0.2);
    position: sticky;
    background-color: #009715;
`;

const Button = styled.button`
    width: 70px;
    height: 70px;
    position: absolute;
    left: 0;
    top: 0;
    @media (max-width: 768px) {
        width: 50px;
        height: 60px;
    }
`;
const Icon = styled(FontAwesomeIcon)`
    font-size: 26px;
    color: #fff;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const HeadName = styled.div`
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 768px) {
        font-size: 16px;
        height: 60px;
    }
`;
export default ({ title, to }) => {
    const navigate = useNavigate();
    const onClickBtn = () => {
        navigate(-1); // 바로 이전 페이지로 이동, '/main' 등 직접 지정도 당연히 가능
    };
    return (
        <>
            <Head className="sticky-header justify-center items-center flex lg:hidden">
                <Button onClick={onClickBtn} className="items-center justify-center">
                    <Icon icon={faAngleLeft} />
                </Button>
                <HeadName>{title}</HeadName>
            </Head>
        </>
    );
};
