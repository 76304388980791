import React from "react";
import InputErrorText from "../../components/logout/InputErrorText";
import ClearButton from "../../components/button/ClearButton";
import { InputSubButton } from "../../components/linkButton/InputSub";
import { useTranslation } from "react-i18next";

export default ({
  Controller,
  control,
  setValue,
  watch,
  errors,
  onPhoneVerifySend,
  isCounting,
  remainingTime,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <Controller
        control={control}
        rules={{
          required: t(`join.id.rules.required`),
          minLength: {
            value: 5,
            message: t(`join.id.rules.min`),
          },
          maxLength: {
            value: 15,
            message: t(`join.id.rules.max`),
          },
          validate: (value) => {
            var noEngRule = /^[a-zA-Z0-9]*$/;
            return !noEngRule.test(value)
              ? t(`join.id.rules.validate`)
              : undefined;
          },
        }}
        name="userId"
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="py-3 lg:py-5 ">
            <p className="mb-0 inputLabel sm:mb-3">{t(`join.id.title`)}</p>
            <div className="mt-3 flex-col flex justify-between  items-stretch md:mt-5 md:flex-row">
              <div className="inputGreyBox flex items-center justify-between">
                <input
                  type="text"
                  className="defaultInput"
                  placeholder={t(`join.id.input.placeholder`)}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  autocomplete="username"
                />
                {value !== undefined && value !== "" && (
                  <ClearButton onClick={() => setValue("userId", "")} />
                )}
              </div>
            </div>
            {errors.userId && <InputErrorText text={errors.userId.message} />}
          </div>
        )}
      />
      <Controller
        control={control}
        rules={{
          required: t(`join.password.rules.required`),
          minLength: {
            value: 8,
            message: t(`join.password.rules.max`),
          },
        }}
        name="password"
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="py-3 lg:py-5 ">
            <p className="mb-0 inputLabel sm:mb-3">
              {t(`join.password.title`)}
            </p>
            <div className="mt-3 flex-col flex justify-between  items-stretch md:mt-5 md:flex-row">
              <div className="inputGreyBox flex items-center justify-between">
                <input
                  type="password"
                  className="defaultInput"
                  placeholder={t(`join.password.input.placeholder`)}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  autocomplete="new-password"
                />
                {value !== undefined && value !== "" && (
                  <ClearButton onClick={() => setValue("password", "")} />
                )}
              </div>
            </div>
            {errors.password && (
              <InputErrorText text={errors.password.message} />
            )}
          </div>
        )}
      />
      <Controller
        control={control}
        rules={{
          required: t(`join.passwordConfirm.rules.required`),
          validate: (value) => {
            return value !== watch("password")
              ? t(`join.passwordConfirm.rules.validate`)
              : undefined;
          },
        }}
        name="passwordConfirm"
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="py-3 lg:py-5 ">
            <p className="mb-0 inputLabel sm:mb-3">
              {t(`join.passwordConfirm.title`)}
            </p>
            <div className="mt-3 flex-col flex justify-between  items-stretch md:mt-5 md:flex-row">
              <div className="inputGreyBox flex items-center justify-between">
                <input
                  type="password"
                  className="defaultInput"
                  placeholder={t(`join.passwordConfirm.input.placeholder`)}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  autocomplete="new-password"
                />
                {value !== undefined && value !== "" && (
                  <ClearButton
                    onClick={() => setValue("passwordConfirm", "")}
                  />
                )}
              </div>
            </div>
            {errors.passwordConfirm && (
              <InputErrorText text={errors.passwordConfirm.message} />
            )}
          </div>
        )}
      />
      <Controller
        control={control}
        rules={{
          required: t(`join.name.input.placeholder`),
        }}
        name="name"
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="py-3 lg:py-5 ">
            <p className="mb-0 inputLabel sm:mb-3">{t(`join.name.title`)}</p>
            <div className="mt-3 flex-col flex justify-between  items-stretch md:mt-5 md:flex-row">
              <div className="inputGreyBox flex items-center justify-between">
                <input
                  type="text"
                  className="defaultInput"
                  placeholder={t(`join.name.input.placeholder`)}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
                {value !== undefined && value !== "" && (
                  <ClearButton onClick={() => setValue("name", "")} />
                )}
              </div>
            </div>
            {errors.name && <InputErrorText text={errors.name.message} />}
          </div>
        )}
      />
      <Controller
        control={control}
        rules={{
          required: t(`join.phone.input.placeholder`),
        }}
        name="phone"
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="py-3 lg:py-5 ">
            <p className="mb-0 inputLabel sm:mb-3">{t(`join.phone.title`)}</p>
            <div className="mt-3 flex-col flex justify-between  items-stretch md:mt-5 md:flex-row">
              <div className="inputGreyBox flex items-center justify-between">
                <input
                  className="defaultInput"
                  id="phone"
                  type="text"
                  placeholder={t(`join.phone.input.placeholder`)}
                  maxLength={12}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
                {value !== undefined && value !== "" && (
                  <ClearButton onClick={() => setValue("phone", "")} />
                )}
              </div>
              <InputSubButton
                onClick={() => {
                  onPhoneVerifySend();
                }}
                type="button"
                text={t(`join.phone.button.text`)}
              />
            </div>
            {errors.phone && <InputErrorText text={errors.phone.message} />}
          </div>
        )}
      />
      <Controller
        control={control}
        rules={{
          required: t(`join.code.input.placeholder`),
        }}
        name="code"
        render={({ field: { onChange, onBlur, value } }) => (
          <div className="mb-10">
            <div className="block md:flex justify-between">
              <div className="inputGreyBox flex items-center justify-between">
                <input
                  className="defaultInput"
                  id="code"
                  type="text"
                  placeholder={t(`join.code.input.placeholder`)}
                  maxLength={12}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                />
                {value !== undefined && value !== "" && (
                  <ClearButton onClick={() => setValue("code", "")} />
                )}
                {isCounting ? (
                  <p className="timers">{`${Math.floor(
                    remainingTime / 60
                  )}:${String(remainingTime % 60).padStart(2, "0")}`}</p>
                ) : null}
              </div>
            </div>
            {errors.code && <InputErrorText text={errors.code.message} />}
          </div>
        )}
      />
    </div>
  );
};
