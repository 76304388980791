import React from "react";
import { Navigate } from "react-router-dom";
import App from "../App";

import { PublicAuth, ProtectedAuth } from "./auth";

// 공통
import Main from "../pages/Main";

import MountainList from "../pages/mountain/List";
import MountainDetail from "../pages/mountain/Detail";

// 회원
import MountainProgram from "../pages/mountain/Program";
import AccountEdit from "../pages/account/Edit";
import AccountUserReservationList from "../pages/account/user/ReservationList";
import AccountCompanyReservationList from "../pages/account/company/ReservationList";

// 게스트
import Login from "../pages/auth/Login";
import Join from "../pages/auth/Join";
import IdFind from "../pages/auth/IdFind";
import PwFind from "../pages/auth/PwFind";

// 약관
import Privacy from "../pages/policy/Privacy";
import Service from "../pages/policy/Service";

// 에러
import Error from "../pages/Error";
import Notification from "../pages/account/Notification";

export default [
  {
    path: "/",
    element: <Navigate to="/main" />,
  },
  {
    path: "main",
    element: <Main />, //메인
  },

  {
    path: "mountains",
    element: <MountainList />, // 숲관광지 리스트뷰
  },
  {
    path: "mountains/:id",
    element: <MountainDetail />, //숲 디테일 페이지
  },

  {
    path: "policy/privacy",
    element: <Privacy />, //개인정보처리방침
  },
  {
    path: "policy/service",
    element: <Service />, //이용약관
  },
  {
    element: <PublicAuth />,
    children: [
      {
        element: <App />,
        children: [
          {
            path: "login",
            element: <Login />, //로그인
          },
          {
            path: "join",
            element: <Join />, // 회원가입
          },
          {
            path: "idfind",
            element: <IdFind />, //아이디 찾기
          },
          {
            path: "pwfind",
            element: <PwFind />, //비밀번호 찾기
          },
        ],
      },
    ],
  },
  {
    element: <ProtectedAuth />,
    children: [
      {
        path: "mountains/:id/programs/:programId",
        element: <MountainProgram />, //숲 프로그램
      },
      {
        path: "account/edit",
        element: <AccountEdit />, //내정보 수정
      },

      {
        path: "account/user/reservations",
        element: <AccountUserReservationList />, //상담내역
      },
      {
        path: "account/company/reservations",
        element: <AccountCompanyReservationList />, //상담관리
      },
      {
        path: "account/notification",
        element: <Notification />, //알림 내역
      },
    ],
  },
  {
    path: "*",
    element: <Error />,
  },
];
