import React, { useState } from 'react';
import styled from 'styled-components';
import {
    HeadAlarm,
    HeadBackButton,
    HeadLanguage,
    HeadOnDrawer,
} from '../../components/linkButton/HeaderButton';
import DrawerLayout from './DrawerLayout';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar, languageVar, toggleLanguage } from '../../apollo';

const HeadName = styled.div`
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333333;
    font-size: 18px;
    font-weight: 600;
    @media (max-width: 768px) {
        font-size: 16px;
        height: 60px;
    }
`;
export default ({ title, isBack }) => {
    const isLoggedIn = useReactiveVar(isLoggedInVar);

    const [isOpen, setIsOpen] = useState(false);
    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <div className="headSticky sticky-header justify-center items-center flex lg:hidden">
                {isBack ? <HeadBackButton /> : <HeadOnDrawer onClick={toggleDrawer} />}
                <HeadName>{title}</HeadName>
                <div className=" absolute  top-0 right-0 flex">
                    <HeadLanguage /> {isLoggedIn && <HeadAlarm />}
                </div>
                <DrawerLayout isOpen={isOpen} toggleDrawer={toggleDrawer} />
            </div>
        </>
    );
};
