import React from 'react';
import { bool, func } from 'prop-types';
// import SVG from 'src/SVG';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

const Button = styled.button`
    position: absolute;
    right: -90px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    padding: 50px 30px;
`;
const Icon = styled(FontAwesomeIcon)`
    font-size: 42px;
`;
const RightNav = React.memo(({ disabled, onClick }) => {
    return (
        <Button
            type="button"
            className="image-gallery-right-nav"
            disabled={disabled}
            onClick={onClick}
            aria-label="Next Slide"
        >
            <Icon icon={faChevronRight} color="#AEAEAE" />
        </Button>
    );
});

RightNav.displayName = 'RightNav';

RightNav.propTypes = {
    disabled: bool.isRequired,
    onClick: func.isRequired,
};

export default RightNav;
