import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

const List = styled.li`
  /* border-bottom-width: 1px;
    border-color: #e2e2e2; */
`;
const Road = styled.p`
  font-size: 20px;
  color: #1f1f1f;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 17px;
  }
`;
const Address = styled.span`
  color: #969696;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;
const Line = styled.div`
  width: 1px;
  height: 15px;
  background-color: #c7c7c7;
`;
const Km = styled.span`
  color: #969696;
  font-size: 16px;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const OnTap = styled.button`
  background-color: #52be77;
  height: min-content;
  color: #fff;
  padding: 7px 15px 4px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 18px;
  @media (max-width: 1024px) {
    display: none;
  }
`;
const Button = styled.button`
  padding: 13px;
  border-radius: 10px;
  border-radius: 10px;
  margin: 0 5px;
  justify-content: center;
  align-items: center;
  background: #ebebeb;
`;
export default ({ name, address, onClick }) => {
  const { t } = useTranslation();

  return (
    <List className="flex justify-between my-3 py-3 items-center">
      <div className="flex-1">
        <Road>{name}</Road>
        <div className="flex-1 flex items-center mt-0 md:mt-2">
          <Address>{address}</Address>
        </div>
      </div>
      <OnTap onClick={onClick} className="hidden md:flex">
        <span>{t(`mountain.detail.barrierfree.button.text`)}</span>
      </OnTap>
      <Button className="flex lg:hidden" type="button">
        <FontAwesomeIcon icon={faMap} />
      </Button>
    </List>
  );
};
