import React from 'react';
import styled from 'styled-components';
import { faWheelchair } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTree } from '@fortawesome/pro-solid-svg-icons';

const Lable = styled.div`
    padding: 5px 10px 3px;
    border-radius: 5px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 7px;
    background: #fafafa;
    border: 1px solid #eee;
    color: #737373;
    margin-bottom: 7px;
    @media (max-width: 768px) {
        padding: 4px 8px 2px;
    }
`;
const Icon = styled(FontAwesomeIcon)`
    font-size: 15px;
    width: 23px;
    margin-bottom: 1px;
    color: #2c9b2b;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;
const LabelText = styled.p`
    font-size: 15px;
    white-space: nowrap;
    margin-left: 5px;
    color: #909090;
    font-weight: 500;
    @media (max-width: 768px) {
        font-size: 14px;
        margin-left: 4px;
    }
`;

const GridLabel = styled.div`
    padding: 5px 10px 4px;
    border-radius: 5px;
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 7px;
    background: transparent;
    border: 1px solid #eee;
    color: #fff;
    margin-bottom: 7px;
`;

const GridIcon = styled(FontAwesomeIcon)`
    font-size: 18px;
    width: 20px;
    margin-bottom: 1px;
    color: #fff;
    @media (max-width: 768px) {
        font-size: 15px;
    }
`;
const GridLabelText = styled.p`
    font-size: 17px;
    white-space: nowrap;
    margin-left: 5px;
    color: #fff;
    font-weight: 500;
    @media (max-width: 768px) {
        font-size: 15px;
    }
`;
export const BarrierLabel = ({ text }) => {
    return (
        <Lable>
            <Icon icon={faWheelchair} />
            <LabelText>{text}</LabelText>
        </Lable>
    );
};

export const ProgramLabel = ({ text }) => {
    return (
        <Lable>
            <Icon icon={faTree} />
            <LabelText>{text}</LabelText>
        </Lable>
    );
};

export const GridBarrierLabel = ({ text }) => {
    return (
        <GridLabel>
            <GridIcon icon={faWheelchair} />
            <GridLabelText>{text}</GridLabelText>
        </GridLabel>
    );
};
export const GridProgramLabel = ({ text }) => {
    return (
        <GridLabel>
            <GridIcon icon={faTree} />
            <GridLabelText>{text}</GridLabelText>
        </GridLabel>
    );
};
