import { gql } from "@apollo/client";

export const NOTIFICATIONDETAIL_LIST_QUERY = gql`
  query notificationDetailList($language: String, $take: Int, $skip: Int!) {
    notificationDetailList(
      input: { language: $language, take: $take, skip: $skip }
    ) {
      ok
      results {
        id
        createdAt
        updatedAt

        commonState
        notification {
          id
          createdAt
          updatedAt

          commonState
          title
          body
          data
        }
      }
      error
      totalResults
      totalPages
    }
  }
`;

export const NOTIFICATIONDETAIL_DELETE_MUTATION = gql`
  mutation notificationDetailDelete($id: Float!) {
    notificationDetailDelete(input: { id: $id }) {
      ok
      error
    }
  }
`;
export const NOTIFICATIONDETAIL_ALLDELETE_MUTATION = gql`
  mutation notificationDetailAllDelete {
    notificationDetailAllDelete {
      ok
      error
    }
  }
`;
