import React from 'react';
import TermsHead from '../../layout/header/TermsHead';
import { useTranslation } from 'react-i18next';

const Privacy = () => {
    const { t } = useTranslation();

    return (
        <>
            <TermsHead title={t(`policy.privacy.title`)} />
            <div
                className="flex flex-col items-center justify-center w-full h-full bg-Default"
                style={{ backgroundColor: '#009715' }}
            >
                <div className="max-w-3xl px-5 py-10 md;py-20">
                    <p className="mt-5 md:mt-20 mb-5 text-xl font-bold tracking-tight text-white text-blue md:mb-36 md:text-7xl">
                        {t(`policy.privacy.title`)}
                    </p>
                    <p
                        className="  text-sm  sm:text-base font-normal leading-7 tracking-tight text-white md:leading-10 md:text-xl"
                        dangerouslySetInnerHTML={{ __html: t(`policy.privacy.content`) }}
                    ></p>
                </div>
            </div>
        </>
    );
};

export default Privacy;
