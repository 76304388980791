import React from "react";
import styled from "styled-components";
import Drawer from "react-modern-drawer";
import { faAngleRight } from "@fortawesome/pro-regular-svg-icons";
import {
  faHome,
  faMountains,
  faPowerOff,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReactiveVar } from "@apollo/client";
import { isLoggedInVar } from "../../apollo";
import { useTranslation } from "react-i18next";
import { logUserOut } from "../../apollo";
import { Link } from "react-router-dom";
import { Close, IsLoginTop, IsLogoutTop } from "../../components/drawer/widget";
import { ScrollToTop } from "../../components/linkButton/Default";
const menu = [
  {
    key: "main",
    icon: faHome,
    to: "/main",
  },
  {
    key: "mountains",
    icon: faMountains,
    to: "/mountains",
  },
];
const mypage = [
  {
    key: "edit",
    icon: faAngleRight,
    to: "/account/edit",
  },
  {
    key: "user",
    icon: faAngleRight,
    to: "/account/user/reservations",
  },
  {
    key: "company",
    icon: faAngleRight,
    to: "/account/company/reservations",
  },
];
const Label = styled.p`
  font-size: 14px;
  color: #969696;
  margin-bottom: 10px;
`;
const LinkButton = styled(Link)``;
const LinkIcon = styled(FontAwesomeIcon)`
  color: #7b7b7b;
  font-size: 15px;
  width: 20px;
  margin-right: 15px;
  margin-bottom: 3px;
`;
const LinkText = styled.p`
  font-size: 17px;
  color: #505157;
  font-weight: 600;
`;

const Line = styled.div`
  background-color: #e9e9e9;
  width: 100%;
  height: 1px;
  margin-bottom: 30px;
`;
const MypageText = styled.p`
  color: #868686;
`;
const ArrowIcon = styled(FontAwesomeIcon)`
  color: #868686;
  font-size: 15px;
  width: 30px;
`;
const Logout = styled.button`
  background-color: #ececec;
  width: 100%;
  padding: 15px 0 13px;
  border-radius: 7px;
`;
const PowerOffIcon = styled(FontAwesomeIcon)`
  width: 20px;
  color: #bbbbbb;
  margin-right: 5px;
`;
const LogoutText = styled.p`
  color: #9a9a9a;
  font-size: 15px;
`;

export default ({ isOpen, toggleDrawer }) => {
  const { t } = useTranslation();
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const meJson = localStorage.getItem("ME");
  const me = meJson && JSON.parse(meJson);

  return (
    <Drawer open={isOpen} onClose={toggleDrawer} direction="left">
      <div className="px-3 flex flex-col justify-between h-full">
        <div className="">
          <Close onClick={toggleDrawer} />
          {isLoggedIn ? <IsLoginTop /> : <IsLogoutTop />}
          <Label>{t(`drawer.nav.title`)}</Label>
          <div className="mt-3">
            {menu.map((item, index) => {
              return (
                <LinkButton
                  key={index}
                  to={item.to}
                  className="flex justify-start items-center py-2 mb-1"
                >
                  <LinkIcon icon={item.icon} />
                  <LinkText>{t(`drawer.nav.items.${item.key}`)}</LinkText>
                </LinkButton>
              );
            })}
          </div>
        </div>
        {isLoggedIn && (
          <div>
            <Line />
            <Label>{t(`drawer.config.title`)}</Label>
            <div className="mt-3">
              {mypage
                .filter((item) =>
                  me.role !== "Company" ? item.key !== "company" : true
                )
                .map((item, index) => {
                  return (
                    <Link
                      key={index}
                      onClick={ScrollToTop}
                      className="flex justify-between items-center py-2 mb-1"
                      to={item.to}
                    >
                      <MypageText>
                        {t(`drawer.config.items.${item.key}`)}
                      </MypageText>
                      <ArrowIcon icon={item.icon} />
                    </Link>
                  );
                })}
            </div>
            <div className="mt-5 mb-5">
              <Logout
                className="flex justify-center items-center"
                onClick={logUserOut}
              >
                <PowerOffIcon icon={faPowerOff} />
                <LogoutText>{t(`drawer.config.logout`)}</LogoutText>
              </Logout>
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
};
