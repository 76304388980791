import React, { useState, useEffect } from "react";
import Header from "../../layout/header/HeaderNav";
import Search from "../../containers/mountain/Search";
import Region from "../../containers/mountain/Region";
import List from "../../containers/mountain/List";
import Footer from "../../layout/footer/Footer";
import MobileFooter from "../../layout/footer/MobileFooter";
import MapList from "../../layout/forest/MapList";
import Map from "../../layout/forest/Map";
import MobileMap from "../../layout/forest/MobileMap";
import FilterModal from "../../components/modals/login/FilterModal";
import { useReactiveVar } from "@apollo/client";
import { languageVar } from "../../apollo";
import { useTranslation } from "react-i18next";

import { useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { MOUNTAIN_LIST_QUERY } from "../../lib/queries/mountain";
import MobileHead from "../../layout/header/MobileHead";

export default () => {
  const { t } = useTranslation();
  const [listType, setListType] = useState();

  useEffect(() => {
    const listType = localStorage.getItem("LISTTYPE");
    setListType(listType);
  }, []);
  const [isShow, setIsShow] = useState(false);
  const toggleModal = () => {
    setIsShow(!isShow);
  };
  const [searchParams] = useSearchParams();

  const keyword = searchParams.get("keyword")
    ? searchParams.get("keyword")
    : "";
  const region = searchParams.get("region");
  const filter = searchParams.get("filter");
  const sort = searchParams.get("sort") ? searchParams.get("sort") : "distance";
  const page = searchParams.get("page") ? +searchParams.get("page") : 1;
  const baseUrl =
    "/mountains?" +
    (keyword ? `keyword=${keyword}&` : ``) +
    (region ? `region=${region}&` : ``) +
    (filter ? `filter=${filter}&` : ``) +
    (sort ? `sort=${sort}&` : ``);

  const language = useReactiveVar(languageVar);
  const take = 12;
  const skip = (page - 1) * take;
  const { data, error, loading } = useQuery(MOUNTAIN_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      language,
      take,
      skip,
      keyword,
      region,
      filter,
      sort,
    },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  if (listType === "LIST") {
    return (
      <div>
        <Header />
        <MobileHead title={t(`mountain.title`)} />

        <div className="py-5 md:py-16">
          <div className="width-1600">
            <Search
              keyword={keyword}
              region={region}
              filter={filter}
              sort={sort}
              data={data}
            />
            <Region
              keyword={keyword}
              region={region}
              filter={filter}
              sort={sort}
            />
            <List
              filter={filter}
              sort={sort}
              page={page}
              baseUrl={baseUrl}
              take={take}
              data={data}
              setListType={setListType}
            />
          </div>
        </div>
        <Footer />
        <MobileFooter />
      </div>
    );
  } else {
    return (
      <>
        <div className=" w-screen h-screen flex flex-col">
          <Header isLogin={true} className="HeaderFixed" />
          <MobileHead title={t(`mountain.title`)} />

          <div className=" overflow-hidden lg:overflow-auto flex flex-1">
            <div className="hidden lg:flex width-1600 xl:flex-col">
              <div className="MapViewList">
                <MapList
                  keyword={keyword}
                  region={+region}
                  filter={filter}
                  sort={sort}
                  page={page}
                  baseUrl={baseUrl}
                  take={take}
                  data={data}
                  setListType={setListType}
                />
              </div>
              <div className="flex-1 flex bg-slate-400 mb-7 rounded-3xl items-center justify-center MapFixed">
                <Map data={data} baseUrl={baseUrl} setListType={setListType} />
              </div>
            </div>
            <div className="w-full flex lg:hidden">
              <MobileMap
                keyword={keyword}
                region={+region}
                filter={filter}
                sort={sort}
                data={data}
                setListType={setListType}
                baseUrl={baseUrl}
              />
            </div>
          </div>
        </div>
        <FilterModal isOpen={isShow} closeModal={toggleModal} />
      </>
    );
  }
};
