import React, { useState } from "react";
import DetailGridImage from "../../components/forest/DetailGridImage";
import DetailContents from "./DetailContents";
import DetailFixedScroll from "./DetailFixedScroll";
import ImageSlideModal from "../../components/modals/login/ImageSlideModal";
import DetailBottomFixed from "./mobile/DetailBottomFixed";

export default ({ data }) => {
  const [slide, setSlide] = useState(false);
  const SlideToggleModal = () => {
    setSlide(!slide);
  };

  return (
    <>
      <div className="py-5 lg:py-16">
        <div className="width-1600">
          <DetailGridImage onClick={SlideToggleModal} data={data} />
          <div>
            <div className="flex">
              <DetailContents data={data} onClick={SlideToggleModal} />

              <DetailFixedScroll data={data} />
            </div>
          </div>
        </div>
      </div>
      <DetailBottomFixed data={data} />

      <ImageSlideModal
        isOpen={slide}
        closeModal={SlideToggleModal}
        data={data}
      />
    </>
  );
};
