import React from "react";
import { faFaceDotted } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex justify-center items-center flex-col text-center py-10">
        <FontAwesomeIcon icon={faFaceDotted} className="noIcon" />
        <p className="noTitle">{t(`mountain.list.empty.title`)}</p>
        <p
          className="noText"
          dangerouslySetInnerHTML={{ __html: t(`mountain.list.empty.message`) }}
        ></p>
      </div>
    </>
  );
};
