import React from 'react';
import styled from 'styled-components';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const CloseButton = styled.button`
    width: 54px;
    height: 54px;
    background: #eee;
    border-radius: 30px;
    @media (max-width: 768px) {
        width: 40px;
        height: 40px;
    }
`;
const Icon = styled(FontAwesomeIcon)`
    font-size: 24px; /* 기본 크기 설정 */
    color: #868686;
    margin-bottom: -5px;
    @media (max-width: 768px) {
        font-size: 20px;
        margin-bottom: -3px;
    }
`;

export const ModalCloseButton = ({ onClick }) => {
    return (
        <>
            <div className="flex justify-end">
                <CloseButton onClick={onClick}>
                    <Icon icon={faXmark} />
                </CloseButton>
            </div>
        </>
    );
};
