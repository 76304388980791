import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { DefaultButton } from "../../components/linkButton/Default";
import InputErrorText from "../../components/logout/InputErrorText";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ko } from "date-fns/esm/locale";
import axios from "axios";
import DatePickerWrap from "./DatePickerWrap";
import { useTranslation } from "react-i18next";

import { useParams, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { RESERVATION_CREATE_MUTATION } from "../../lib/queries/reservation";

import Img from "../../assets/images/user/modalIcon.svg";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import WeekWeather from "../../components/data/WeekWeather";
const MySwal = withReactContent(Swal);

const Fiexd = styled.div`
  position: sticky;
  background-color: #fff;
  top: 160px;
  border-radius: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.02);
  height: min-content;
  width: 500px;
  @media (max-width: 1024px) {
    position: relative;
    width: 100%;
    top: auto;
    background-color: transparent;
    box-shadow: none;
  }
`;
const Wrap = styled.div`
  /* margin: 20px 0; */
  padding: 20px 0;
  border-bottom: 1px solid #eee;
`;
const WrapBorderNone = styled.div`
  margin: 22px 0;
  padding: 22px 0;
`;
const Label = styled.p`
  color: #bfbfbf;
  margin-bottom: 10px;
`;

const Span = styled.span`
  padding-right: 30px;
  color: #b5b5b5;
  font-size: 15px;
`;

export default ({ data }) => {
  const { t } = useTranslation();
  const meJson = localStorage.getItem("ME");
  const me = meJson && JSON.parse(meJson);

  const navigate = useNavigate();
  const params = useParams();
  const id = params.id;
  const programId = params.programId;

  const {
    mountainProgram: {
      result: {
        createdAt,
        updatedAt,

        commonState,
        no,
        name,
        reason,
        locationInfo,
        height,
        description,
        roadAddress,
        jibunAddress,
        longitude,
        latitude,

        commonFiles,
        regionHasMountains,
        phytoncides,
        barrierfrees,
        programs,
        user,
        phytoncide,
        isBarrierfree,
        isProgram,
      },
    },
  } = data;

  const [reservationCreateMutation, { loading: reservationCreateLoading }] =
    useMutation(RESERVATION_CREATE_MUTATION);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      date: new Date(),
      persons: "1",
      name: me.name,
      phone: me.phone,
    },
  });

  const onSubmit = async (data) => {
    try {
      if (reservationCreateLoading) {
        return;
      }
      const {
        data: { reservationCreate },
      } = await reservationCreateMutation({
        variables: {
          program_id: +programId,
          date: data.date,
          persons: data.persons,
          name: data.name,
          phone: data.phone,
        },
      });
      if (!reservationCreate.ok) {
        MySwal.fire({
          showCloseButton: true,
          icon: "error",
          title: (
            <p className="modalTitle">
              {t(`mountain.program.alerts.reservationCreate.error.title`)}
            </p>
          ),
          html: (
            <p className="modalSubText">
              {t(`mountain.program.alerts.reservationCreate.error.message`)}
            </p>
          ),
          confirmButtonText: t(
            `mountain.program.alerts.reservationCreate.error.buttons.confirm`
          ),
          confirmButtonColor: `#44a16a`,
        });
        return;
      }
      MySwal.fire({
        showCloseButton: true,
        imageUrl: Img,
        imageWidth: 80,
        imageHeight: 80,
        title: (
          <p className="modalTitle">
            {t(`mountain.program.alerts.reservationCreate.confirm.title`)}
          </p>
        ),
        html: (
          <p className="modalSubText">
            {t(`mountain.program.alerts.reservationCreate.confirm.message`)}
          </p>
        ),
        confirmButtonText: t(
          `mountain.program.alerts.reservationCreate.confirm.buttons.confirm`
        ),
        confirmButtonColor: `#44a16a`,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isDismissed || result.isConfirmed) {
          navigate("/main");
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Fiexd className="px-0 py-0 mt-10 lg:mt-20 lg:py-10 lg:px-10">
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className="DetailLabelTitle">{t(`mountain.program.title`)}</p>
        <div className="mt-5">
          <Controller
            control={control}
            rules={{
              required: t(`mountain.program.date.rules.required`),
            }}
            name="date"
            render={({ field: { onChange, value } }) => {
              return (
                <Wrap>
                  <Label>{t(`mountain.program.date.title`)}</Label>
                  <DatePickerWrap value={value} onChange={onChange} />
                  {errors.date && <InputErrorText text={errors.date.message} />}
                  <WeekWeather longitude={longitude} latitude={latitude} />
                </Wrap>
              );
            }}
          />
          <Controller
            control={control}
            rules={{
              required: t(`mountain.program.persons.rules.required`),
            }}
            name="persons"
            render={({ field: { onChange, onBlur, value } }) => (
              <Wrap>
                <Label>{t(`mountain.program.persons.title`)}</Label>
                <div className="inputWrap  flex items-center">
                  <input
                    type="text"
                    className="program_input"
                    placeholder={t(
                      `mountain.program.persons.input.placeholder`
                    )}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                  <Span>{t(`mountain.program.persons.unit`)}</Span>
                </div>
                {errors.persons && (
                  <InputErrorText text={errors.persons.message} />
                )}
              </Wrap>
            )}
          />
          <Controller
            control={control}
            rules={{
              required: t(`mountain.program.name.rules.required`),
            }}
            name="name"
            render={({ field: { onChange, onBlur, value } }) => (
              <Wrap>
                <Label>{t(`mountain.program.name.title`)}</Label>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="program_input"
                    placeholder={t(`mountain.program.name.input.placeholder`)}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                </div>
                {errors.name && <InputErrorText text={errors.name.message} />}
              </Wrap>
            )}
          />
          <Controller
            control={control}
            rules={{
              required: t(`mountain.program.phone.rules.required`),
            }}
            name="phone"
            render={({ field: { onChange, onBlur, value } }) => (
              <WrapBorderNone>
                <Label>{t(`mountain.program.phone.title`)}</Label>
                <div className="inputWrap">
                  <input
                    type="text"
                    className="program_input"
                    placeholder={t(`mountain.program.phone.input.placeholder`)}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                </div>
                {errors.phone && <InputErrorText text={errors.phone.message} />}
              </WrapBorderNone>
            )}
          />
        </div>
        <DefaultButton
          type="submit"
          text={t(`mountain.program.buttons.confirm.text`)}
        />
      </form>
    </Fiexd>
  );
};
