import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import Img from '../../assets/images/user/modalIcon.svg';

const CustomModal = styled(Modal)`
    // Common styles for the Modal content
    right: auto;
    bottom: auto;
    background-color: #fff;
    max-width: 100%;
    width: 100%;
    border-radius: 30px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 30px 35px 20px; // Default padding values for larger screens
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    // Media query for screens with width less than or equal to 724px
    @media (max-width: 768px) {
        padding: 20px 12px; // Adjusted padding values for smaller screens
    }
`;
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)', // 원하는 오버레이 색상 지정
        zindex: 1000,
    },
};

const CloseButton = styled.button`
    width: 54px;
    height: 54px;
    background: #eee;
    border-radius: 30px;
    @media (max-width: 768px) {
        width: 40px;
        height: 40px;
    }
`;
const Icon = styled(FontAwesomeIcon)`
    font-size: 24px; /* 기본 크기 설정 */
    color: #868686;
    margin-bottom: -5px;
    @media (max-width: 768px) {
        font-size: 20px;
        margin-bottom: -3px;
    }
`;

function BottomModal({ isOpen, closeModal, children }) {
    return (
        <div style={{ zIndex: 100 }}>
            <CustomModal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="flex justify-end">
                    <CloseButton onClick={closeModal}>
                        <Icon icon={faXmark} />
                    </CloseButton>
                </div>

                <div className="flex flex-col justify-start">{children}</div>
            </CustomModal>
        </div>
    );
}

export default BottomModal;
