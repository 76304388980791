import React from "react";
import { faPhone, faUser, faUsers } from "@fortawesome/pro-solid-svg-icons";
import {
  ProgramTitle,
  TableDateText,
  TableIconDate,
  TableIconLabel,
  TableImg,
  TableProgramInfo,
  TableText,
  TableTwoLineText,
} from "./TableWidget";
import { useTranslation } from "react-i18next";

export default ({
  key,
  img,
  title,
  date,
  persons,
  requestDate,
  number,
  name,
  phone,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div key={key} className="tableList">
        {/* PC */}
        <div className="hidden xl:flex justify-between items-center py-6">
          <div className="text-center w-1/12">
            <TableText text={number} />
          </div>
          <div className="flex items-center w-5/12">
            <TableProgramInfo img={img} title={title} date={date} />
          </div>
          <div className="text-center w-2/12">
            <TableTwoLineText first={name} second={phone} />
          </div>
          <div className="text-center w-2/12">
            <TableText text={persons} />
          </div>
          <div className="text-center w-2/12">
            <TableDateText date={requestDate} />
          </div>
        </div>
        {/* 모바일 */}
        <div className="flex justify-between flex-col sm:flex-row py-5 xl:hidden">
          <TableImg img={img} />
          <div className="ml-0 mt-4 flex-1 flex flex-col sm:ml-5 sm:mt-0">
            <ProgramTitle text={title} />

            <div className="flex items-center">
              <TableDateText date={date} />
            </div>
            <div className="flex  w-full items-start sm:items-center mt-0 sm:mt-1 flex-col sm:flex-row">
              <TableIconLabel
                icon={faUsers}
                label={t(`account.user.reservation.items.persons`)}
                value={persons}
              />
              <div className="pl-0 sm:pl-3" />
              <TableIconDate
                label={t(`account.user.reservation.items.createdAt`)}
                date={requestDate}
              />
            </div>
            <div className="flex  items-start sm:items-center mt-0 sm:mt-1 flex-col sm:flex-row">
              <TableIconLabel
                icon={faUser}
                label={t(`account.user.reservation.items.companyName`)}
                value={name}
              />
              <div className="pl-0 sm:pl-3" />
              <TableIconLabel
                icon={faPhone}
                label={t(`account.user.reservation.items.companyTel`)}
                value={phone}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
