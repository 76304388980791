import React, { useEffect, useRef, useState } from 'react';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useSearchParams, useNavigate } from 'react-router-dom';

export default ({ baseurl, info, options, selected, isLeft }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    const dropdownRef = useRef(null);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    // 드롭다운이 열려있을 때, 바깥 영역을 클릭하면 닫히도록 합니다.
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // 드롭다운이 열릴 때 이벤트 핸들러를 추가합니다.
        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            // 컴포넌트가 언마운트될 때 이벤트 핸들러를 제거합니다.
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const keyword = searchParams.get('keyword') ? searchParams.get('keyword') : '';
    const region = searchParams.get('region') ? searchParams.get('region') : '';
    const filter = searchParams.get('filter') ? searchParams.get('filter') : '';
    const sort = searchParams.get('sort') ? searchParams.get('sort') : '';

    const onCheck = (item) => {
        const url =
            baseurl +
            (keyword ? `keyword=${keyword}&` : ``) +
            (info.key === 'region'
                ? item.key !== '' && item.key !== 0
                    ? `region=${item.key}&`
                    : ''
                : region
                ? `region=${region}&`
                : ``) +
            (info.key === 'filter'
                ? item.key !== ''
                    ? `filter=${item.key}&`
                    : ''
                : filter
                ? `filter=${filter}&`
                : ``) +
            (info.key === 'sort'
                ? item.key !== ''
                    ? `sort=${item.key}&`
                    : ''
                : sort
                ? `sort=${sort}&`
                : ``) +
            `page=1`;
        navigate(url);
    };
    return (
        <>
            <button
                className="flex items-center DropdownButton FilterDropdownButton relative left"
                onClick={toggleDropdown}
            >
                <p className="flex">
                    <span className="mr-5">{info.value} :</span>{' '}
                    {selected === null
                        ? t(`mountain.list.selects.filter.options.all`)
                        : options.find((item) => item.key === selected)?.value}
                </p>
                <FontAwesomeIcon icon={faChevronDown} className="downIcon" />
            </button>

            <div
                ref={dropdownRef}
                isOpen={isOpen}
                className={`dropdown-menu filterDrop ${isLeft ? 'left' : ''} ${
                    isOpen ? 'open' : ''
                }`}
            >
                <p className="LabelText">{info.value}</p>
                {/* 드롭다운 메뉴 내용 */}
                <ul className="flex-wrap flex ">
                    {options.map((option, index) => (
                        <li key={index}>
                            <button
                                onClick={() => onCheck(option)}
                                className={`selecteOptionButton ${
                                    selected === null
                                        ? option.key === ''
                                            ? 'selected'
                                            : ''
                                        : option.key === selected
                                        ? 'selected'
                                        : ''
                                }`}
                            >
                                {option.value}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};
