import React from 'react';
import styled from 'styled-components';

const Line = styled.div`
    width: 100%;
    height: 1px;
    background-color: #e2e2e2;
`;
export default () => {
    return (
        <>
            <Line />
        </>
    );
};
