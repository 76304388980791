import React from "react";
import GridForest from "../../components/forest/GridForest";
import { DefaultLineButton } from "../../components/linkButton/Default";
import { useReactiveVar } from "@apollo/client";
import { languageVar } from "../../apollo";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
import { MOUNTAIN_LIST_QUERY } from "../../lib/queries/mountain";

export default () => {
  const { t } = useTranslation();

  const language = useReactiveVar(languageVar);
  const take = 12;
  let page = 1;
  const { data, error, loading, fetchMore } = useQuery(MOUNTAIN_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      language,
      take,
      skip: (page - 1) * take,
    },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <>error</>;
  }

  if (data && data.mountainList.results) {
    page = Math.ceil(data.mountainList.results.length / 12) + 1;
  }
  const loadMoreData = () => {
    fetchMore({
      variables: {
        take,
        skip: (page - 1) * take,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        const index = page * 12;
        const merged =
          previousResult && fetchMoreResult.mountainList.totalResults > 0
            ? previousResult.mountainList.results.slice(0)
            : [];
        for (let i = 0; i < fetchMoreResult.mountainList.results.length; ++i) {
          merged[index + i] = fetchMoreResult.mountainList.results[i];
        }
        return {
          mountainList: {
            ok: fetchMoreResult.mountainList.ok,
            results: merged,
            totalPages: fetchMoreResult.mountainList.totalPages,
            totalResults: fetchMoreResult.mountainList.totalResults,
            error: fetchMoreResult.mountainList.error,
          },
        };
      },
    });
  };

  return (
    <div className="py-14 md:py-20">
      <div className="flex-wrap flex MobileGrid">
        {data.mountainList.results.map((item, index) => {
          return (
            <GridForest
              key={index}
              to={`/mountains/${item.id}`}
              isBarrierfree={item.isBarrierfree}
              isProgram={item.isProgram}
              img={item.commonFile?.fileUrl}
              name={item.name}
              address={item.roadAddress ? item.roadAddress : item.jibunAddress}
            />
          );
        })}
      </div>
      {data.mountainList.totalPages >= page && (
        <div className="w-full flex my-10">
          <DefaultLineButton
            text={t(`main.mountain.more`)}
            onClick={loadMoreData}
          />
        </div>
      )}
    </div>
  );
};
