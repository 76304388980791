import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import RoundButton from '../../components/button/RoundButton';
import { LanguageButton } from '../../components/button/LanguageButton';
import LogoSVG from '../../assets/images/logo.svg';
import UserDropDownButton from '../../components/button/UserDropDownButton';
import { useReactiveVar } from '@apollo/client';
import { isLoggedInVar } from '../../apollo';
import { useTranslation } from 'react-i18next';

const BG = styled.div`
    background-color: ${(props) => (props.isGrey ? '#FBFBFB' : '#fff')};
`;
const Logo = styled.div`
    width: 135px;
`;
const Nav = styled(Link)`
    font-size: 20px;
    letter-spacing: -0.6px;
    color: #707070;
    margin-bottom: -5px;
    font-weight: 500;
`;

function HeaderNav({ isGrey, onClick }) {
    const { t } = useTranslation();
    const isLoggedIn = useReactiveVar(isLoggedInVar);

    return (
        <>
            <BG className="hidden lg:flex sticky-header py-14" isGrey={isGrey}>
                <div className="width-1600">
                    <div className="flex place-content-between items-center">
                        <div className="flex items-center">
                            <Logo>
                                <Link to="/main">
                                    <img src={LogoSVG} alt="Logo" />
                                </Link>
                            </Logo>

                            <Nav to="/mountains" className="ml-24">
                                {t(`header.nav.mountains`)}
                            </Nav>
                        </div>
                        <div className="flex relative">
                            {isLoggedIn ? (
                                <UserDropDownButton onClick={onClick} />
                            ) : (
                                <>
                                    <RoundButton text={t(`header.login`)} to="/login" />
                                    <RoundButton
                                        text={t(`header.join`)}
                                        isblack="true"
                                        to="/join"
                                    />
                                </>
                            )}

                            <LanguageButton />
                        </div>
                    </div>
                </div>
            </BG>
        </>
    );
}
export default HeaderNav;
