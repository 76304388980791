import React, { useState } from "react";
import styled from "styled-components";
import BottomModal from "../bottomModal";
import AreaButton from "../../button/AreaButton";
import { DefaultButton } from "../../linkButton/Default";
import { filterOptions, sortOptions } from "../../../lib/codes/mountain";
import { useTranslation } from "react-i18next";

import { useSearchParams, useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { COMMON_REGION_LIST_QUERY } from "../../../lib/queries/mountain";
import { useForm, Controller } from "react-hook-form";

const ModalTitle = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: #222;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;
const SelectWrap = styled.div`
  margin: 0 -7px;
  @media (max-width: 768px) {
    margin: 0 -5px;
  }
`;

const Label = styled.p`
  font-size: 16px;
  color: #222;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
`;
const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ededed;
  margin: 25px 0;
  @media (max-width: 768px) {
    margin: 18px 0;
  }
`;
export default ({ isOpen, closeModal }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const keyword = searchParams.get("keyword")
    ? searchParams.get("keyword")
    : "";
  const region = searchParams.get("region") ? searchParams.get("region") : "0";
  const filter = searchParams.get("filter") ? searchParams.get("filter") : "";
  const sort = searchParams.get("sort") ? searchParams.get("sort") : "name";

  // 지역 필터
  const [selected, setSelected] = useState(0);
  const handleClick = (index) => {
    setSelected(index);
  };
  // 무장애길 필터
  const [roadSelected, setRoadSelected] = useState(0);
  const roadhandleClick = (index) => {
    setRoadSelected(index);
  };
  // 정렬 필터
  const [sortSelected, setSortSelected] = useState(0);
  const sorthandleClick = (index) => {
    setSortSelected(index);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    reset,
    register,
  } = useForm({
    defaultValues: {
      region: +region,
      filter,
      sort,
    },
  });

  const onSubmit = async (data) => {
    // console.log({ data });
    // return;
    try {
      const url =
        "/mountains?" +
        (keyword ? `keyword=${keyword}&` : ``) +
        (data.region ? `region=${data.region}&` : ``) +
        (data.filter ? `filter=${data.filter}&` : ``) +
        (data.sort ? `sort=${data.sort}&` : ``) +
        `page=1`;
      navigate(url);
    } catch (e) {
      console.log(e);
    }
  };

  const { data, error, loading } = useQuery(COMMON_REGION_LIST_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      take: 100,
      skip: 0,
    },
  });

  if (loading) {
    return <></>;
  }
  if (error) {
    return <></>;
  }
  return (
    <>
      <BottomModal isOpen={isOpen} closeModal={closeModal}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalTitle>{t(`mountain.list.selects.title`)}</ModalTitle>
          <div className="flex flex-col justify-between ">
            <div
              style={{
                height: 350,
                overflow: "hidden",
                overflowY: "scroll",
              }}
            >
              <Controller
                control={control}
                name="region"
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <Label>{t(`mountain.list.selects.region.modal`)}</Label>
                    <SelectWrap className="inline-flex flex-wrap">
                      {data.commonRegionList.results.map((item, index) => {
                        return (
                          <AreaButton
                            key={index}
                            type={"button"}
                            selected={item.id === value}
                            onClick={() => {
                              onChange(item.id);
                            }}
                            region={t(
                              `mountain.list.selects.region.options.${item.id}`
                            )}
                            length={item.count.toLocaleString()}
                          />
                        );
                      })}
                    </SelectWrap>
                  </div>
                )}
              />
              <Line />

              <Controller
                control={control}
                name="filter"
                render={({ field: { onChange, onBlur, value } }) => (
                  <div>
                    <Label>{t(`mountain.list.selects.filter.modal`)}</Label>
                    <SelectWrap className="inline-flex flex-wrap">
                      {[
                        {
                          key: "",
                          value: t(`mountain.list.selects.filter.options.all`),
                        },
                        {
                          key: "barrierfree",
                          value: t(
                            `mountain.list.selects.filter.options.barrierfree`
                          ),
                        },
                        {
                          key: "program",
                          value: t(
                            `mountain.list.selects.filter.options.program`
                          ),
                        },
                      ].map((item, index) => {
                        return (
                          <AreaButton
                            key={index}
                            type={"button"}
                            selected={item.key === value}
                            onClick={() => {
                              onChange(item.key);
                            }}
                            region={item.value}
                          />
                        );
                      })}
                    </SelectWrap>
                  </div>
                )}
              />
              <Line />
              <Controller
                control={control}
                name="sort"
                render={({ field: { onChange, onBlur, value } }) => (
                  <div className="pb-5">
                    <Label>{t(`mountain.list.selects.sort.modal`)}</Label>
                    <SelectWrap className="inline-flex flex-wrap">
                      {[
                        {
                          key: "distance",
                          value: t(
                            `mountain.list.selects.sort.options.distance`
                          ),
                        },
                        {
                          key: "name",
                          value: t(`mountain.list.selects.sort.options.name`),
                        },
                        {
                          key: "height",
                          value: t(`mountain.list.selects.sort.options.height`),
                        },
                      ].map((item, index) => {
                        return (
                          <AreaButton
                            key={index}
                            type={"button"}
                            selected={item.key === value}
                            onClick={() => {
                              onChange(item.key);
                            }}
                            region={item.value}
                          />
                        );
                      })}
                    </SelectWrap>
                  </div>
                )}
              />
            </div>
          </div>
          <DefaultButton
            text={t(`mountain.list.selects.button.confirm.text`)}
            onClick={null}
            type={"submit"}
          />
        </form>
      </BottomModal>
    </>
  );
};
