import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/images/whiteLogo.svg";
import { ScrollToTop } from "../../components/linkButton/Default";
import { useTranslation } from "react-i18next";

const FooterBox = styled.div`
  background-color: #202020;
`;
const FooterTop = styled.div`
  border-bottom-width: 1px;
  border-color: #4a4a4a;
`;
const FooterLogo = styled.div`
  width: 110px;
`;
const OnTap = styled(Link)`
  margin-left: 50px;
`;
const LinkText = styled.p`
  color: #ffffff;
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: -0.6px;
`;

const GreyText = styled.p`
  font-size: 15px;
  line-height: 24px;
  color: #bcbcbc;
  margin-right: 15px;
`;
const Copyright = styled.p`
  font-size: 12px;
  letter-spacing: -0.4px;
  line-height: 22px;
  color: #565656;
  margin-top: 5px;
`;
const FooterBottom = styled.div``;

function Footer() {
  const { t } = useTranslation();

  return (
    <FooterBox className="hidden lg:flex">
      <div className="container mx-auto">
        <FooterTop className="flex place-content-between items-center py-10 px-8">
          <FooterLogo>
            <img src={Logo} alt="메타숲 로고" />
          </FooterLogo>
          <div className="flex items-center">
            <OnTap to={"/policy/service"}>
              <LinkText onClick={ScrollToTop}>
                {t(`footer.policy.service`)}
              </LinkText>
            </OnTap>
            <OnTap to={"/policy/privacy"}>
              <LinkText onClick={ScrollToTop}>
                {t(`footer.policy.privacy`)}
              </LinkText>
            </OnTap>
          </div>
        </FooterTop>
        <FooterBottom className="px-8 pt-10 pb-14">
          <div className="flex items-center">
            <GreyText style={{ fontWeight: 700 }}>
              {t(`footer.company.name`)}
            </GreyText>
            <GreyText>{t(`footer.company.address`)}</GreyText>
          </div>
          <div className="flex items-center">
            <GreyText>TEL : {t(`footer.company.tel`)}</GreyText>
            <GreyText>EMAIL : {t(`footer.company.email`)}</GreyText>
          </div>
          <Copyright>Copyright © 메타숲, All rights reserved.</Copyright>
        </FooterBottom>
      </div>
    </FooterBox>
  );
}

export default Footer;
