import React, { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';
import { IconButton, WhiteIconButton } from '../../components/linkButton/IconButton';
import { faGrid2, faMap } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MapStyleViewButton from '../../components/maps/MapStyleViewButton';

export default ({ data, setListType }) => {
    const location = useLocation();

    const mapElement = useRef(null);

    useEffect(() => {
        const { naver } = window;
        if (!mapElement.current || !naver) return;

        // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
        // const location = new naver.maps.LatLng(latitude, longitude);
        const mapOptions = {
            //   center: location,
            zoom: 11,
            zoomControl: true,
            zoomControlOptions: {
                position: naver.maps.Position.TOP_RIGHT,
            },
        };
        const map = new naver.maps.Map(mapElement.current, mapOptions);

        var markers = [],
            infoWindows = [];
        var bounds = new naver.maps.LatLngBounds();

        data.mountainList.totalResults > 0
            ? data.mountainList.results.map((item) => {
                  // new naver.maps.Marker({
                  //   position: new naver.maps.LatLng(item.latitude, item.longitude),
                  //   map: map,
                  // });
                  var position = new naver.maps.LatLng(item.latitude, item.longitude);
                  bounds.extend(position);
                  const forestMarkerImage = {
                      url: process.env.PUBLIC_URL + '/assets/images/user/forestMarker.svg',
                      size: new naver.maps.Size(60, 60),
                      origin: new naver.maps.Point(0, 0),
                  };
                  var marker = new naver.maps.Marker({
                      map,
                      position,
                      title: item.name,
                      // icon: {
                      //   url: HOME_PATH + "/img/example/sp_pins_spot_v3.png",
                      //   size: new naver.maps.Size(24, 37),
                      //   anchor: new naver.maps.Point(12, 37),
                      //   origin: new naver.maps.Point(item.latitude, item.longitude),
                      // },
                      icon: forestMarkerImage,
                      zIndex: 100,
                  });
                  var contentString = [
                      '<div class="iw_inner">',
                      '   <a href="/mountains/${item.id}${location.search}" class="iw_innerText">',
                      `       ${item.name}`,
                      '   </a>',
                      '</div>',
                  ].join('');
                  var infoWindow = new naver.maps.InfoWindow({
                      content: contentString,
                      maxWidth: 200,
                      backgroundColor: 'transparent',
                      borderColor: 'transparent',
                      borderWidth: 0,
                      anchorSize: new naver.maps.Size(0, 0),
                      anchorSkew: false,

                      pixelOffset: new naver.maps.Point(0, 0),
                  });

                  markers.push(marker);
                  infoWindows.push(infoWindow);
              })
            : bounds.extend(new naver.maps.LatLng(36.3504119, 127.3845475));
        map.fitBounds(bounds, {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10,
            maxZoom: 7,
        });

        naver.maps.Event.addListener(map, 'idle', function () {
            updateMarkers(map, markers);
        });

        function updateMarkers(map, markers) {
            var mapBounds = map.getBounds();
            var marker, position;

            for (var i = 0; i < markers.length; i++) {
                marker = markers[i];
                position = marker.getPosition();

                if (mapBounds.hasLatLng(position)) {
                    showMarker(map, marker);
                } else {
                    hideMarker(map, marker);
                }
            }
        }

        function showMarker(map, marker) {
            if (marker.setMap()) return;
            marker.setMap(map);
        }

        function hideMarker(map, marker) {
            if (!marker.setMap()) return;
            marker.setMap(null);
        }

        // 해당 마커의 인덱스를 seq라는 클로저 변수로 저장하는 이벤트 핸들러를 반환합니다.
        function getClickHandler(seq) {
            return function (e) {
                var marker = markers[seq],
                    infoWindow = infoWindows[seq];

                if (infoWindow.getMap()) {
                    infoWindow.close();
                } else {
                    infoWindow.open(map, marker);
                }
            };
        }

        for (var i = 0, ii = markers.length; i < ii; i++) {
            naver.maps.Event.addListener(markers[i], 'click', getClickHandler(i));
        }
    }, []);

    return (
        <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <MapStyleViewButton setListType={setListType} />
            <div ref={mapElement} style={{ minHeight: '500px', height: '100%' }} />
        </div>
    );
};
