import React from 'react';
import styled from 'styled-components';
import HeaderNav from '../header/HeaderNav';
import Footer from '../footer/Footer';
import MobileFooter from '../footer/MobileFooter';
import DrawerHead from '../header/DrawerHead';
import MobileHead from '../header/MobileHead';

export default ({ children, title }) => {
    return (
        <>
            <div>
                <HeaderNav isGrey />
                <MobileHead title={title} />
                <div className="py-5 lg:py-16">
                    <div className="width-1600">
                        <div className="flex">{children}</div>
                    </div>
                </div>
            </div>

            <Footer />
            <MobileFooter />
        </>
    );
};
