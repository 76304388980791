import React from 'react';
import { Link } from 'react-router-dom';
import { ScrollToTop } from './Default';

export const MoreButton = ({ text, onClick }) => {
    return (
        <button onClick={onClick} className="moreTap">
            <span>{text}</span>
        </button>
    );
};

export const MoreLink = ({ text, to }) => {
    return (
        <Link to={to} onClick={ScrollToTop} className="moreTap ml-1">
            <span>{text}</span>
        </Link>
    );
};
