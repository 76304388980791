import React from 'react';
import Logo from '../assets/images/user/404-error.png';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Img = styled.div`
    max-width: 400px;
    width: 80%;
`;
const Title = styled.p`
    font-size: 2.4rem;
    font-weight: 600;
    color: #414a5c;
    text-align: center;
    margin-bottom: 10px;
    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
`;
const Text = styled.p`
    text-align: center;
    color: #868c94;
    font-size: 1.2rem;
    @media (max-width: 768px) {
        font-size: 1rem;
    }
`;

const GoHome = styled(Link)`
    background-color: #f36f6c;
    padding: 14px 25px 11px;
    font-size: 18px;
    border-radius: 10px;
    color: #fff;
    margin-top: 20px;
`;
export default () => {
    return (
        <div className="w-full h-screen flex flex-col justify-center items-center bg-slate-300">
            <Img>
                <img src={Logo} alt="404로고" />
            </Img>

            <Title>페이지를 찾을 수 없습니다.</Title>
            <Text>
                요청하신 페이지가 존재하지 않는 주소이거나, <br /> 주소가 변경, 삭제되어 찾을 수
                없습니다.
            </Text>
            <GoHome to="/main">홈으로가기</GoHome>
        </div>
    );
};
