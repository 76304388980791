import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { logUserOut } from "../../apollo";
import { useTranslation } from "react-i18next";

export default ({}) => {
  const { t } = useTranslation();

  const meJson = localStorage.getItem("ME");
  const me = meJson && JSON.parse(meJson);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // 드롭다운이 열릴 때 이벤트 핸들러를 추가합니다.
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 핸들러를 제거합니다.
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);
  return (
    <>
      <button
        onClick={toggleDropdown}
        className="flex items-center DropdownButton relative "
      >
        <p>
          <span>
            {me.role === "Company" ? me.company.companyName : me?.name}{" "}
          </span>
        </p>
        <FontAwesomeIcon icon={faChevronDown} className="downIcon" />
      </button>
      <div
        ref={dropdownRef}
        className={`dropdown-menu userDropdown ${isOpen ? "open" : ""}`}
      >
        {/* 드롭다운 메뉴 내용 */}
        <ul>
          {me.role === "Administrator" && (
            <li>
              <Link to="http://admin.metafore.kr">관리자</Link>
            </li>
          )}
          <li>
            <Link to="/account/edit">{t(`header.account.edit`)}</Link>
          </li>
          <li>
            <Link to="/account/user/reservations">
              {t(`header.account.user.reservations`)}
            </Link>
          </li>
          {me.role === "Company" && (
            <li>
              <Link to="/account/company/reservations">
                {t(`header.account.company.reservations`)}
              </Link>
            </li>
          )}
          <li>
            <Link to="/account/notification">
              {t(`header.account.notification`)}
            </Link>
          </li>
          <li>
            <button onClick={logUserOut}>{t(`header.logout`)}</button>
          </li>
        </ul>
      </div>
    </>
  );
};
