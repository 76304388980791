import React from "react";
import { Link, useNavigate } from "react-router-dom";
import HeaderNav from "../../layout/header/HeaderNav";
import Footer from "../../layout/footer/Footer";
import TreeTitle from "../../components/logout/TreeTitle";
import InputErrorText from "../../components/logout/InputErrorText";
import Logo from "../../assets/images/logo.svg";
import ClearButton from "../../components/button/ClearButton";
import { useTranslation } from "react-i18next";

import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { LOGIN_MUTATION } from "../../lib/queries/user";
import { logUserIn } from "../../apollo";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { TextUnderLineLink } from "../../components/linkButton/TextUnderLine";
import {
  DefaultButton,
  DefaultLineLink,
} from "../../components/linkButton/Default";
const MySwal = withReactContent(Swal);

export default () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [logInMutation, { logInLoading }] = useMutation(LOGIN_MUTATION);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    reset,
  } = useForm({
    defaultValues: {
      userId: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      if (logInLoading) {
        return;
      }
      const deviceToken = localStorage.getItem("deviceToken");

      const {
        data: { login },
      } = await logInMutation({
        variables: {
          userId: data.userId.toLowerCase(),
          password: data.password,
          deviceToken,
        },
      });

      if (login.ok) {
        logUserIn(login.token, login.result);
        navigate("/main");
      } else {
        MySwal.fire({
          showCloseButton: true,
          icon: "error",
          title: <p className="modalTitle">에러</p>,
          html: <p className="modalSubText">{login.error}</p>,
          confirmButtonText: `확인`,
          confirmButtonColor: `#44a16a`,
        });
        // return toast.error(login.error);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div
        style={{ backgroundColor: "#fafafa" }}
        className=" h-full minHeight min-h-screen flex lg:block"
      >
        <HeaderNav isLogin={false} />
        <form onSubmit={handleSubmit(onSubmit)} className="flex w-full">
          <div className="w-11/12 m-auto pt-0 pb-0 md:w-5/6 lg:w-3/5 max-w-screen-md md:pt-10 md:pb-20">
            <div className="whiteShadowBox">
              <div style={{ width: 90 }} className="m-auto mb-5 md:mb-10">
                <Link to="/main">
                  <img src={Logo} alt="logo" />
                </Link>
              </div>
              <TreeTitle title={t(`login.title`)} />
              <div>
                <Controller
                  control={control}
                  rules={{
                    required: t(`login.id.rules.required`),
                  }}
                  name="userId"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div className="my-4 md:my-8">
                      <p className="inputLabel mb-3">{t(`login.id.title`)}</p>
                      <div className="inputGreyBox  flex items-center justify-between">
                        <input
                          type="text"
                          className="defaultInput"
                          placeholder={t(`login.id.input.placeholder`)}
                          maxLength={12}
                          value={value}
                          onBlur={onBlur}
                          onChange={onChange}
                          autoComplete="username"
                        />
                        {value !== "" && (
                          <ClearButton onClick={() => setValue("userId", "")} />
                        )}
                      </div>
                      {errors.userId && (
                        <InputErrorText text={errors.userId.message} />
                      )}
                    </div>
                  )}
                />

                <Controller
                  control={control}
                  rules={{
                    required: t(`login.password.rules.required`),
                  }}
                  name="password"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <div>
                      <p className="inputLabel mb-3">
                        {t(`login.password.title`)}
                      </p>
                      <div className="inputGreyBox  flex items-center justify-between">
                        <input
                          type="password"
                          className="defaultInput"
                          placeholder={t(`login.password.input.placeholder`)}
                          maxLength={12}
                          value={value}
                          onBlur={onBlur}
                          onChange={onChange}
                          autoComplete="current-password"
                        />
                        {value !== "" && (
                          <ClearButton
                            onClick={() => setValue("password", "")}
                          />
                        )}
                      </div>
                      {errors.password && (
                        <InputErrorText text={errors.password.message} />
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="flex my-5 md:my-14 justify-around">
                <TextUnderLineLink
                  to="/idfind"
                  text={t(`login.buttons.idfind.text`)}
                />
                <TextUnderLineLink
                  to="/pwfind"
                  text={t(`login.buttons.pwfind.text`)}
                />
              </div>
              <div className="mt-7 md:mt-10">
                <DefaultButton
                  type="submit"
                  text={t(`login.buttons.login.text`)}
                />
                <DefaultLineLink
                  to="/join"
                  text={t(`login.buttons.join.text`)}
                />
              </div>
            </div>
          </div>
        </form>
        <Footer />
      </div>
    </>
  );
};
