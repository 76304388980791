import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import '../../assets/css/banner.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import ImageGallery from 'react-image-gallery';
import { DefaultLineButton } from '../../components/linkButton/Default';

import { useQuery } from '@apollo/client';
import { BANNER_LIST_QUERY } from '../../lib/queries/banner';

const Index = styled.div`
    position: absolute;
    bottom: 40px;
    left: 60px;
    font-size: 24px;
`;
const IndexLine = styled.div`
    width: 25px;
    height: 1.5px;
    background-color: #fff;
    margin: 0 11px;
    opacity: 0.5;
    margin-bottom: 3px;
`;
const Now = styled.span`
    color: #fff;
    font-weight: 700;
`;
const Total = styled.span`
    color: #95b5d3;
`;
const LeftButton = styled.button`
    /* background-color: orange; */
    padding: 20px;
    position: absolute;
    bottom: 30px;
    left: 160px;
    z-index: 10;
`;
const Icon = styled(FontAwesomeIcon)`
    font-size: 22px;
    color: #fff;
`;
const PlayButton = styled.button`
    /* background-color: blue; */
    padding: 20px;
    position: absolute;
    bottom: 30px;
    left: 210px;
    z-index: 10;
`;
const RightButton = styled.button`
    /* background-color: blue; */
    padding: 20px;
    position: absolute;
    bottom: 30px;
    left: 260px;
    z-index: 10;
`;

export default () => {
    const renderCustomLeftNav = (onClick, disabled) => {
        return (
            <LeftButton
                type="button"
                className={`hidden md:flex image-gallery-custom-left-nav ${
                    disabled ? 'disabled' : ''
                }`}
                onClick={onClick}
            >
                <Icon icon={faChevronLeft} />
            </LeftButton>
        );
    };

    const renderCustomRightNav = (onClick, disabled) => {
        return (
            <RightButton
                type="button"
                className={`hidden md:flex image-gallery-custom-right-nav ${
                    disabled ? 'disabled' : ''
                }`}
                onClick={onClick}
            >
                <Icon icon={faChevronRight} />
            </RightButton>
        );
    };

    const renderPlayPauseButton = (togglePlayPause, isPlaying) => {
        return (
            <PlayButton
                type="button"
                className={`hidden md:flex image-gallery-custom-play-button ${
                    isPlaying ? 'playing' : ''
                }`}
                onClick={togglePlayPause}
            >
                {isPlaying ? <Icon icon={faPause} /> : <Icon icon={faPlay} />}
            </PlayButton>
        );
    };

    const [currentIndex, setCurrentIndex] = useState(0);

    const handleSlide = (currentIndex) => {
        setCurrentIndex(currentIndex);
    };

    const take = 12;
    let page = 1;
    const { data, error, loading, fetchMore } = useQuery(BANNER_LIST_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            take,
            skip: (page - 1) * take,
        },
    });

    if (loading) {
        return <></>;
    }
    if (error) {
        return <>error</>;
    }
    return (
        <div className="pt-5 hidden md:block lg:py-2 ">
            <div className="width-1600">
                <div className="relative">
                    <ImageGallery
                        items={data.bannerList.results}
                        showThumbnails={false}
                        showFullscreenButton={false}
                        startIndex={0}
                        // showPlayButton={false}
                        onSlide={handleSlide}
                        autoPlay={true}
                        renderLeftNav={renderCustomLeftNav}
                        renderRightNav={renderCustomRightNav}
                        renderPlayPauseButton={renderPlayPauseButton}
                        renderItem={(item) => {
                            const fileUrl = item.pcImage?.fileUrl
                                ? item.pcImage?.fileUrl
                                : '/static/media/defaultImg.b9c73ad879bafcc7a378b338bdb3c963.svg';

                            // 피씨 이미지
                            return (
                                <div
                                    className="image-gallery-slide"
                                    style={{ backgroundImage: `url(${fileUrl})` }}
                                />
                            );
                            // 모바일 이미지
                            //   return (
                            //     <div
                            //       className="image-gallery-slide"
                            //       style={{
                            //         backgroundImage: `url(${item.mobileImage.fileUrl})`,
                            //       }}
                            //     />
                            //   );
                        }}
                    />
                    <Index className="hidden md:flex  items-center">
                        <Now>{currentIndex + 1}</Now>
                        <IndexLine /> <Total>{data.bannerList.totalResults}</Total>
                    </Index>
                </div>
            </div>
        </div>
    );
};
