import React from "react";
import styled from "styled-components";
import CoverImage from "./CoverImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import { useTranslation } from "react-i18next";

const Grid = styled.div`
  height: 500px;
  position: relative;
  @media (max-width: 1024px) {
    height: auto;
    padding-top: 75%;
  }
`;
const Cover = styled.div`
  border-radius: 20px;
  overflow: hidden;
  height: 100%;
  @media (max-width: 1024px) {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
  }
`;

const CoverList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 25px;
  width: 600px;
  height: 100%;
  @media (max-width: 1536px) {
    width: 40%;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;
const GridItem = styled.div`
  background-color: #f0f0f0;
  /* padding: 20px; */
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;
const Abs = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(63, 63, 63, 0.8);
  color: #fff;
  font-size: 24px;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const CameraIcon = styled(FontAwesomeIcon)`
  font-size: 28px;
  color: #fff;
  margin-bottom: 10px;
`;
const DetailGridImage = ({ onClick, data }) => {
  const { t } = useTranslation();
  const {
    mountainRead: {
      result: { commonFiles },
    },
  } = data;

  var imageLength = [0, 1, 2, 3, 4];
  return (
    <Grid className="flex items-center justify-between">
      <Cover className=" flex-1 mr-0 lg:mr-7">
        {commonFiles[0] ? (
          <CoverImage img={`url(${commonFiles[0].fileUrl})`} />
        ) : (
          <CoverImage
            img={`url("/static/media/defaultImg.b9c73ad879bafcc7a378b338bdb3c963.svg")`}
          />
        )}
      </Cover>
      <CoverList className="hidden xl:flex flex-wrap">
        {imageLength
          .filter((item, index) => index > 0)
          .map((item, index) => {
            return (
              <GridItem key={index}>
                <CoverImage
                  img={`url(${
                    commonFiles[item]
                      ? commonFiles[item].fileUrl
                      : "/static/media/defaultImg.b9c73ad879bafcc7a378b338bdb3c963.svg"
                  })`}
                />

                {item === 4 && commonFiles.length > 0 && (
                  <Abs onClick={onClick}>
                    <CameraIcon icon={faCamera} />
                    {commonFiles.length}
                    {t(`mountain.detail.photos.unit`)}
                  </Abs>
                )}
              </GridItem>
            );
          })}
      </CoverList>
    </Grid>
  );
};

export default DetailGridImage;
