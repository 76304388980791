import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import ImageGallery from 'react-image-gallery';
import RightNav from '../../controls/RightNav';
import LeftNav from '../../controls/LeftNav';
import { faImage } from '@fortawesome/pro-solid-svg-icons';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)', // 원하는 오버레이 색상 지정
        zindex: 1000,
    },
};

const CustomModal = styled(Modal)`
    position: absolute;
    left: 50%;
    top: 50%;
    margin-right: -50%;
    background-color: #282828;
    border-color: transparent;
    transform: translate(-50%, -50%);
    width: 80%;
    border-radius: 30px;
    padding: 30px 35px;
    z-index: 100;
    @media (max-width: 1280px) {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    @media (max-width: 768px) {
        padding: 20px; // Adjusted padding values for smaller screens
    }
`;

const CloseButton = styled.button`
    padding: 10px;
`;
const Icon = styled(FontAwesomeIcon)`
    font-size: 42px; /* 기본 크기 설정 */
    color: #979797;
    margin-bottom: -5px;
    @media (max-width: 768px) {
        font-size: 22px;
    }
`;

const Index = styled.div`
    color: #fff;
    font-size: 22px;
    @media (max-width: 768px) {
        font-size: 15px;
    }
`;

function ImageSlideModal({ isOpen, closeModal, data }) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const formatStatus = (current, total) => {
        return (
            <Index className="flex justify-center items-center">
                <div style={{ width: 50 }}>
                    <FontAwesomeIcon icon={faImage} />
                </div>
                {currentIndex + 1} / {total}
            </Index>
        );
    };
    const handleThumbnailClick = (index) => {
        setCurrentImageIndex(index);
    };

    const {
        mountainRead: {
            result: { commonFiles },
        },
    } = data;

    return (
        <div style={{ zIndex: 100 }}>
            <CustomModal
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="flex justify-end">
                    <CloseButton onClick={closeModal}>
                        <Icon icon={faXmark} />
                    </CloseButton>
                </div>

                <div className="flex flex-col justify-center items-center">
                    <div className="w-full">
                        <Carousel
                            showArrows={true}
                            showStatus={true}
                            showThumbs={true}
                            showIndicators={false}
                            selectedItem={currentIndex}
                            thumbWidth={80}
                            useKeyboardArrows
                            autoPlay={false}
                            infiniteLoop={true}
                            statusFormatter={formatStatus}
                        >
                            {commonFiles.map((image, index) => (
                                <div
                                    key={index}
                                    className="CoverImg"
                                    onClick={() => handleThumbnailClick(index)}
                                >
                                    <div
                                        className="CoverInnerImg"
                                        style={{ backgroundImage: `url(${image.fileUrl})` }}
                                    ></div>
                                    <img src={image.fileUrl} alt={`Thumbnail ${index}`} />
                                </div>
                            ))}
                        </Carousel>
                    </div>
                </div>
            </CustomModal>
        </div>
    );
}

export default ImageSlideModal;
