import React from "react";
import styled from "styled-components";
import LogoSVG from "../../assets/images/user/mytree.svg";
const Image = styled.div`
  width: 65px;
`;
const Name = styled.p`
  color: #222222;
  font-size: 28px;
  font-weight: 600;
`;
const Id = styled.span`
  color: #9b9b9b;
  font-size: 18px;
`;

export default () => {
  const meJson = localStorage.getItem("ME");
  const me = meJson && JSON.parse(meJson);

  return (
    <>
      <Image>
        <img src={LogoSVG} alt="Logo" />
      </Image>
      <div className="mt-10 my-10">
        <Name>{me?.name}</Name>
        <Id>@{me?.userId}</Id>
      </div>
    </>
  );
};
