import { faXmarkCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    width: 70px;
    margin-right: 15px;
    padding-top: 4px;
    color: #b3b3b3;
    font-size: 22px;
    @media (max-width: 768px) {
        margin-right: 10px;
        padding-top: 2px;
    }
`;
const Icon = styled(FontAwesomeIcon)`
    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

export default ({ onClick }) => {
    return (
        <>
            <Button type="button" onClick={onClick}>
                <Icon icon={faXmarkCircle} />
            </Button>
        </>
    );
};
