import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const Span = styled.span`
    margin-bottom: -4px;
    color: white;
    font-weight: 500;
    font-size: 17px;
    letter-spacing: 0.2px;
`;

export const UncheckedIcon = () => {
    return (
        <Wrap>
            <Span className="mr-2 opacity-50 ">off</Span>
        </Wrap>
    );
};

export const CheckedIcon = () => {
    return (
        <Wrap>
            <Span className="ml-2">on</Span>
        </Wrap>
    );
};
