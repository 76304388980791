import React from 'react';
import Modal from 'react-modal';
import { ModalCloseButton } from '../linkButton/ModalClose';
import ModalIcon from './ModalIcon';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.4)', // 원하는 오버레이 색상 지정
        zindex: 1000,
    },
};

function ModalLayout({ isOpen, closeModal, children, noneIcon }) {
    return (
        <div style={{ zIndex: 100 }}>
            <Modal
                className="customModalCenter"
                isOpen={isOpen}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <ModalCloseButton onClick={closeModal} />

                <div className="flex flex-col justify-center items-center">
                    {noneIcon ? null : <ModalIcon />}
                    {children}
                </div>
            </Modal>
        </div>
    );
}

export default ModalLayout;
