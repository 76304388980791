import React from 'react';
import { Link } from 'react-router-dom';

export const InputSubButton = ({ onClick, type, text }) => {
    return (
        <button className="inputSub" onClick={onClick} type={type}>
            <p className="inputSubText">{text}</p>
        </button>
    );
};
export const InputSubLink = ({ text, to }) => {
    return (
        <Link className="inputSub" to={to}>
            <p className="inputSubText">{text}</p>
        </Link>
    );
};
