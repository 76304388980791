import React from 'react';
import styled from 'styled-components';
import { faCircleXmark, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { useController } from 'react-hook-form';

const SearchWrap = styled.div`
    width: 70%;
    @media (max-width: 1024px) {
        width: 100%;
    }
`;
const SearchGreyBG = styled.div`
    border-bottom-width: 2.5px;
    border-color: #333;
    display: flex;
`;
const Wrap = styled.div`
    width: 90%;
    padding: 22px 20px;
    position: relative;
    z-index: 0;
    @media (max-width: 768px) {
        padding: 8px 0;
    }
`;

const ClearButton = styled.button`
    font-size: 32px;
    width: 50px;
    height: 30px;
    @media (max-width: 768px) {
        font-size: 22px;
        width: 40px;
        height: 40px;
    }
`;
const SearchButton = styled.button`
    width: 80px;
    @media (max-width: 768px) {
        width: 40px;
    }
`;
const Icon = styled(FontAwesomeIcon)`
    font-size: 32px;
    color: #727272;
    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

export default ({ control, name, onClear, keyword, data, title, placeholder }) => {
    const { t } = useTranslation();
    const {
        field: { value, onBlur, onChange },
    } = useController({
        control,
        name,
    });

    return (
        <SearchWrap>
            <SearchGreyBG>
                <Wrap className="flex">
                    <input
                        type="text"
                        className="myeongjo searchInput"
                        title={title}
                        placeholder={placeholder}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                    />
                    {value !== null && value !== '' && (
                        <ClearButton type="button" onClick={onClear}>
                            <FontAwesomeIcon icon={faCircleXmark} style={{ color: '#A7A7A7' }} />
                        </ClearButton>
                    )}
                </Wrap>

                <SearchButton type="submit">
                    <Icon icon={faMagnifyingGlass} />
                </SearchButton>
            </SearchGreyBG>
            {keyword ? (
                <p className="searchResult myeongjo">
                    "<span className="myeongjo">{keyword}</span>"
                    <span className="myeongjo"> ({data.mountainList.totalResults})</span>
                    {t(`mountain.list.search.result.text`)}{' '}
                </p>
            ) : (
                ``
            )}
        </SearchWrap>
    );
};
