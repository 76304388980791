import * as React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

export default ({ text }) => {
  return (
    <Stack
      sx={{ color: "grey.500" }}
      spacing={2}
      className="flex flex-col justify-center items-center py-10  min-h-full"
    >
      <CircularProgress color="success" />
      <p>{text}</p>
    </Stack>
  );
};
