import React from "react";
import UserCounsel from "../../../layout/mypage/consulting/UserCounsel";
import {
  faBars,
  faUser,
  faBell,
  faMessageCheck,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import SideName from "../../../components/mypage/SideName";
import MypageLayout from "../../../layout/mypage/MypageLayout";
import { useTranslation } from "react-i18next";

function CounselIndex() {
  const { t } = useTranslation();
  const meJson = localStorage.getItem("ME");
  const me = meJson && JSON.parse(meJson);

  return (
    <MypageLayout title={t(`header.account.user.reservations`)}>
      <div className="hidden lg:block sideFixed">
        <div>
          <SideName />
          <ul>
            <li>
              <Link className="sideMenu" to="/account/edit">
                <FontAwesomeIcon icon={faUser} className="sideIcon" />
                <p className="sideText">{t(`header.account.edit`)}</p>
              </Link>
            </li>
            <li>
              <Link
                className="sideMenu menuActive"
                to="/account/user/reservations"
              >
                <FontAwesomeIcon
                  icon={faBars}
                  className="sideIcon whiteActive"
                />
                <p className="sideText whiteActive">
                  {t(`header.account.user.reservations`)}
                </p>
              </Link>
            </li>
            {me.role === "Company" && (
              <li>
                <Link className="sideMenu" to="/account/company/reservations">
                  <FontAwesomeIcon icon={faMessageCheck} className="sideIcon" />
                  <p className="sideText">
                    {t(`header.account.company.reservations`)}
                  </p>
                </Link>
              </li>
            )}
            <li>
              <Link className="sideMenu" to="/account/notification">
                <FontAwesomeIcon icon={faBell} className="sideIcon" />
                <p className="sideText">{t(`header.account.notification`)}</p>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex-1">
        <UserCounsel text={t(`account.user.reservation.title`)} />
      </div>
    </MypageLayout>
  );
}

export default CounselIndex;
