import { faFilterList } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

export const IconLink = ({ to, icon }) => {
    return (
        <>
            <Link to={to} className="iconTap">
                <FontAwesomeIcon icon={icon} className="iconTapIcon" />
            </Link>
        </>
    );
};

export const IconButton = ({ onClick, type, icon }) => {
    return (
        <>
            <button onClick={onClick} type={type} className="iconTap">
                <FontAwesomeIcon icon={icon} className="iconTapIcon" />
            </button>
        </>
    );
};

export const WhiteIconLink = ({ to, icon }) => {
    return (
        <>
            <Link to={to} className="iconTap white">
                <FontAwesomeIcon icon={icon} className="iconTapIcon white" />
            </Link>
        </>
    );
};

export const WhiteIconButton = ({ onClick, type, icon }) => {
    return (
        <>
            <button onClick={onClick} type={type} className="iconTap white">
                <FontAwesomeIcon icon={icon} className="iconTapIcon white" />
            </button>
        </>
    );
};

export const FilterIconButton = ({ onClick }) => {
    return (
        <>
            <button onClick={onClick} className="py-4 px-3 ">
                <FontAwesomeIcon icon={faFilterList} className="text-xl" />
            </button>
        </>
    );
};
