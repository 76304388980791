import React from "react";
import EditGeneral from "./EditGeneral";
import EditCompany from "./EditCompany";

import { useQuery } from "@apollo/client";
import { ME_QUERY } from "../../../lib/queries/user";

export default () => {
  const meJson = localStorage.getItem("ME");
  const me = meJson && JSON.parse(meJson);

  const { loading, error, data } = useQuery(ME_QUERY, {
    fetchPolicy: "network-only",
  });
  if (loading) {
    return <></>;
  }
  if (error) {
    return <>{error.message}</>;
  }

  return (
    <>
      {me.role === "User" ? <EditGeneral data={data} /> : ""}
      {me.role === "Company" ? <EditCompany data={data} /> : ""}
    </>
  );
};
