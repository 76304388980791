import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ScrollToTop } from './Default';

const Text = styled.p`
    font-size: 16px;
    color: #cecece;
    line-height: 22px;
    text-decoration: underline;
    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

export const TextUnderLineLink = ({ text, to }) => {
    return (
        <>
            <Link to={to} onClick={ScrollToTop}>
                <Text>{text}</Text>
            </Link>
        </>
    );
};
export const TextUnderLineButton = ({ text, type, onClick }) => {
    return (
        <>
            <button type={type} onClick={onClick}>
                <Text>{text}</Text>
            </button>
        </>
    );
};
