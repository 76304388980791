import React from "react";
import styled from "styled-components";
import VnSVG from "../../assets/images/vietnam.svg";
import { Link } from "react-router-dom";
const StyleButton = styled(Link)`
  margin: ${(props) => (props.isblack ? "0 25px" : "0")};
  background-color: ${(props) => (props.isblack ? "#353535" : "#F8F8F8")};
  padding: 18px 30px 14px;
  border-radius: 30px;
`;
const Icon = styled.div`
  width: 23px;
  height: 23px;
  margin-right: 10px;
`;
const ButtonText = styled.p`
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.6px;
  color: #8d8d8d;
  color: ${(props) => (props.isblack ? "#fff" : "#9E9E9E")};
`;
function RoundButton({ text, vn, isblack, to }) {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <StyleButton
      className="flex items-center"
      isblack={isblack}
      to={to}
      onClick={handleClick}
    >
      {vn && (
        <Icon>
          <img src={VnSVG} />
        </Icon>
      )}
      <ButtonText isblack={isblack}>{text}</ButtonText>
    </StyleButton>
  );
}
export default RoundButton;
