import { faFaceSadTear } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
    background-color: #f5f5f5;
    border-radius: 15px;
`;

const Text = styled.span`
    color: #9c9c9c;
    margin-top: 2px;
    font-size: 17px;
    @media (max-width: 768px) {
        font-size: 15px;
    }
`;
const Icon = styled(FontAwesomeIcon)`
    width: 30px;
    font-size: 22px;
    color: #e29e6d;
    margin-right: 7px;
    @media (max-width: 768px) {
        font-size: 18px;
    }
`;
export default ({ text }) => {
    return (
        <>
            <Box className="flex items-center py-4 px-5">
                <Icon icon={faFaceSadTear} />
                <Text className="">{text}</Text>
            </Box>
        </>
    );
};
