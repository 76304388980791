import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import CoverImage from './CoverImage';
import DefaultImage from '../../assets/images/user/defaultImg.svg';
import { BarrierLabel, ProgramLabel } from '../label/ListLabel';
import { useTranslation } from 'react-i18next';

const List = styled(Link)`
    border-bottom-width: 1px;
    border-color: #e6e6e6;
    padding-bottom: 20px;
`;

const Img = styled.div`
    width: 120px;
    height: 105px;
    border-radius: 5px;
    margin-right: 20px;
    overflow: hidden;
`;

const Name = styled.p`
    font-weight: 800;
    font-size: 20px;
    letter-spacing: -0.5px;
`;
const Address = styled.p`
    margin-top: 3px;
    color: #4a4a4a;
`;
export default ({ to, isBarrierfree, isProgram, name, address, img }) => {
    const { t } = useTranslation();

    return (
        <div className="mb-5">
            <List to={to} className=" flex items-start">
                {img != null ? (
                    <Img>
                        <CoverImage img={`url(${img})`} />
                    </Img>
                ) : (
                    <Img>
                        <div
                            style={{
                                backgroundImage: `url(${DefaultImage})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                                height: '100%',
                            }}
                        />
                    </Img>
                )}

                <div className="flex flex-col items-start w-full">
                    <div className="flex">
                        {/* 무장애길 유무 라벨 */}
                        {isBarrierfree && (
                            <>
                                <BarrierLabel text={t(`main.mountain.items.labels.barrierfree`)} />
                                <div className="pr-1" />
                            </>
                        )}
                        {/* 프로그램 유무 라벨 */}
                        {isProgram && (
                            <ProgramLabel text={t(`main.mountain.items.labels.program`)} />
                        )}
                    </div>
                    <Name>{name}</Name>
                    <Address>{address}</Address>
                </div>
            </List>
        </div>
    );
};
