import React from "react";
import ReactMoment from "react-moment";
import CoverImage from "../CoverImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarWeek } from "@fortawesome/pro-solid-svg-icons";
import ContainImage from "../ContainImage";

export const TableHeadTitle = ({ title }) => {
  return <p className="tableHeadTitle">{title}</p>;
};
export const ProgramTitle = ({ text }) => {
  return <p className="tableTitle">{text}</p>;
};

export const TableText = ({ text }) => {
  return <p className="tableText">{text}</p>;
};

export const TableDateText = ({ date }) => {
  return (
    <p className="tableText">
      <ReactMoment format="YYYY-MM-DD">{date}</ReactMoment>
    </p>
  );
};
export const TableImg = ({ img }) => {
  return (
    <div className="tableImg">
      <CoverImage img={`url(${img})`} />
    </div>
  );
};

export const TableProgramInfo = ({ img, title, date }) => {
  return (
    <>
      <TableImg img={img} />
      <div className="ml-5">
        <ProgramTitle text={title} />
        <TableDateText date={date} />
      </div>
    </>
  );
};
export const TableTwoLineText = ({ first, second }) => {
  return (
    <>
      <TableText text={first} />
      <TableText text={second} />
    </>
  );
};

export const TableIconLabel = ({ icon, label, value }) => {
  return (
    <div className="flex w-full sm:w-auto items-center mobileTableText justify-between">
      <div className="flex items-center">
        <FontAwesomeIcon icon={icon} className="mb-1 w-7 tableIcons" />
        <span className="tableLabels">{label}</span>
      </div>
      <div className="textWrap flex items-center">
        <p className=" tableText">{value}</p>
      </div>
    </div>
  );
};

export const TableIconDate = ({ label, date }) => {
  return (
    <div className="flex w-full sm:w-auto items-center mobileTableText justify-between">
      <div className="flex items-center">
        <FontAwesomeIcon
          icon={faCalendarWeek}
          className="mb-1 w-7 tableIcons"
        />
        <span className="tableLabels">{label}</span>
      </div>
      <div className="textWrap  flex items-center">
        <p className="tableText">
          <ReactMoment format="YYYY-MM-DD">{date}</ReactMoment>
        </p>
      </div>
    </div>
  );
};
